/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    imageId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    regDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    lastLogin?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    playlistPublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    bettingPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    character: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    favoriteBook: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    favoriteMovie: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    characterHtml: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    favoriteBookHtml: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    favoriteMovieHtml: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    note: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    r1Moderator: boolean;
}

/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value: object): value is UserProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('playlistPublic' in value) || value['playlistPublic'] === undefined) return false;
    if (!('bettingPublic' in value) || value['bettingPublic'] === undefined) return false;
    if (!('character' in value) || value['character'] === undefined) return false;
    if (!('favoriteBook' in value) || value['favoriteBook'] === undefined) return false;
    if (!('favoriteMovie' in value) || value['favoriteMovie'] === undefined) return false;
    if (!('characterHtml' in value) || value['characterHtml'] === undefined) return false;
    if (!('favoriteBookHtml' in value) || value['favoriteBookHtml'] === undefined) return false;
    if (!('favoriteMovieHtml' in value) || value['favoriteMovieHtml'] === undefined) return false;
    if (!('note' in value) || value['note'] === undefined) return false;
    if (!('r1Moderator' in value) || value['r1Moderator'] === undefined) return false;
    return true;
}

export function UserProfileFromJSON(json: any): UserProfile {
    return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'name': json['name'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
        'regDate': json['regDate'] == null ? undefined : (new Date(json['regDate'])),
        'lastLogin': json['lastLogin'] == null ? undefined : (new Date(json['lastLogin'])),
        'playlistPublic': json['playlistPublic'],
        'bettingPublic': json['bettingPublic'],
        'character': json['character'],
        'favoriteBook': json['favoriteBook'],
        'favoriteMovie': json['favoriteMovie'],
        'characterHtml': json['characterHtml'],
        'favoriteBookHtml': json['favoriteBookHtml'],
        'favoriteMovieHtml': json['favoriteMovieHtml'],
        'note': json['note'],
        'r1Moderator': json['r1Moderator'],
    };
}

export function UserProfileToJSON(value?: UserProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'status': value['status'],
        'name': value['name'],
        'imageId': value['imageId'],
        'regDate': value['regDate'] == null ? undefined : ((value['regDate']).toISOString()),
        'lastLogin': value['lastLogin'] == null ? undefined : ((value['lastLogin']).toISOString()),
        'playlistPublic': value['playlistPublic'],
        'bettingPublic': value['bettingPublic'],
        'character': value['character'],
        'favoriteBook': value['favoriteBook'],
        'favoriteMovie': value['favoriteMovie'],
        'characterHtml': value['characterHtml'],
        'favoriteBookHtml': value['favoriteBookHtml'],
        'favoriteMovieHtml': value['favoriteMovieHtml'],
        'note': value['note'],
        'r1Moderator': value['r1Moderator'],
    };
}

