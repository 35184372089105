/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestResult1
 */
export interface RestResult1 {
    /**
     * 
     * @type {number}
     * @memberof RestResult1
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RestResult1
     */
    message?: string;
}

/**
 * Check if a given object implements the RestResult1 interface.
 */
export function instanceOfRestResult1(value: object): value is RestResult1 {
    return true;
}

export function RestResult1FromJSON(json: any): RestResult1 {
    return RestResult1FromJSONTyped(json, false);
}

export function RestResult1FromJSONTyped(json: any, ignoreDiscriminator: boolean): RestResult1 {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function RestResult1ToJSON(value?: RestResult1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statusCode': value['statusCode'],
        'message': value['message'],
    };
}

