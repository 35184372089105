import { push } from '@lagunovsky/redux-react-router';
import { all, put, select, takeLatest, delay } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { RoundWinnerParams, roundWinnerPath } from '.';
import { fetchWrapperFunc } from '../../../utils';
import { getHighscoreApi2, getSystemConfig } from '../../System/systemConfig';
import { tournamentSelectionTournamentIdSelector } from '../../TournamentSelectionComponent/selectors';
import { roundWinnersSetWinners, ROUND_WINNERS_LOAD, ROUND_WINNERS_LOAD_WITHOUT_PROPS } from './actions';

function* load(action: Action<RoundWinnerParams>) {
    const api = getHighscoreApi2();

    yield* fetchWrapperFunc(() => api.getWinners({clientId: getSystemConfig().clientId, tId: action.payload.tId}), [
        r => roundWinnersSetWinners(r)
    ]);
}

function* loadWithoutProps() {

    yield delay(500);
    const tId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    if (tId) {
        yield put(push(roundWinnerPath(String(tId))));
    }
}

function* watch() {
    yield takeLatest(ROUND_WINNERS_LOAD_WITHOUT_PROPS, loadWithoutProps);
    yield takeLatest(ROUND_WINNERS_LOAD, load);
}

export function* roundWinnersSagas() {
    yield all([watch()]);
}
