/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MyGroupHighscore
 */
export interface MyGroupHighscore {
    /**
     * 
     * @type {number}
     * @memberof MyGroupHighscore
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof MyGroupHighscore
     */
    totalNumberOfGroups: number;
    /**
     * 
     * @type {string}
     * @memberof MyGroupHighscore
     */
    ugId: string;
    /**
     * 
     * @type {string}
     * @memberof MyGroupHighscore
     */
    userGroupName: string;
}

/**
 * Check if a given object implements the MyGroupHighscore interface.
 */
export function instanceOfMyGroupHighscore(value: object): value is MyGroupHighscore {
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('totalNumberOfGroups' in value) || value['totalNumberOfGroups'] === undefined) return false;
    if (!('ugId' in value) || value['ugId'] === undefined) return false;
    if (!('userGroupName' in value) || value['userGroupName'] === undefined) return false;
    return true;
}

export function MyGroupHighscoreFromJSON(json: any): MyGroupHighscore {
    return MyGroupHighscoreFromJSONTyped(json, false);
}

export function MyGroupHighscoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyGroupHighscore {
    if (json == null) {
        return json;
    }
    return {
        
        'rank': json['rank'],
        'totalNumberOfGroups': json['totalNumberOfGroups'],
        'ugId': json['ugId'],
        'userGroupName': json['userGroupName'],
    };
}

export function MyGroupHighscoreToJSON(value?: MyGroupHighscore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rank': value['rank'],
        'totalNumberOfGroups': value['totalNumberOfGroups'],
        'ugId': value['ugId'],
        'userGroupName': value['userGroupName'],
    };
}

