/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailoutState } from './MailoutState';
import {
    MailoutStateFromJSON,
    MailoutStateFromJSONTyped,
    MailoutStateToJSON,
} from './MailoutState';

/**
 * 
 * @export
 * @interface Mailout
 */
export interface Mailout {
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof Mailout
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    contentType: string;
    /**
     * 
     * @type {Date}
     * @memberof Mailout
     */
    sendStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Mailout
     */
    sendEnd?: Date;
    /**
     * 
     * @type {MailoutState}
     * @memberof Mailout
     */
    state: MailoutState;
    /**
     * 
     * @type {number}
     * @memberof Mailout
     */
    entriesSentCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Mailout
     */
    validTo: Date;
    /**
     * 
     * @type {string}
     * @memberof Mailout
     */
    sender: string;
    /**
     * 
     * @type {number}
     * @memberof Mailout
     */
    entryCount: number;
    /**
     * 
     * @type {number}
     * @memberof Mailout
     */
    sendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Mailout
     */
    threadCount?: number;
}

/**
 * Check if a given object implements the Mailout interface.
 */
export function instanceOfMailout(value: object): value is Mailout {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('language' in value) || value['language'] === undefined) return false;
    if (!('time' in value) || value['time'] === undefined) return false;
    if (!('clientName' in value) || value['clientName'] === undefined) return false;
    if (!('contentType' in value) || value['contentType'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('validTo' in value) || value['validTo'] === undefined) return false;
    if (!('sender' in value) || value['sender'] === undefined) return false;
    if (!('entryCount' in value) || value['entryCount'] === undefined) return false;
    return true;
}

export function MailoutFromJSON(json: any): Mailout {
    return MailoutFromJSONTyped(json, false);
}

export function MailoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mailout {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'subject': json['subject'],
        'text': json['text'],
        'language': json['language'],
        'time': (new Date(json['time'])),
        'clientName': json['clientName'],
        'contentType': json['contentType'],
        'sendStart': json['sendStart'] == null ? undefined : (new Date(json['sendStart'])),
        'sendEnd': json['sendEnd'] == null ? undefined : (new Date(json['sendEnd'])),
        'state': MailoutStateFromJSON(json['state']),
        'entriesSentCount': json['entriesSentCount'] == null ? undefined : json['entriesSentCount'],
        'validTo': (new Date(json['validTo'])),
        'sender': json['sender'],
        'entryCount': json['entryCount'],
        'sendRate': json['sendRate'] == null ? undefined : json['sendRate'],
        'threadCount': json['threadCount'] == null ? undefined : json['threadCount'],
    };
}

export function MailoutToJSON(value?: Mailout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'subject': value['subject'],
        'text': value['text'],
        'language': value['language'],
        'time': ((value['time']).toISOString()),
        'clientName': value['clientName'],
        'contentType': value['contentType'],
        'sendStart': value['sendStart'] == null ? undefined : ((value['sendStart']).toISOString()),
        'sendEnd': value['sendEnd'] == null ? undefined : ((value['sendEnd']).toISOString()),
        'state': MailoutStateToJSON(value['state']),
        'entriesSentCount': value['entriesSentCount'],
        'validTo': ((value['validTo']).toISOString()),
        'sender': value['sender'],
        'entryCount': value['entryCount'],
        'sendRate': value['sendRate'],
        'threadCount': value['threadCount'],
    };
}

