/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PlaylistUser,
} from '../models/index';
import {
    PlaylistUserFromJSON,
    PlaylistUserToJSON,
} from '../models/index';

export interface GetPlaylistUserRequest {
    userId: string;
}

export interface UpdateUserPlaylistPublicRequest {
    value?: boolean;
}

/**
 * 
 */
export class PlaylistUser2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async getPlaylistUserRaw(requestParameters: GetPlaylistUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlaylistUser>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getPlaylistUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/playlist/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaylistUserFromJSON(jsonValue));
    }

    /**
     */
    async getPlaylistUser(requestParameters: GetPlaylistUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlaylistUser> {
        const response = await this.getPlaylistUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isPlaylistPublicRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlaylistUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/playlist/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaylistUserFromJSON(jsonValue));
    }

    /**
     */
    async isPlaylistPublic(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlaylistUser> {
        const response = await this.isPlaylistPublicRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserPlaylistPublicRaw(requestParameters: UpdateUserPlaylistPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlaylistUser>> {
        const queryParameters: any = {};

        if (requestParameters['value'] != null) {
            queryParameters['value'] = requestParameters['value'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/playlist/user/playlist-public`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaylistUserFromJSON(jsonValue));
    }

    /**
     */
    async updateUserPlaylistPublic(requestParameters: UpdateUserPlaylistPublicRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlaylistUser> {
        const response = await this.updateUserPlaylistPublicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
