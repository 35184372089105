/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bet
 */
export interface Bet {
    /**
     * 
     * @type {number}
     * @memberof Bet
     */
    gameId: number;
    /**
     * 
     * @type {number}
     * @memberof Bet
     */
    bet1: number;
    /**
     * 
     * @type {number}
     * @memberof Bet
     */
    bet2: number;
}

/**
 * Check if a given object implements the Bet interface.
 */
export function instanceOfBet(value: object): value is Bet {
    if (!('gameId' in value) || value['gameId'] === undefined) return false;
    if (!('bet1' in value) || value['bet1'] === undefined) return false;
    if (!('bet2' in value) || value['bet2'] === undefined) return false;
    return true;
}

export function BetFromJSON(json: any): Bet {
    return BetFromJSONTyped(json, false);
}

export function BetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bet {
    if (json == null) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'bet1': json['bet1'],
        'bet2': json['bet2'],
    };
}

export function BetToJSON(value?: Bet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameId': value['gameId'],
        'bet1': value['bet1'],
        'bet2': value['bet2'],
    };
}

