export const sysAdminPath = () => '/sys';

export const sysAdminSystemPropertyPath = () => '/sys/properties';
export const sysAdminClientPropertyPath = () => '/sys/client-properties';

export const sysAdminJobBasePath = () => `${sysAdminPath()}/jobs`;
export const sysAdminJobPath = (serverName: string) => `${sysAdminJobBasePath()}/${serverName}`;

export const sysAdminCacheBasePath = () => `${sysAdminPath()}/cache`;
export const sysAdminCachePath = (serverName: string) => `${sysAdminCacheBasePath()}/${serverName}`;

export const sysAdminSqlBasePath = () => `${sysAdminPath()}/sql`;
export const sysAdminSqlPath = (serverName: string) => `${sysAdminSqlBasePath()}/${serverName}`;

export const sysAdminUseCaseLogBasePath = () => `${sysAdminPath()}/ucl`;
export const sysAdminUseCaseLogPath = (serverName: string) => `${sysAdminUseCaseLogBasePath()}/${serverName}`;

export const sysAdminEventHandlerPath = () => '/sys/event-handler';
export const sysAdminWebUsagePath = () => '/sys/usage';
export const sysAdminMailoutPath = () => `${sysAdminPath()}/mailouts`;
export const sysAdminUpdateTaskPath = () => `${sysAdminPath()}/update-tasks`;
export const sysAdminTranslationTaskPath = () => `${sysAdminPath()}/translations`;

export const sysAdminSportPath = () => '/sys/sports';
export const sysAdminSportTournamentPath = () => sysAdminSportPath() + '/tournaments';
export const sysAdminSportTeamPath = () => sysAdminSportPath() + '/teams';
export const sysAdminSportOverviewPath = () => sysAdminSportPath() + '/overview';
