import { faMedal, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { getBettingUserApi2 } from '../../components/System/systemConfig';
import { userViewProfilePath } from '../../components/UserView';
import { myProfilePath } from '../../screens/UserProfileScreen';
import { BettingUser } from '../../services/betting';

export interface UserLinkProps extends React.PropsWithChildren {
    id: string;
    name?: string;
    maxWidth?: number;
    showTrophies?: boolean;
    linkToMyProfile?: boolean;
}

interface TournamentTrophyProps {
    count: number;
}

const TournamentTrophy: React.FC<TournamentTrophyProps> = (props) => {
    return (
        <>
            <span className="fa-layers fa-fw" title={props.count + ' Turnier(e) gewonnen'}>
                <FontAwesomeIcon icon={faTrophy} color="gold" />
            </span>
        </>
    );
};

const TournamentRoundTrophy: React.FC<TournamentTrophyProps> = (props) => {
    return (
        <>
            <span className="fa-layers fa-fw" title={props.count + ' Spieltag(e) gewonnen'}>
                <FontAwesomeIcon icon={faMedal} color="silver" />
            </span>
        </>
    );
};

export const UserLink: React.FC<UserLinkProps> = (props) => {
    const [userInfos, setUserInfos] = React.useState<BettingUser>();

    const [wonTournaments, setWonTournaments] = React.useState(0);
    const [wonTournamentRounds, setWonTournamentRounds] = React.useState(0);

    const api = getBettingUserApi2();
    const bettingUserQuery = useQuery({
        queryKey: ['bettingUserQuery', props.id],
        queryFn: () => api.getBettingUserProfileById({userId: props.id}),
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const getProfileUrl = (): string => {
        if (props.linkToMyProfile) {
            return myProfilePath();
        } else {
            return userViewProfilePath(props.id);
        }
    };

    React.useEffect(() => {
        if (bettingUserQuery.isSuccess && bettingUserQuery.data) {
            setUserInfos(bettingUserQuery.data);
            setWonTournamentRounds(bettingUserQuery.data.wonTournamentRounds);
            setWonTournaments(bettingUserQuery.data.wonTournaments);
        }
    }, [bettingUserQuery]);

    if (!props.children) {
        return props.maxWidth !== undefined ? (
            <Link to={getProfileUrl()} style={{ maxWidth: props.maxWidth + 'px' }} className="d-block text-truncate">
                {props.name || userInfos?.name}
                {props.name === undefined && bettingUserQuery.isLoading && 'lade ...'}
            </Link>
        ) : (
            <Link to={getProfileUrl()}>
                {props.name || userInfos?.name}
                {props.name === undefined && bettingUserQuery.isLoading && 'lade ...'}
                {wonTournaments > 0 && (
                    <>
                        {' '}
                        <TournamentTrophy count={wonTournaments} />
                    </>
                )}
                {wonTournaments === 0 && wonTournamentRounds > 0 && (
                    <>
                        {' '}
                        <TournamentRoundTrophy count={wonTournamentRounds} />
                    </>
                )}
            </Link>
        );
    } else {
        return props.maxWidth !== undefined ? (
            <Link to={getProfileUrl()} style={{ maxWidth: props.maxWidth + 'px' }} className="d-block text-truncate">
                {props.children}
            </Link>
        ) : (
            <Link to={getProfileUrl()}>{props.children}</Link>
        );
    }
};

export default UserLink;
