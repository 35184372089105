import { faEnvelope, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCrown, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Table } from 'reactstrap';
import UserLink from '../../links/UserLink';
import { GroupMember, UserGroupDetail } from '../../services/betting';
import { ButtonWithConfirmation } from '../common/ButtonWithConfirmation';
import { Section } from '../common/Section';
import { DateStr } from '../DateTime';
import { isSysAdminSelector } from '../System/selectors';

interface Props {
    groupMembers: GroupMember[];
    userGroupDetail: UserGroupDetail;
    onRemoveMember: (ugId: number, userId: number) => void;
    onRemoveInvitation: (ugId: number, userId: number) => void;
    onReinviteMember: (ugId: number, userId: number) => void;
    onAcceptMembershipRequest: (ugId: number, userId: number) => void;
    onRefuseMembershipRequest: (ugId: number, userId: number) => void;
    onGrantAdminPrivilege: (ugId: number, userId: number) => void;
    onRevokeAdminPrivilege: (ugId: number, userId: number) => void;
    loginUserId: string;
}

export class UserGroupMemberAdminList extends React.Component<Props> {
    showRevokeAdminPrivilegeButton(gm: GroupMember) {
        if (!gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        if (this.props.loginUserId === String(gm.userId)) {
            return false;
        }
        return true;
    }
    showGrantAdminPrivilegeButton(gm: GroupMember) {
        if (gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        return true;
    }

    public render() {
        return (
            <Section>
                <p className="my-4">
                    <b>Gründungsdatum:</b> <DateStr date={this.props.userGroupDetail.date} />
                </p>
                <Table className={'members'}>
                    <thead>
                        <tr>
                            <th>Mitglied</th>
                            <th>Status</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.groupMembers.map((gm: GroupMember) => {
                            return (
                                <tr key={gm.userId}>
                                    <td>
                                        {gm.state === 'invited' && <span />}
                                        {gm.invitationEmail && gm.invitationEmail}
                                        {!gm.invitationEmail && <UserLink id={String(gm.userId)} name={gm.userName} />}
                                    </td>
                                    <td>
                                        {(this.props.userGroupDetail.loginUserIsGroupAdmin || isSysAdminSelector) && (
                                            <>
                                                {gm.state === 'active' && gm.admin && (
                                                    <FontAwesomeIcon icon={faCrown} title="Administrator" />
                                                )}
                                                {gm.state === 'active' && !gm.admin && (
                                                    <FontAwesomeIcon icon={faUserFriends} title="Mitglied" />
                                                )}
                                                {gm.state === 'invited' && (
                                                    <FontAwesomeIcon icon={faEnvelope} title="Einladung" />
                                                )}
                                                {gm.state === 'askForMembership' && (
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                        color="#ff2222"
                                                        title="Mitgliedsanfrage"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {gm.state === 'active' && !gm.admin && (
                                            <ButtonWithConfirmation
                                                title={'Mitglied aus Team entfernen'}
                                                message={'Wollen Sie das Mitglied aus dem Team entfernen?'}
                                                color={'link'}
                                                onOK={() => this.props.onRemoveMember(gm.userGroupId, gm.userId)}
                                                size="sm"
                                            >
                                                Mitglied entfernen
                                            </ButtonWithConfirmation>
                                        )}
                                        {gm.state === 'invited' && (
                                            <div>
                                                <ButtonWithConfirmation
                                                    title={'Einladung löschen'}
                                                    message={'Wollen Sie die Einladung löschen?'}
                                                    color={'link'}
                                                    onOK={() =>
                                                        this.props.onRemoveInvitation(gm.userGroupId, gm.userId)
                                                    }
                                                >
                                                    Einladung löschen
                                                </ButtonWithConfirmation>

                                                <ButtonWithConfirmation
                                                    title={'Einladung erneut versenden'}
                                                    message={'Wollen Sie die Einladung erneut versenden?'}
                                                    color={'link'}
                                                    onOK={() => this.props.onReinviteMember(gm.userGroupId, gm.userId)}
                                                >
                                                    Einladung erneut versenden
                                                </ButtonWithConfirmation>
                                            </div>
                                        )}
                                        {gm.state === 'askForMembership' && (
                                            <>
                                                <ButtonWithConfirmation
                                                    title={'Anfrage annehmen'}
                                                    message={'Wollen Sie die Anfrage annehmen?'}
                                                    color={'link'}
                                                    onOK={() =>
                                                        this.props.onAcceptMembershipRequest(gm.userGroupId, gm.userId)
                                                    }
                                                >
                                                    Mitgliedsanfrage annehmen
                                                </ButtonWithConfirmation>
                                                <ButtonWithConfirmation
                                                    title={'Anfrage ablehen'}
                                                    message={'Wollen Sie die Anfrage ablehnen?'}
                                                    color={'link'}
                                                    onOK={() =>
                                                        this.props.onRefuseMembershipRequest(gm.userGroupId, gm.userId)
                                                    }
                                                >
                                                    Mitgliedsanfrage ablehnen
                                                </ButtonWithConfirmation>
                                            </>
                                        )}
                                        {this.showRevokeAdminPrivilegeButton(gm) && (
                                            <ButtonWithConfirmation
                                                title={'Admin-Rechte entziehen'}
                                                message={'Wollen Sie die Admin-Rechte entziehen?'}
                                                color={'link'}
                                                onOK={() =>
                                                    this.props.onRevokeAdminPrivilege(gm.userGroupId, gm.userId)
                                                }
                                            >
                                                Admin-Rechte entziehen
                                            </ButtonWithConfirmation>
                                        )}
                                        {this.showGrantAdminPrivilegeButton(gm) && (
                                            <ButtonWithConfirmation
                                                title={'Admin-Rechte erteilen'}
                                                message={'Wollen Sie die Admin-Rechte erteilen?'}
                                                color={'link'}
                                                onOK={() => this.props.onGrantAdminPrivilege(gm.userGroupId, gm.userId)}
                                                size="sm"
                                            >
                                                zum Team-Admin machen
                                            </ButtonWithConfirmation>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Section>
        );
    }
}
