import actionCreatorFactory from 'typescript-fsa';
import { RoundWinnerParams } from '.';
import { RoundWinners } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const ROUND_WINNERS_LOAD = 'ROUND_WINNERS_LOAD';
export const roundWinnersLoad = actionCreator<RoundWinnerParams>(ROUND_WINNERS_LOAD);

export const ROUND_WINNERS_LOAD_WITHOUT_PROPS = 'ROUND_WINNERS_LOAD_WITHOUT_PROPS';
export const roundWinnersLoadWithoutProps = actionCreator(ROUND_WINNERS_LOAD_WITHOUT_PROPS);

export const ROUND_WINNERS_SET_WINNERS = 'ROUND_WINNERS_SET_WINNERS';
export const roundWinnersSetWinners = actionCreator<RoundWinners[]>(ROUND_WINNERS_SET_WINNERS);
