import * as React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { BetQuote, GameBet } from '../../../../services/betting';
import { TeamLink } from '../../../TeamLink';
import { GameResult } from '../../../TournamentApp/GameResult';

interface Props {
    betQuote: BetQuote;
    gameBet: GameBet;
    onClose: () => void;
}

export class BetQuoteComponent extends React.Component<Props> {
    getBetCount() {
        return (
            this.props.betQuote.differenceCount + this.props.betQuote.resultCount + this.props.betQuote.tendencyCount
        );
    }

    getQuote(count: number) {
        const betCount = this.props.betQuote.betCount;
        if (betCount > 0) {
            return (count * 100.0) / betCount;
        } else {
            return 0;
        }
    }

    public render() {
        if (this.props.betQuote && this.props.gameBet) {
            return (
                <Modal isOpen={true} centered={true} keyboard={false} autoFocus={false}>
                    <ModalHeader toggle={this.props.onClose}>Tipp-Quote</ModalHeader>
                    <ModalBody>
                        <div className="container-fluid">
                            <Row className="p-2 rounded bg-light text-black">
                                <Col xs={6}>Paarung</Col>
                                <Col xs={3}>Tipp</Col>
                                <Col xs={3}>Ergebnis</Col>
                            </Row>
                            <Row className="p-2 rounded bg-success text-white">
                                <Col xs={3}>
                                    <TeamLink team={this.props.gameBet.team1} />
                                </Col>
                                <Col xs={3}>
                                    <TeamLink team={this.props.gameBet.team2} />
                                </Col>
                                <Col xs={3}>
                                    {this.props.gameBet.bet1}
                                    &nbsp;:&nbsp;
                                    {this.props.gameBet.bet2}
                                </Col>
                                <Col xs={3}>
                                    <GameResult gameId={String(this.props.gameBet.gameId)} />
                                </Col>
                            </Row>
                        </div>
                        <Table>
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        Wenn Dein Tipp richtig ist, würdest Du auf der Basis des aktuellen Punktemodus
                                        folgende Punkte bekommen.
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Quote</td>
                                    <td>Punkte</td>
                                </tr>
                                {!this.props.betQuote.tendencyDisabled && (
                                    <tr>
                                        <td>Tendenz</td>
                                        <td>
                                            {this.getQuote(this.props.betQuote.tendencyCount).toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                                            )}{' '}
                                            %
                                        </td>
                                        <td>{this.props.betQuote.tendencyPoints}</td>
                                    </tr>
                                )}
                                {!this.props.betQuote.differenceDisabled && (
                                    <tr>
                                        <td>Tordifferenz</td>
                                        <td>
                                            {this.getQuote(
                                                this.props.betQuote.differenceCount
                                            ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}{' '}
                                            %
                                        </td>
                                        <td>{this.props.betQuote.differencePoints}</td>
                                    </tr>
                                )}
                                {!this.props.betQuote.resultDisabled && (
                                    <tr>
                                        <td>Ergebnise</td>
                                        <td>
                                            {this.getQuote(this.props.betQuote.resultCount).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}{' '}
                                            %
                                        </td>
                                        <td>{this.props.betQuote.resultPoints}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <b>Summe</b>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <b>{this.props.betQuote.points}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.onClose}>X</Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            return null;
        }
    }
}
