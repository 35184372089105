import { push } from '@lagunovsky/redux-react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { ApiFootballContext } from '..';
import { BettingLink } from '../../../components/Betting';
import { DateTime } from '../../../components/DateTime';
import { usePathSegment } from '../../../components/SysAdminApp/CacheStatisticsComponent';
import { systemSetPopupMessage } from '../../../components/System/actions';
import { getSystemConfig, getTournamentApi2 } from '../../../components/System/systemConfig';
import { IncrementalSearch, KeyNameValue } from '../../../components/common/IncrementalSearch';
import { FuntipTitle } from '../../../reactutils/withTitle';
import { Tournament, TournamentRound } from '../../../services/betting';
import {
    AFFixtureResponse,
    AFFixtureSet,
    AFLeagueResponse,
    AFLeagueSet,
    AFRoundsResponse,
    AFSeason,
} from '../../apifootball-model';
import { getApiFootball } from '../live';
import { ApiFootballTeamLink } from '../team';
import { ApiFootballTicker } from '../ticker';

interface RoundFixtureProps {
    name: string;
    league?: string;
    season?: string;
    round?: string;
    live: boolean;
}

const API_FOOTBALL_PROVIDER_KEY = 'apiFootball';

export const getLiveFixtures = async (): Promise<AFFixtureResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/fixtures?live=all').then((response) => response.data);
};

export const getFixtures = async (league: string, season: string, round: string): Promise<AFFixtureResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball
        .get('/fixtures?league=' + league + '&season=' + season + '&round=' + round)
        .then((response) => response.data);
};

export const getFixturesById = async (id: string): Promise<AFFixtureResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/fixtures?id=' + id).then((response) => response.data);
};

export const getAssignedTournamentRound = (league: string, season: string, round: string): Promise<TournamentRound> =>
    getTournamentApi2().getTournamentRoundByExternalKey({
        key: JSON.stringify({ league, season, round }),
        provider: API_FOOTBALL_PROVIDER_KEY,}
    );

export const getAssignedTournament = (league: string, season: string): Promise<Tournament> =>
    getTournamentApi2().getTournamentByExternalKey({key: JSON.stringify({ league, season }), provider: API_FOOTBALL_PROVIDER_KEY});

export const ApiFootballRoundFixtures: FC<RoundFixtureProps> = (props) => {
    const [fixtures, setFixtures] = useState<AFFixtureSet[]>();
    const [assignedTournamentRound, setAssignedTournamentRound] = useState<TournamentRound>();
    const [showAssignTournamentRoundDialog, setShowAssignTournamentRoundDialog] = useState(false);

    const selectedLeagues = useContext(ApiFootballContext);

    const dispatch = useDispatch();

    const liveFixturesQuery = useQuery({
        queryKey: ['liveFixtures'],
        queryFn: getLiveFixtures,
        enabled: props.live,
    });

    useEffect(() => {
        if (liveFixturesQuery.data) {
            setFixtures(
                liveFixturesQuery.data.response, //
                //.filter((l) => selectedLeagues.selectedLeagues.includes(l.league.id))
            );
        }
    }, [liveFixturesQuery.data, selectedLeagues.selectedLeagues]);

    const isTournamentRound = (): boolean => !props.live && !!props.league && !!props.season && !!props.round;

    const fixturesQuery = useQuery({
        queryKey: ['getFixtures', props.league, props.season, props.round],
        queryFn: () => getFixtures(props.league || '', props.season || '', props.round || ''),
        enabled: isTournamentRound(),
    });

    useEffect(() => {
        if (fixturesQuery.data) {
            setFixtures(fixturesQuery.data.response);
        }
    }, [fixturesQuery.data]);

    const getAssignedTournamentRoundQuery = useQuery({
        queryKey: ['getAssignedTournamentRound', props.league, props.season, props.round],
        queryFn: () => getAssignedTournamentRound(props.league || '', props.season || '', props.round || ''),
        enabled: isTournamentRound(),
    });

    useEffect(() => {
        if (getAssignedTournamentRoundQuery.data) {
            setAssignedTournamentRound(getAssignedTournamentRoundQuery.data);
        } else {
            setAssignedTournamentRound(undefined);
        }
    }, [getAssignedTournamentRoundQuery.data]);

    const removeAssignmentMutation = useMutation({
        mutationFn: (trId: string) =>
            getTournamentApi2().deleteTournamentRoundExternalkey({trId, provider: API_FOOTBALL_PROVIDER_KEY}),
        onSuccess: () => {
            getAssignedTournamentRoundQuery.refetch();
        },
    });

    const importTournamentRoundMutation = useMutation({
        mutationFn: (trId: string) => getTournamentApi2().importFromProvider({trId, provider: API_FOOTBALL_PROVIDER_KEY}),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Tournament round imported'));
        },
        onError: () => {
            dispatch(systemSetPopupMessage('Tournament round NOT imported'));
        },
    });

    const [showTicker, setShowTicker] = useState<Map<string, boolean>>(new Map());

    const isShowTicker = (fixtureId: string): boolean => {
        return showTicker.get(fixtureId) || false;
    };

    const toggleShowTicker = (fixtureId: string) => {
        setShowTicker(new Map(showTicker.set(fixtureId, !showTicker.get(fixtureId) || false)));
    };

    const showFixture = (fix: AFFixtureSet, index: number, a: AFFixtureSet[]) => {
        const isNewTournamentRound = index === 0 || a[index - 1].league.round !== fix.league.round;
        return (
            <React.Fragment key={fix.fixture.id}>
                {props.live && isNewTournamentRound && (
                    <tr>
                        <td className="align-middle bg-light" colSpan={9}>
                            <small>
                                <Link to={apiFootballLeaguePath(String(fix.league.id), String(fix.league.season))}>
                                    {fix.league.name}
                                </Link>
                                &nbsp;-&nbsp;
                                <Link
                                    to={apiFootballLeaguePath(
                                        String(fix.league.id),
                                        String(fix.league.season),
                                        String(fix.league.round),
                                    )}
                                >
                                    {fix.league.round}
                                </Link>
                            </small>
                        </td>
                    </tr>
                )}
                <tr>
                    <td className="align-middle d-none d-lg-table-cell">
                        <DateTime date={fix.fixture.date} format="short" />
                    </td>
                    <td className="align-middle">
                        <ApiFootballTeamLink id={fix.teams.home.id} name={fix.teams.home.name}>
                            {fix.teams.home.name}
                        </ApiFootballTeamLink>
                    </td>
                    <td className="align-middle">
                        <ApiFootballTeamLink id={fix.teams.away.id} name={fix.teams.away.name}>
                            {fix.teams.away.name}
                        </ApiFootballTeamLink>
                    </td>
                    <td className="align-middle">
                        {fix.goals.home}&nbsp;:&nbsp;{fix.goals.away}
                    </td>
                    <td className="align-middle d-none d-lg-table-cell">
                        <Button color="link" className="m-0 p-0" onClick={() => toggleShowTicker(String(fix.fixture.id))}>
                            Ticker
                        </Button>
                    </td>
                    <td className="align-middle d-table-cell d-lg-none">
                        <Button color="link" className="m-0 p-0" onClick={() => toggleShowTicker(String(fix.fixture.id))}>
                            T
                        </Button>
                    </td>

                    <td className="align-middle d-none d-lg-table-cell">
                        {fix.score.halftime?.home}&nbsp;:&nbsp;{fix.score.halftime?.away}
                    </td>
                    <td className="align-middle d-none d-lg-table-cell">
                        {fix.score.fulltime?.home}&nbsp;:&nbsp;{fix.score.fulltime?.away}
                    </td>
                    <td className="align-middle d-none d-lg-table-cell">
                        {fix.score.extratime?.home}&nbsp;:&nbsp;{fix.score.extratime?.away}
                    </td>
                    <td className="align-middle d-none d-lg-table-cell">
                        {fix.score.penalty?.home}&nbsp;:&nbsp;{fix.score.penalty?.away}
                    </td>
                </tr>
                {isShowTicker(String(fix.fixture.id)) && (
                    <tr>
                        <td colSpan={5}>
                            <ApiFootballTicker key={fix.fixture.id} fixtureId={String(fix.fixture.id)} />
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    const removeTrAssignment = () => {
        if (assignedTournamentRound) {
            removeAssignmentMutation.mutate(String(assignedTournamentRound.id), {
                onSuccess: () => {
                    getAssignedTournamentRoundQuery.refetch();
                    dispatch(systemSetPopupMessage('Tournament round unassigned.'));
                },
            });
        }
    };

    const assignTournamentRoundMutation = useMutation({
        mutationFn: (p: { trId: string; extKey: string }) =>
            getTournamentApi2().assignTournamentRoundExternalkey({trId: p.trId, key: p.extKey, provider: API_FOOTBALL_PROVIDER_KEY}),
    });

    const onAssignTournamentRound = (trId: string, extKey: string) => {
        setShowAssignTournamentRoundDialog(false);
        assignTournamentRoundMutation.mutate(
            { trId, extKey },
            {
                onSuccess: () => {
                    getAssignedTournamentRoundQuery.refetch();
                    dispatch(systemSetPopupMessage('Tournament round assigned.'));
                },
            },
        );
    };

    const importTournamentRound = () => {
        if (assignedTournamentRound) {
            importTournamentRoundMutation.mutate(String(assignedTournamentRound.id));
        }
    };

    return (
        <>
            <FuntipTitle title="Fixtures" subTitle={props.name} />
            <p>
                {assignedTournamentRound && (
                    <>
                        <span>
                            <b>Assigned TournamentRound:&nbsp;</b>
                            <BettingLink trId={String(assignedTournamentRound.id)}>
                                {assignedTournamentRound.longName}
                            </BettingLink>
                        </span>
                        <Button size="sm" className="m-2" onClick={removeTrAssignment} color="warning">
                            Remove Tournament Round Assignment
                        </Button>
                        <Button size="sm" className="m-2" onClick={importTournamentRound}>
                            Import
                        </Button>
                        <br />
                    </>
                )}
                {!assignedTournamentRound && (
                    <Button size="sm" className="m-2" onClick={() => setShowAssignTournamentRoundDialog(true)}>
                        Assign Tournament Round
                    </Button>
                )}
                <AssignTournamentRoundDialog
                    league={props.league || ''}
                    season={props.season || ''}
                    round={props.round || ''}
                    show={showAssignTournamentRoundDialog}
                    onCancel={() => setShowAssignTournamentRoundDialog(false)}
                    onAssign={onAssignTournamentRound}
                />
            </p>
            <Table>
                <thead>
                    <tr>
                        <th className="d-none d-lg-table-cell">Date</th>
                        <th colSpan={2}>Teams</th>
                        <th>Result</th>
                        <th className="d-none d-lg-table-cell">Ticker</th>
                        <th className="d-table-cell d-lg-none">T</th>
                        <th className="d-none d-lg-table-cell">HT</th>
                        <th className="d-none d-lg-table-cell">FT</th>
                        <th className="d-none d-lg-table-cell">ET</th>
                        <th className="d-none d-lg-table-cell">PE</th>
                    </tr>
                </thead>
                <tbody>{fixtures && fixtures.map((f, index, a) => showFixture(f, index, a))}</tbody>
            </Table>
        </>
    );
};

interface AssignTournamentProps {
    league: string;
    season: string;
    show: boolean;
    onCancel: () => void;
    onAssign: (tId: string, externalKey: string) => void;
}

const AssignTournamentDialog: FC<AssignTournamentProps> = (props) => {
    const [tournaments, setTournaments] = useState<Tournament[]>();
    const [tId, setTId] = useState<string>('');

    const availableTournamentsQuery = useQuery({
        queryKey: ['getAvailableTournaments'],
        queryFn: () => getTournamentApi2().getActiveTournaments({clientId: getSystemConfig().clientId}),
    });

    useEffect(() => {
        if (availableTournamentsQuery.data) {
            setTournaments(availableTournamentsQuery.data);
        }
    }, [availableTournamentsQuery.data]);

    const externalKey = {
        league: props.league,
        season: props.season,
    };

    const onSubmit = () => {
        if (tId !== '') {
            props.onAssign(tId, JSON.stringify(externalKey));
        }
    };

    return (
        <>
            <Modal isOpen={props.show}>
                <ModalHeader>
                    Assign Tournament '{props.league}':'{props.season}'
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Tournament:</Label>
                        <Input type="select" onChange={(e) => setTId(e.currentTarget.value)} value={tId}>
                            {tournaments &&
                                tournaments.map((t) => (
                                    <option value={t.id} key={t.id}>
                                        {t.name}
                                    </option>
                                ))}
                            <option value={''} key={''}>
                                None
                            </option>
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button onClick={onSubmit} disabled={tId === ''} color="success">
                        Assign
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

interface AssignTournamentRoundProps {
    league: string;
    season: string;
    round: string;
    show: boolean;
    onCancel: () => void;
    onAssign: (trId: string, externalKey: string) => void;
}

const AssignTournamentRoundDialog: FC<AssignTournamentRoundProps> = (props) => {
    const [trId, setTrId] = useState<string>('');
    const [tournaments, setTournaments] = useState<Tournament[]>();
    const [tId, setTId] = useState<string>('');
    const [tournamentRounds, setTournamentRounds] = useState<TournamentRound[]>();

    const availableTournamentsQuery = useQuery({
        queryKey: ['getAvailableTournaments'],
        queryFn: () => getTournamentApi2().getActiveTournaments({clientId: getSystemConfig().clientId}),
    });

    useEffect(() => {
        if (availableTournamentsQuery.data) {
            setTournaments(availableTournamentsQuery.data);
        }
    }, [availableTournamentsQuery.data]);

    const tournamentRoundsQuery = useQuery({
        queryKey: ['getTournamentRounds', tId],
        queryFn: () => getTournamentApi2().getTournamentRoundsByType({tournamentId: Number(tId), type: 'betting'}),
        enabled: tId !== '',
    });

    useEffect(() => {
        if (tournamentRoundsQuery.data) {
            setTournamentRounds(tournamentRoundsQuery.data);
        }
    }, [tournamentRoundsQuery.data]);

    const externalKey = {
        league: props.league,
        season: props.season,
        round: props.round,
    };

    const onSubmit = () => {
        if (trId !== undefined) {
            props.onAssign(trId, JSON.stringify(externalKey));
        }
    };

    const onTournamentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTId(e.currentTarget.value);
    };

    useEffect(() => {
        tournamentRoundsQuery.refetch();
    }, [tId, tournamentRoundsQuery]);

    return (
        <>
            <Modal isOpen={props.show}>
                <ModalHeader>
                    Assign Tournament Round '{props.league}':'{props.season}' - {props.round}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Tournament:</Label>
                        <Input type="select" onChange={onTournamentChange} value={tId}>
                            {tournaments &&
                                tournaments.map((t) => (
                                    <option value={t.id} key={t.id}>
                                        {t.name}
                                    </option>
                                ))}
                            <option key={''} value={''}>
                                None
                            </option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Tournament Round:</Label>
                        <Input type="select" onChange={(e) => setTrId(e.currentTarget.value)} value={trId}>
                            {tournamentRounds &&
                                tournamentRounds.map((tr) => (
                                    <option value={tr.id} key={tr.id}>
                                        {tr.longName}
                                    </option>
                                ))}
                            <option key={''} value={''}>
                                None
                            </option>
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button onClick={onSubmit} disabled={trId === ''} color="success">
                        Assign
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export const getLeagues = async (): Promise<AFLeagueResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/leagues').then((r) => r.data);
};

interface LeagueListProps {
    addLeague: (id?: number) => void;
    removeLeague: (id?: number) => void;
}
export const ApiFootballLeagueList: FC<LeagueListProps> = (props) => {
    const context = useContext(ApiFootballContext);

    const [leagues, setLeagues] = useState<AFLeagueSet[]>();
    const [searchValue, setSearchValue] = useState('');

    const refreshLeagues = useCallback(() => {
        setLeagues(context.allLeagues.filter((l) => context.selectedLeagues.includes(l.league.id)));
    }, [context.selectedLeagues, context.allLeagues]);

    useEffect(() => {
        refreshLeagues();
    }, [context, refreshLeagues]);

    const onSelected = (n: KeyNameValue) => {
        props.addLeague(Number(n.key));
        setSearchValue('');
        refreshLeagues();
    };

    const getLeagueList = (s: string): KeyNameValue[] => {
        return context.allLeagues
            .map((l) => {
                return { key: String(l.league.id), name: l.league.name + ' (' + l.country.name + ')' } as KeyNameValue;
            })
            .filter((l) => l.name.toLowerCase().includes(s.toLowerCase()));
    };

    return (
        <>
            <small>
                <div className="d-flex flex-wrap">
                    {leagues &&
                        leagues.map((l) => (
                            <div key={l.league.id} className="m-1">
                                <ApiFootballLeagueLink id={l.league.id} removeLeague={props.removeLeague}>
                                    {l.league.name}
                                </ApiFootballLeagueLink>
                            </div>
                        ))}
                </div>
            </small>
            <div className="d-flex flex-nowrap align-items-center align-content-center">
                <Label>Add League</Label>
                <IncrementalSearch
                    isLoaded={() => true}
                    onSelected={onSelected}
                    selectList={getLeagueList}
                    initialSearchValue={searchValue}
                />
            </div>
        </>
    );
};

export const getLeague = async (id: number): Promise<AFLeagueResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/leagues?id=' + id).then((r) => r.data);
};
export const getRounds = async (league: string, season: string): Promise<AFRoundsResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/fixtures/rounds?league=' + league + '&season=' + season).then((response) => response.data);
};

interface LeagueLinkProps extends PropsWithChildren {
    id: number;
    removeLeague: (id?: number) => void;
}
export const ApiFootballLeagueLink: FC<LeagueLinkProps> = (props) => {
    return (
        <>
            <div className="bg-light d-flex align-items-center">
                <Link to={apiFootballLeaguePath(String(props.id), String(''))}>{props.children}</Link>
                <Button color="link" className="m-0" onClick={() => props.removeLeague(props.id)}>
                    x
                </Button>
            </div>
        </>
    );
};

export const ApiFootballLeague: FC<{}> = () => {
    const dispatch = useDispatch();

    const [league] = usePathSegment(3);
    const [season] = usePathSegment(4);
    const [round] = usePathSegment(5);

    const [rounds, setRounds] = useState<string[]>();
    const [leagueEntity, setLeagueEntity] = useState<AFLeagueSet>();

    const [assignedTournament, setAssignedTournament] = useState<Tournament>();
    const [showAssignTournamentDialog, setShowAssignTournamentDialog] = useState(false);

    const assignTournamentMutation = useMutation({
        mutationFn: (p: { tId: string; extKey: string }) =>
            getTournamentApi2().assignTournamentExternalkey({tId: p.tId, key: p.extKey, provider: API_FOOTBALL_PROVIDER_KEY}),
    });

    const getAssignedTournamentQuery = useQuery({
        queryKey: ['getAssignedTournament', league, season],
        queryFn: () => getAssignedTournament(league || '', season || ''),
    });

    useEffect(() => {
        if (getAssignedTournamentQuery.data) {
            setAssignedTournament(getAssignedTournamentQuery.data);
        } else {
            setAssignedTournament(undefined);
        }
    }, [getAssignedTournamentQuery.data]);

    const onAssignTournament = (tId: string, extKey: string) => {
        setShowAssignTournamentDialog(false);
        assignTournamentMutation.mutate(
            { tId, extKey },
            {
                onSuccess: () => {
                    getAssignedTournamentQuery.refetch();
                    dispatch(systemSetPopupMessage('Tournament assigned.'));
                },
            },
        );
    };

    const roundsQuery = useQuery({
        queryKey: ['getRounds', league, season],
        queryFn: () => getRounds(league, season),
    });

    useEffect(() => {
        if (roundsQuery.data) {
            setRounds(roundsQuery.data.response);
        }
    }, [roundsQuery.data]);

    const leagueQuery = useQuery({
        queryKey: ['getLeague', league],
        queryFn: () => getLeague(Number(league)),
    });

    useEffect(() => {
        if (leagueQuery.data) {
            const l = leagueQuery.data.response[0];
            if (l) {
                setLeagueEntity(l);
                if (!season) {
                    const lastSeason = l.seasons.slice(-1)[0];
                    if (lastSeason) {
                        dispatch(push(apiFootballLeaguePath(league, String(lastSeason.year))));
                    }
                }
                // remove duplicate entries
                l.seasons = [...new Map(l.seasons.map((item) => [item.year, item])).values()];
            }
        }
    }, [dispatch, league, leagueQuery.data, season]);

    const showRounds = (round: string, selectedRound: string) => {
        return (
            <div className="m-1" key={round}>
                {selectedRound === round && <span className="font-weight-bold text-primary">{round}</span>}
                {selectedRound !== round && (
                    <Link to={apiFootballLeaguePath(String(leagueEntity?.league.id), String(season), round)}>
                        {round}
                    </Link>
                )}
            </div>
        );
    };

    const showSeason = (season: AFSeason, selectedSeason: string) => {
        return (
            <div className="m-1" key={season.year}>
                {selectedSeason === String(season.year) && (
                    <span className="font-weight-bold text-primary">{season.year}</span>
                )}
                {selectedSeason !== String(season.year) && (
                    <Link to={apiFootballLeaguePath(String(leagueEntity?.league.id), String(season.year))}>
                        {season.year}
                    </Link>
                )}
            </div>
        );
    };
    const removeAssignmentMutation = useMutation({
        mutationFn: (tId: string) => getTournamentApi2().deleteTournamentExternalkey({tId, provider: API_FOOTBALL_PROVIDER_KEY}),

        onSuccess: () => {
            getAssignedTournamentQuery.refetch();
        },
    });

    const removeTournamentAssignment = () => {
        if (assignedTournament) {
            removeAssignmentMutation.mutate(String(assignedTournament.id), {
                onSuccess: () => getAssignedTournamentQuery.refetch(),
            });
        }
    };

    const importTournamentMutation = useMutation({
        mutationFn: (tId: string) => getTournamentApi2().importTournamentFromProvider({tId, provider: API_FOOTBALL_PROVIDER_KEY}),
        onSuccess: () => {},
    });

    const importTournament = () => {
        if (assignedTournament) {
            importTournamentMutation.mutate(String(assignedTournament.id));
        }
    };

    const assignAllTournamentRounds = () => {
        if (assignedTournament) {
            const param = {
                tId: String(assignedTournament.id),
                keys:
                    rounds?.map((r) => {
                        return JSON.stringify({ league, season, round: r });
                    }) ?? [],
            };
            assignAllTournamentRoundsMutation.mutate(param);
        }
    };

    const assignAllTournamentRoundsMutation = useMutation({
        mutationFn: (param: { tId: string; keys: string[] }) =>
            getTournamentApi2().assignAllTournamentRounds({tId: param.tId, provider: API_FOOTBALL_PROVIDER_KEY, roundKeys: param.keys}),

        onSuccess: () => {},
    });

    return (
        <>
            <h2>{leagueEntity?.league.name}</h2>
            <h4>Seasons</h4>
            <div className="d-flex flex-wrap">{leagueEntity?.seasons.map((s) => showSeason(s, season))}</div>
            {assignedTournament && (
                <>
                    <span>
                        <b>Assigned Tournament:&nbsp;</b>
                        {assignedTournament.name}
                    </span>
                    <br />
                    <Button size="sm" className="m-2" onClick={removeTournamentAssignment} color="warning">
                        Remove Tournament Assignment
                    </Button>
                    <Button size="sm" className="m-2" onClick={assignAllTournamentRounds}>
                        Assign all Tournament Rounds
                    </Button>
                    <Button size="sm" className="m-2" onClick={importTournament}>
                        Import
                    </Button>
                </>
            )}{' '}
            {!assignedTournament && (
                <Button size="sm" onClick={() => setShowAssignTournamentDialog(true)}>
                    Assign Tournament
                </Button>
            )}
            <AssignTournamentDialog
                league={league || ''}
                season={season || ''}
                show={showAssignTournamentDialog}
                onCancel={() => setShowAssignTournamentDialog(false)}
                onAssign={onAssignTournament}
            />
            <h4>Rounds</h4>
            <div className="d-flex flex-wrap">{rounds && rounds.map((r) => showRounds(r, round))}</div>
            <ApiFootballRoundFixtures name={round} season={season} league={league} round={round} live={false} />
        </>
    );
};

export const apiFootballLeaguePath = (league: string, season: string, round?: string) =>
    `/a/league/${league}/${season}/${round}`;
