/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActionTokenCheckResponse,
  ActionTokenType,
} from '../models/index';
import {
    ActionTokenCheckResponseFromJSON,
    ActionTokenCheckResponseToJSON,
    ActionTokenTypeFromJSON,
    ActionTokenTypeToJSON,
} from '../models/index';

export interface CheckActionTokenRequest {
    atId?: string;
    secId?: string;
    uId?: string;
}

export interface ExecuteActionTokenRequest {
    atId?: string;
    secId?: string;
    tag?: string;
    uId?: string;
}

/**
 * 
 */
export class ActionToken2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async checkActionTokenRaw(requestParameters: CheckActionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionTokenCheckResponse>> {
        const queryParameters: any = {};

        if (requestParameters['atId'] != null) {
            queryParameters['atId'] = requestParameters['atId'];
        }

        if (requestParameters['secId'] != null) {
            queryParameters['secId'] = requestParameters['secId'];
        }

        if (requestParameters['uId'] != null) {
            queryParameters['uId'] = requestParameters['uId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/action-token/check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionTokenCheckResponseFromJSON(jsonValue));
    }

    /**
     */
    async checkActionToken(requestParameters: CheckActionTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionTokenCheckResponse> {
        const response = await this.checkActionTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async executeActionTokenRaw(requestParameters: ExecuteActionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionTokenType>> {
        const queryParameters: any = {};

        if (requestParameters['atId'] != null) {
            queryParameters['atId'] = requestParameters['atId'];
        }

        if (requestParameters['secId'] != null) {
            queryParameters['secId'] = requestParameters['secId'];
        }

        if (requestParameters['tag'] != null) {
            queryParameters['tag'] = requestParameters['tag'];
        }

        if (requestParameters['uId'] != null) {
            queryParameters['uId'] = requestParameters['uId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/action-token/execute`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionTokenTypeFromJSON(jsonValue));
    }

    /**
     */
    async executeActionToken(requestParameters: ExecuteActionTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionTokenType> {
        const response = await this.executeActionTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
