/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OAuthTokenResponse,
} from '../models';
import {
    OAuthTokenResponseFromJSON,
    OAuthTokenResponseToJSON,
} from '../models';

export interface AuthRedirectRequest {
    providerId: string;
    code: string;
    state: string;
}

export interface GetTokenRequest {
    tenant: string;
    code: string;
    clientId: string;
    clientSecret: string;
    redirectUri: string;
    grantType: string;
}

export interface RefreshSessionTokenRequest {
    tenant: string;
}

/**
 * 
 */
export class DelegatingOAuth3ControllerApi extends runtime.BaseAPI {

    /**
     */
    async authRedirectRaw(requestParameters: AuthRedirectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling authRedirect.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling authRedirect.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling authRedirect.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth3delegate/auth-redirect/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authRedirect(providerId: string, code: string, state: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authRedirectRaw({ providerId: providerId, code: code, state: state }, initOverrides);
    }

    /**
     */
    async getTokenRaw(requestParameters: GetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OAuthTokenResponse>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling getToken.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getToken.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getToken.');
        }

        if (requestParameters.clientSecret === null || requestParameters.clientSecret === undefined) {
            throw new runtime.RequiredError('clientSecret','Required parameter requestParameters.clientSecret was null or undefined when calling getToken.');
        }

        if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
            throw new runtime.RequiredError('redirectUri','Required parameter requestParameters.redirectUri was null or undefined when calling getToken.');
        }

        if (requestParameters.grantType === null || requestParameters.grantType === undefined) {
            throw new runtime.RequiredError('grantType','Required parameter requestParameters.grantType was null or undefined when calling getToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.clientSecret !== undefined) {
            queryParameters['client_secret'] = requestParameters.clientSecret;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirect_uri'] = requestParameters.redirectUri;
        }

        if (requestParameters.grantType !== undefined) {
            queryParameters['grant_type'] = requestParameters.grantType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth3delegate/{tenant}/token`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters.tenant))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OAuthTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async getToken(tenant: string, code: string, clientId: string, clientSecret: string, redirectUri: string, grantType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OAuthTokenResponse> {
        const response = await this.getTokenRaw({ tenant: tenant, code: code, clientId: clientId, clientSecret: clientSecret, redirectUri: redirectUri, grantType: grantType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTokenFromSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth3delegate/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getTokenFromSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTokenFromSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshSessionTokenRaw(requestParameters: RefreshSessionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling refreshSessionToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth3delegate/{tenant}/refresh-session-token`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters.tenant))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async refreshSessionToken(tenant: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.refreshSessionTokenRaw({ tenant: tenant }, initOverrides);
        return await response.value();
    }

}
