import {
    ActionTokenControllerApi,
    AliveControllerApi,
    CacheControllerApi,
    ClientPropertyControllerApi,
    Configuration,
    ConfigurationParameters,
    DelegatingOAuth3ControllerApi,
    DeviceControllerApi,
    EventHandlerConfigurationControllerApi,
    FetchAPI,
    JobControllerApi,
    LoggingControllerApi,
    LoginControllerApi,
    PasswordForgottenControllerApi,
    ProfileControllerApi,
    PushNotificationControllerApi,
    RegistrationControllerApi,
    SqlStatisticsControllerApi,
    SysadminProfileControllerApi,
    SystemControllerApi,
    SystemPropertyControllerApi,
    UseCaseLogControllerApi,
    WebUsageControllerApi,
} from '../../services/account';
import {
    BettingControllerApi,
    BettingUserControllerApi,
    Configuration as BettingConfiguration,
    ConfigurationParameters as BettingConfigurationParameters,
    FeedbackControllerApi,
    GameControllerApi,
    GroupHighscoreControllerApi,
    HighscoreControllerApi,
    PortletControllerApi,
    PricesControllerApi,
    RankingControllerApi,
    Team,
    TeamControllerApi,
    TournamentControllerApi,
    UserGroupAdminControllerApi,
    UserGroupControllerApi,
    UserMailControllerApi,
    TournamentSysadminControllerApi,
    ClientSysadminControllerApi,
    ExternalKeyControllerApi,
    TeamsSysadminControllerApi,
    SysadminBettingProfileControllerApi,
} from '../../services/betting';
import {
    BlogControllerApi,
    Configuration as ImageConfiguration,
    ConfigurationParameters as ImageConfigurationParameters,
    ImageControllerApi,
    NewsletterControllerApi,
    TeamChatControllerApi,
} from '../../services/image';
import {
    Configuration as PlaylistConfiguration,
    ConfigurationParameters as PlaylistConfigurationParameters,
    PlaylistAdminControllerApi,
    PlaylistControllerApi,
    PlaylistUserControllerApi,
} from '../../services/playlist';
import {
    ActionToken2ControllerApi,
    ClientAdminControllerApi,
    ClientProperty2ControllerApi,
    Image2ControllerApi,
    Login2ControllerApi,
    MailoutControllerApi,
    Newsletter2ControllerApi,
    NewsletterAdminControllerApi,
    Playlist2ControllerApi,
    PlaylistUser2ControllerApi,
    Profile2ControllerApi,
    PushNotification2ControllerApi,
    Registration2ControllerApi,
    SysadminProfile2ControllerApi,
    TeamChat2ControllerApi,
    TranslationControllerApi,
    UpdateTaskControllerApi,
    Configuration as WebappConfiguration,
    ConfigurationParameters as WebappConfigurationParameters,
} from '../../services/webapp';
import { getFetchHeaders } from './system';

export interface DeploymentProperties {
    bettingUrl: string;
    profileApiUrl: string;
    tokenValidationApiUrl: string;
    authenticationUrl: string;
    authorizationUrl: string;
    tokenRefreshUrl: string;
    tokenExpiredUrl: string;
    deleteTokenUrl: string;
    pushSettingsUrl: string;
    deviceTokenApiUrl: string;
}

export enum AppFeature {
    playlist,
    newsletter,
    r1comment,
    rssPortal,
    blog,
}

export interface SystemConfig {
    bettingApiUrl: string;
    bettingGraphQLUrl: string;
    accountApiUrl: string;
    playlistApiUrl: string;
    imageApiUrl: string;
    webappApiUrl: string;
    iframeApiUrl: string;
    playlistStationId: string;
    clientId: number;
    clientName: string;
    oauthClientId: string;
    oauthRedirectUrl: string;
    oauthAuthorizationUri: string;
    oauthAuthenticationUri: string;
    oauthScopes: string[];
    features: AppFeature[];
    imageUrl: (imageId: string) => string;
    serverUrls: { [serverName in ServerType]: string };
    // matchStatisticsUrl: (team1Id: string, team2Id: string) => string;
    mobileWebUrl: () => string;
    isUsePopupLogin: () => boolean;
    isProduction: () => boolean;
}

export const localBaseUrl = () => window.location.protocol + '//' + window.location.host;

export type ServerType = 'betting' | 'account' | 'playlist' | 'image' | 'webapp';

export const relevantServerNames = () => Object.keys(getSystemConfig().serverUrls).filter(
    (s) => s !== 'account' && s !== 'image' && s !== 'playlist',
);

const DevelopmentRadioEinsSystemConfig: SystemConfig = {
    // bettingApiUrl: 'http://192.168.99.100:30352',
    bettingApiUrl: 'http://localhost:8082',
    bettingGraphQLUrl: 'http://localhost:8082/graphql',
    accountApiUrl: '//accounts.local.funtip.de:8081',
    playlistApiUrl: 'http://localhost:8083',
    imageApiUrl: 'http://localhost:8080',
    webappApiUrl: 'http://localhost:8080',
    iframeApiUrl: 'http://iframe.local.funtip.de:3000',
    playlistStationId: 'R1',
    clientId: 3504492,
    clientName: 'radioeins',
    oauthClientId: 'radioeins@funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'http://accounts.local.funtip.de:8081/oauth3/radioeins/token',
    oauthAuthorizationUri: 'http://accounts.local.funtip.de:8081/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [AppFeature.newsletter, AppFeature.r1comment, AppFeature.rssPortal, AppFeature.blog],
    imageUrl: (imageId: string) => `http://localhost:8080/image/${imageId}`,
    serverUrls: {
        playlist: '//betting.local.funtip.de:8083',
        account: '//accounts.local.funtip.de:8081',
        betting: 'http://localhost:8082',
        image: '//image.local.funtip.de:8084',
        webapp: 'http://localhost:8080',
    },
    mobileWebUrl: () => 'http://r1mobile.local.funtip.de:8080',
    isUsePopupLogin: () => true,
    isProduction: () => false,
};

const DevelopmentFuntipSystemConfig: SystemConfig = {
    bettingApiUrl: 'http://localhost:8082',
    bettingGraphQLUrl: 'http://localhost:8082/graphql',
    accountApiUrl: '//accounts.local.funtip.de:8081',
    playlistApiUrl: 'http://localhost:8083',
    imageApiUrl: 'http://localhost:8080',
    webappApiUrl: 'http://localhost:8080',
    iframeApiUrl: 'http://localhost:3000',
    playlistStationId: '',
    clientId: 3434703,
    clientName: 'funtip',
    oauthClientId: 'www.funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'http://accounts.local.funtip.de:8081/oauth3/funtip/token',
    oauthAuthorizationUri: 'http://accounts.local.funtip.de:8081/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [AppFeature.blog],
    imageUrl: (imageId: string) => `http://localhost:8080/image/${imageId}`,
    serverUrls: {
        playlist: 'http://localhost:8083',
        account: '//accounts.local.funtip.de:8081',
        betting: 'http://localhost:8082',
        image: '//localhost:8084',
        webapp: 'http://localhost:8080',
    },
    mobileWebUrl: () => 'http://www.local.funtip.de:8080',
    isUsePopupLogin: () => true,
    isProduction: () => false,
};

export const ProductionRadioEinsSystemConfig: SystemConfig = {
    bettingApiUrl: 'https://qbetting.funtip.de',
    bettingGraphQLUrl: 'https://betting.funtip.de/graphql',
    accountApiUrl: 'https://accounts.funtip.de',
    playlistApiUrl: 'https://playlist.funtip.de',
    imageApiUrl: 'https://qwebapp.funtip.de',
    webappApiUrl: 'https://qwebapp.funtip.de',
    iframeApiUrl: 'https://loginiframe.radioeins.de',
    playlistStationId: 'R1',
    clientId: 3504492,
    clientName: 'radioeins',
    oauthClientId: 'radioeins@funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'https://accounts.funtip.de/oauth3/radioeins/token',
    oauthAuthorizationUri: 'https://accounts.funtip.de/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [AppFeature.newsletter, AppFeature.r1comment, AppFeature.rssPortal, AppFeature.blog, AppFeature.playlist],
    imageUrl: (imageId: string) => `https://qwebapp.funtip.de/image/${imageId}`,
    serverUrls: {
        playlist: 'https://playlist.funtip.de',
        account: 'https://accounts.funtip.de',
        betting: 'https://qbetting.funtip.de',
        image: 'https://image.funtip.de',
        webapp: 'https://qwebapp.funtip.de',
    },
    mobileWebUrl: () => 'https://r1mobile.funtip.de',
    isUsePopupLogin: () => true,
    isProduction: () => true,
};

export const TestRadioEinsSystemConfig: SystemConfig = {
    bettingApiUrl: 'https://test-betting.funtip.de',
    bettingGraphQLUrl: 'https://test-betting.funtip.de/graphql',
    accountApiUrl: 'https://test-accounts.funtip.de',
    playlistApiUrl: 'https://test-playlist.funtip.de',
    imageApiUrl: 'https://test-image.funtip.de',
    webappApiUrl: 'https://test-webapp.funtip.de',
    iframeApiUrl: 'https://loginiframe.radioeins.de',
    playlistStationId: 'R1',
    clientId: 3504492,
    clientName: 'radioeins',
    oauthClientId: 'radioeins@funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'https://test-accounts.funtip.de/oauth3/radioeins/token',
    oauthAuthorizationUri: 'https://test-accounts.funtip.de/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [AppFeature.newsletter, AppFeature.r1comment, AppFeature.rssPortal, AppFeature.blog, AppFeature.playlist],
    imageUrl: (imageId: string) => `https://test-image.funtip.de/image/${imageId}`,
    serverUrls: {
        playlist: 'https://test-playlist.funtip.de',
        account: 'https://test-accounts.funtip.de',
        betting: 'https://test-betting.funtip.de',
        image: 'https://test-image.funtip.de',
        webapp: 'https://test-qwebapp.funtip.de',
    },
    mobileWebUrl: () => 'https://test-r1mobile.funtip.de',
    isUsePopupLogin: () => true,
    isProduction: () => false,
};

export const ProductionFuntipSystemConfig: SystemConfig = {
    bettingApiUrl: 'https://qbetting.funtip.de',
    bettingGraphQLUrl: 'https://betting.funtip.de/graphql',
    accountApiUrl: 'https://accounts.funtip.de',
    playlistApiUrl: 'https://playlist.funtip.de',
    imageApiUrl: 'https://qwebapp.funtip.de',
    webappApiUrl: 'https://qwebapp.funtip.de',
    iframeApiUrl: 'https://iframe.funtip.de',
    playlistStationId: '',
    clientId: 3434703,
    clientName: 'funtip',
    oauthClientId: 'www.funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'https://accounts.funtip.de/oauth3/funtip/token',
    oauthAuthorizationUri: 'https://accounts.funtip.de/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [],
    imageUrl: (imageId: string) => `https://qwebapp.funtip.de/image/${imageId}`,
    serverUrls: {
        playlist: 'https://playlist.funtip.de',
        account: 'https://accounts.funtip.de',
        betting: 'https://qbetting.funtip.de',
        image: 'https://image.funtip.de',
        webapp: 'https://qwebapp.funtip.de',
    },
    mobileWebUrl: () => 'https://www.funtip.de',
    isUsePopupLogin: () => true,
    isProduction: () => true,
};

export const TestFuntipSystemConfig: SystemConfig = {
    bettingApiUrl: 'https://test-betting.funtip.de',
    bettingGraphQLUrl: 'https://test-betting.funtip.de/graphql',
    accountApiUrl: 'https://test-accounts.funtip.de',
    playlistApiUrl: 'https://test-playlist.funtip.de',
    imageApiUrl: 'https://test-image.funtip.de',
    webappApiUrl: 'https://test-webapp.funtip.de',
    iframeApiUrl: 'https://iframe.funtip.de',
    playlistStationId: '',
    clientId: 3434703,
    clientName: 'funtip',
    oauthClientId: 'www.funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'https://test-accounts.funtip.de/oauth3/funtip/token',
    oauthAuthorizationUri: 'https://test-accounts.funtip.de/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [],
    imageUrl: (imageId: string) => `https://test-image.funtip.de/image/${imageId}`,
    serverUrls: {
        playlist: 'https://test-playlist.funtip.de',
        account: 'https://test-accounts.funtip.de',
        betting: 'https://test-betting.funtip.de',
        image: 'https://test-image.funtip.de',
        webapp: 'https://qwebapp.funtip.de',
    },
    mobileWebUrl: () => 'https://test-www.funtip.de',
    isUsePopupLogin: () => true,
    isProduction: () => false,
};

export const DevLinodeSystemConfig: SystemConfig = {
    bettingApiUrl: 'https://linode-betting.funtip.de',
    bettingGraphQLUrl: 'https://linode-betting.funtip.de/graphql',
    accountApiUrl: 'https://linode-accounts.funtip.de',
    playlistApiUrl: 'https://linode-playlist.funtip.de',
    imageApiUrl: 'https://linode-image.funtip.de',
    webappApiUrl: 'https://linode-webapp.funtip.de',
    iframeApiUrl: 'https://loginiframe.radioeins.de',
    playlistStationId: 'R1',
    clientId: 3504492,
    clientName: 'radioeins',
    oauthClientId: 'radioeins@funtip.de',
    oauthRedirectUrl: localBaseUrl() + '/oauth2callback',
    oauthAuthenticationUri: 'https://linode-accounts.funtip.de/oauth3/radioeins/token',
    oauthAuthorizationUri: 'https://linode-accounts.funtip.de/oauth3delegate/auth',
    oauthScopes: ['email', 'profile'],
    features: [AppFeature.newsletter, AppFeature.r1comment, AppFeature.rssPortal, AppFeature.blog, AppFeature.playlist],
    imageUrl: (imageId: string) => `https://linode-webapp.funtip.de/image/${imageId}`,
    serverUrls: {
        playlist: 'https://linode-playlist.funtip.de',
        account: 'https://linode-accounts.funtip.de',
        betting: 'https://linode-betting.funtip.de',
        image: 'https://linode-image.funtip.de',
        webapp: 'https://linode-qwebapp.funtip.de',
    },
    mobileWebUrl: () => 'https://r1mobile.funtip.de',
    isUsePopupLogin: () => false,
    isProduction: () => false,
};

export const ardLiveTickerUrl = (tickerId: string) => `http://www1.sportschau.de/ticker/html/${tickerId}`;

export type Client = 'radioeins' | 'funtip' | 'r1mobile';

export function getClient(): Client {
    const currentUrl = window.location.href;
    if (currentUrl.includes('linode-r1')) {
        return 'radioeins';
    } else if (currentUrl.includes('r1mobile') || currentUrl.includes('rbbradio')) {
        return 'r1mobile';
    } else if (currentUrl.includes('radioeins') || currentUrl.includes('r1') || currentUrl.includes('rbb-online')) {
        return 'radioeins';
    } else {
        return 'funtip';
    }
}

export function isTestSystem(): boolean {
    const currentUrl = window.location.href;
    return currentUrl.includes('test') || currentUrl.includes('beta');
}

export function isRadioEins() {
    return isR1Mobile() || isR1WebApp();
}

export function isR1WebApp() {
    return getClient() === 'radioeins';
}

export function isFuntip() {
    return getClient() === 'funtip';
}

export function isR1Mobile() {
    return getClient() === 'r1mobile';
}

export function isLocal() {
    return window.location.href.includes('local') || window.location.href.includes('192.168');
}

function isLinode() {
    return window.location.href.includes('linode');
}

export function registerAuthToken(token: String) {
    if (isWebKit()) {
        try {
            const w = window as any;
            if (w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.registerAuthToken) {
                w.webkit.messageHandlers.registerAuthToken.postMessage({ token: token });
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export function unregisterAuthToken(token: String) {
    if (isWebKit()) {
        try {
            const w = window as any;
            if (w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.unregisterAuthToken) {
                w.webkit.messageHandlers.unregisterAuthToken.postMessage({ token: token });
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export function getAuthToken() {
    if (isWebKit()) {
        try {
            const w = window as any;
            if (w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.getAuthToken) {
                w.webkit.messageHandlers.getAuthToken.postMessage({ dummy: 'dummy' });
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export const isWebKit = () => {
    return /WebKit/.test(navigator.userAgent);
};

export const getStaticFilesBaseUrl = () => 'https://static2.funtip.de';

export function getSystemConfig() {
    const client = getClient();
    if (isLocal()) {
        if (client === 'radioeins' || client === 'r1mobile') {
            return DevelopmentRadioEinsSystemConfig;
        } else {
            return DevelopmentFuntipSystemConfig;
        }
    } else if (isLinode()) {
        return DevLinodeSystemConfig;
    } else if (client === 'radioeins' || client === 'r1mobile') {
        if (isTestSystem()) {
            return TestRadioEinsSystemConfig;
        } else {
            return ProductionRadioEinsSystemConfig;
        }
    } else {
        if (isTestSystem()) {
            return TestFuntipSystemConfig;
        } else {
            return ProductionFuntipSystemConfig;
        }
    }
}

export function hasFeature(feature: AppFeature) {
    return getSystemConfig().features.indexOf(feature) !== -1;
}

export const isDefaultSport = (t: Team) => {
    // FIXME move to client configuration
    return 'Fußball' === t.sportName;
};

/* Rest-Api Access-Function */

function updateOptions(options: RequestInit | undefined) {
    const update: RequestInit = { ...options };
    const standardHeaders = getFetchHeaders();
    update.headers = {
        ...update.headers,
        ...standardHeaders,
    };
    return update;
}

export const fetchFunction: FetchAPI = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    return fetch(input, updateOptions(init));
};

const getOpenApiAccountConfig = (basePath: string): Configuration => {
    const configParams: ConfigurationParameters = {
        basePath,
        headers: getFetchHeaders(),
        fetchApi: fetchFunction,
    };
    return new Configuration(configParams);
};

const getOpenApiBettingConfig = (basePath: string): BettingConfiguration => {
    const configParams: BettingConfigurationParameters = {
        basePath,
        headers: getFetchHeaders(),
        fetchApi: fetchFunction,
    };
    return new BettingConfiguration(configParams);
};

const getOpenApiPlaylistConfig = (basePath: string): PlaylistConfiguration => {
    const configParams: PlaylistConfigurationParameters = {
        basePath,
        headers: getFetchHeaders(),
        fetchApi: fetchFunction,
    };
    return new PlaylistConfiguration(configParams);
};

const getOpenApiImageConfig = (basePath: string): ImageConfiguration => {
    const configParams: ImageConfigurationParameters = {
        basePath,
        headers: getFetchHeaders(),
        fetchApi: fetchFunction,
    };
    return new ImageConfiguration(configParams);
};

const getOpenApiWebappConfig = (basePath: string): WebappConfiguration => {
    const configParams: WebappConfigurationParameters = {
        basePath,
        headers: getFetchHeaders(),
        fetchApi: fetchFunction,
    };
    return new WebappConfiguration(configParams);
};

export const getJobApi = (serverName: ServerType) => {
    return new JobControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};

export const getMailoutApi = () => {
    return new MailoutControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getUpdateTaskApi = () => {
    return new UpdateTaskControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getTranslationApi = () => {
    return new TranslationControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getNewsletterAdminApi = () => {
    return new NewsletterAdminControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getClientAdminApi = () => {
    return new ClientAdminControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getEventHandlerConfigApi = (serverName: ServerType) => {
    const d = new EventHandlerConfigurationControllerApi(
        getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]),
    );
    return d;
};

export const getUseCaseLogApi = (serverName: ServerType) => {
    return new UseCaseLogControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};
export const getWebUsageApi = (serverName: ServerType) => {
    return new WebUsageControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};

export const getNewsletterApi = () => {
    return new NewsletterControllerApi(getOpenApiImageConfig(getSystemConfig().imageApiUrl));
};

export const getNewsletter2Api = () => {
    return new Newsletter2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getSqlStatisticsApi = (serverName: ServerType) => {
    return new SqlStatisticsControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};

export const getHighscoreApi2 = () => {
    return new HighscoreControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};
export const getExternalKeyApi = () => {
    return new ExternalKeyControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getClientPropertyApi = () => {
    return new ClientPropertyControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getClientProperty2Api = () => {
    return new ClientProperty2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getSystemApi = () => {
    return new SystemControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getPlaylistAdminApi = () => {
    return new PlaylistAdminControllerApi(getOpenApiPlaylistConfig(getSystemConfig().playlistApiUrl));
};

export const getPrizesApi2 = () => {
    return new PricesControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getGameApi2 = () => {
    return new GameControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};
export const getUserGroupApi2 = () => {
    return new UserGroupControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getUserGroupAdminApi2 = () => {
    return new UserGroupAdminControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getPushNotificationApi = () => {
    return new PushNotificationControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getPushNotification2Api = () => {
    return new PushNotification2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getBettingApi2 = () => {
    return new BettingControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getTeamChatApi = () => {
    return new TeamChatControllerApi(getOpenApiImageConfig(getSystemConfig().imageApiUrl));
};

export const getTeamChat2Api = () => {
    return new TeamChat2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getLoggingApi = () => {
    return new LoggingControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getPasswordForgottenApi = () => {
    return new PasswordForgottenControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getLoginApi = () => {
    return new LoginControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getLogin2Api = () => {
    return new Login2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getRegistrationApi = () => {
    return new RegistrationControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getRegistration2Api = () => {
    return new Registration2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getBlogApi = () => {
    return new BlogControllerApi(getOpenApiImageConfig(getSystemConfig().imageApiUrl));
};

export const getTournamentSysAdminApi = () => {
    return new TournamentSysadminControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getClientSysAdminApi = () => {
    return new ClientSysadminControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getPortletApi2 = () => {
    return new PortletControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getFeedbackApi2 = () => {
    return new FeedbackControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getUserMailApi2 = () => {
    return new UserMailControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getCacheApi = (serverName: ServerType) => {
    return new CacheControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};

export const getAliveApi = (serverName: ServerType) => {
    return new AliveControllerApi(getOpenApiAccountConfig(getSystemConfig().serverUrls[serverName]));
};

export const getTournamentApi2 = () => {
    return new TournamentControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getTeamApi2 = () => {
    return new TeamControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};
export const getRankingApi2 = () => {
    return new RankingControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getDeviceApi = () => {
    return new DeviceControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getProfileApi = () => {
    return new ProfileControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getProfile2Api = () => {
    return new Profile2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};
export const getSystemPropertyApi = () => {
    return new SystemPropertyControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getDelegatingOAuth3ControllerApi = () => {
    return new DelegatingOAuth3ControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};
export const getActionTokenApi = () => {
    return new ActionTokenControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getActionToken2Api = () => {
    return new ActionToken2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getSysAdminProfileApi = () => {
    return new SysadminProfileControllerApi(getOpenApiAccountConfig(getSystemConfig().accountApiUrl));
};

export const getSysAdminProfile2Api = () => {
    return new SysadminProfile2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getBettingUserApi2 = () => {
    return new BettingUserControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getSysadminBettingProfileApi = () => {
    return new SysadminBettingProfileControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getTeamSysadminApi = () => {
    return new TeamsSysadminControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const getPlaylistApi = () => {
    return new PlaylistControllerApi(getOpenApiPlaylistConfig(getSystemConfig().playlistApiUrl));
};

export const getPlaylist2Api = () => {
    return new Playlist2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getPlaylistUser2Api = () => {
    return new PlaylistUser2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getImageApi = () => {
    return new ImageControllerApi(getOpenApiImageConfig(getSystemConfig().imageApiUrl));
};

export const getImage2Api = () => {
    return new Image2ControllerApi(getOpenApiWebappConfig(getSystemConfig().webappApiUrl));
};

export const getPlaylistUserApi = () => {
    return new PlaylistUserControllerApi(getOpenApiPlaylistConfig(getSystemConfig().playlistApiUrl));
};

export const getGroupHighscoreApi2 = () => {
    return new GroupHighscoreControllerApi(getOpenApiBettingConfig(getSystemConfig().bettingApiUrl));
};

export const ACCESS_TOKEN_KEY = 'ftAccessToken';
