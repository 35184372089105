/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterAdmin
 */
export interface NewsletterAdmin {
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    author: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterAdmin
     */
    receiverCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NewsletterAdmin
     */
    archiveAccessCount: number;
    /**
     * 
     * @type {number}
     * @memberof NewsletterAdmin
     */
    clickCount: number;
    /**
     * 
     * @type {number}
     * @memberof NewsletterAdmin
     */
    state: number;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterAdmin
     */
    lastEdited: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterAdmin
     */
    startSending?: Date;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    layoutName: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    layoutId: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    receiverFilterName: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    receiverFilterId: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterAdmin
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    headerText?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    headerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    headerLink?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    headerImageId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    footerText?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    footerTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    footerLink?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterAdmin
     */
    footerImageId?: string;
}

/**
 * Check if a given object implements the NewsletterAdmin interface.
 */
export function instanceOfNewsletterAdmin(value: object): value is NewsletterAdmin {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('author' in value) || value['author'] === undefined) return false;
    if (!('archiveAccessCount' in value) || value['archiveAccessCount'] === undefined) return false;
    if (!('clickCount' in value) || value['clickCount'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('lastEdited' in value) || value['lastEdited'] === undefined) return false;
    if (!('layoutName' in value) || value['layoutName'] === undefined) return false;
    if (!('layoutId' in value) || value['layoutId'] === undefined) return false;
    if (!('receiverFilterName' in value) || value['receiverFilterName'] === undefined) return false;
    if (!('receiverFilterId' in value) || value['receiverFilterId'] === undefined) return false;
    return true;
}

export function NewsletterAdminFromJSON(json: any): NewsletterAdmin {
    return NewsletterAdminFromJSONTyped(json, false);
}

export function NewsletterAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterAdmin {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'author': json['author'],
        'receiverCount': json['receiverCount'] == null ? undefined : json['receiverCount'],
        'archiveAccessCount': json['archiveAccessCount'],
        'clickCount': json['clickCount'],
        'state': json['state'],
        'lastEdited': (new Date(json['lastEdited'])),
        'startSending': json['startSending'] == null ? undefined : (new Date(json['startSending'])),
        'layoutName': json['layoutName'],
        'layoutId': json['layoutId'],
        'receiverFilterName': json['receiverFilterName'],
        'receiverFilterId': json['receiverFilterId'],
        'archived': json['archived'] == null ? undefined : json['archived'],
        'headerText': json['headerText'] == null ? undefined : json['headerText'],
        'headerTitle': json['headerTitle'] == null ? undefined : json['headerTitle'],
        'headerLink': json['headerLink'] == null ? undefined : json['headerLink'],
        'headerImageId': json['headerImageId'] == null ? undefined : json['headerImageId'],
        'footerText': json['footerText'] == null ? undefined : json['footerText'],
        'footerTitle': json['footerTitle'] == null ? undefined : json['footerTitle'],
        'footerLink': json['footerLink'] == null ? undefined : json['footerLink'],
        'footerImageId': json['footerImageId'] == null ? undefined : json['footerImageId'],
    };
}

export function NewsletterAdminToJSON(value?: NewsletterAdmin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'author': value['author'],
        'receiverCount': value['receiverCount'],
        'archiveAccessCount': value['archiveAccessCount'],
        'clickCount': value['clickCount'],
        'state': value['state'],
        'lastEdited': ((value['lastEdited']).toISOString()),
        'startSending': value['startSending'] == null ? undefined : ((value['startSending']).toISOString()),
        'layoutName': value['layoutName'],
        'layoutId': value['layoutId'],
        'receiverFilterName': value['receiverFilterName'],
        'receiverFilterId': value['receiverFilterId'],
        'archived': value['archived'],
        'headerText': value['headerText'],
        'headerTitle': value['headerTitle'],
        'headerLink': value['headerLink'],
        'headerImageId': value['headerImageId'],
        'footerText': value['footerText'],
        'footerTitle': value['footerTitle'],
        'footerLink': value['footerLink'],
        'footerImageId': value['footerImageId'],
    };
}

