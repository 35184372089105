/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamChat
 */
export interface TeamChat {
    /**
     * 
     * @type {string}
     * @memberof TeamChat
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamChat
     */
    published?: boolean;
}

/**
 * Check if a given object implements the TeamChat interface.
 */
export function instanceOfTeamChat(value: object): value is TeamChat {
    return true;
}

export function TeamChatFromJSON(json: any): TeamChat {
    return TeamChatFromJSONTyped(json, false);
}

export function TeamChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamChat {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'published': json['published'] == null ? undefined : json['published'],
    };
}

export function TeamChatToJSON(value?: TeamChat | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'published': value['published'],
    };
}

