/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MailoutState = {
    Building: 'building',
    Built: 'built',
    Sending: 'sending',
    Sent: 'sent'
} as const;
export type MailoutState = typeof MailoutState[keyof typeof MailoutState];


export function instanceOfMailoutState(value: any): boolean {
    for (const key in MailoutState) {
        if (Object.prototype.hasOwnProperty.call(MailoutState, key)) {
            if ((MailoutState as Record<string, MailoutState>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MailoutStateFromJSON(json: any): MailoutState {
    return MailoutStateFromJSONTyped(json, false);
}

export function MailoutStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailoutState {
    return json as MailoutState;
}

export function MailoutStateToJSON(value?: MailoutState | null): any {
    return value as any;
}

