/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExternalKey,
  RestResult,
  Team,
} from '../models/index';
import {
    ExternalKeyFromJSON,
    ExternalKeyToJSON,
    RestResultFromJSON,
    RestResultToJSON,
    TeamFromJSON,
    TeamToJSON,
} from '../models/index';

export interface AssignExternalKeyRequest {
    externalKeyProvider?: string;
    key?: string;
    teamId?: string;
}

export interface DeleteExternalKeyRequest {
    id?: string;
    teamId?: string;
}

export interface FindByExternalKeyRequest {
    externalKeyProvider?: string;
    key?: string;
}

export interface FindExternalKeysRequest {
    externalKey?: string;
}

export interface GetByIdRequest {
    teamId: string;
}

/**
 * 
 */
export class TeamControllerApi extends runtime.BaseAPI {

    /**
     */
    async assignExternalKeyRaw(requestParameters: AssignExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['externalKeyProvider'] != null) {
            queryParameters['externalKeyProvider'] = requestParameters['externalKeyProvider'];
        }

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['teamId'] != null) {
            queryParameters['teamId'] = requestParameters['teamId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/assign-external-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async assignExternalKey(requestParameters: AssignExternalKeyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.assignExternalKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteExternalKeyRaw(requestParameters: DeleteExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['teamId'] != null) {
            queryParameters['teamId'] = requestParameters['teamId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/delete-external-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteExternalKey(requestParameters: DeleteExternalKeyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteExternalKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findByExternalKeyRaw(requestParameters: FindByExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['externalKeyProvider'] != null) {
            queryParameters['externalKeyProvider'] = requestParameters['externalKeyProvider'];
        }

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teams/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async findByExternalKey(requestParameters: FindByExternalKeyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.findByExternalKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findExternalKeysRaw(requestParameters: FindExternalKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExternalKey>>> {
        const queryParameters: any = {};

        if (requestParameters['externalKey'] != null) {
            queryParameters['externalKey'] = requestParameters['externalKey'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teams/external-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalKeyFromJSON));
    }

    /**
     */
    async findExternalKeys(requestParameters: FindExternalKeysRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExternalKey>> {
        const response = await this.findExternalKeysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling getById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async getById(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.getByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
