/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SysadminUserProfile
 */
export interface SysadminUserProfile {
    /**
     * 
     * @type {string}
     * @memberof SysadminUserProfile
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof SysadminUserProfile
     */
    infoMailsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SysadminUserProfile
     */
    newsletterSubscribed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SysadminUserProfile
     */
    pushNotificationsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof SysadminUserProfile
     */
    emailAddressToConfirm: string;
    /**
     * 
     * @type {boolean}
     * @memberof SysadminUserProfile
     */
    emailValid: boolean;
}

/**
 * Check if a given object implements the SysadminUserProfile interface.
 */
export function instanceOfSysadminUserProfile(value: object): value is SysadminUserProfile {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('infoMailsEnabled' in value) || value['infoMailsEnabled'] === undefined) return false;
    if (!('newsletterSubscribed' in value) || value['newsletterSubscribed'] === undefined) return false;
    if (!('pushNotificationsEnabled' in value) || value['pushNotificationsEnabled'] === undefined) return false;
    if (!('emailAddressToConfirm' in value) || value['emailAddressToConfirm'] === undefined) return false;
    if (!('emailValid' in value) || value['emailValid'] === undefined) return false;
    return true;
}

export function SysadminUserProfileFromJSON(json: any): SysadminUserProfile {
    return SysadminUserProfileFromJSONTyped(json, false);
}

export function SysadminUserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): SysadminUserProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'infoMailsEnabled': json['infoMailsEnabled'],
        'newsletterSubscribed': json['newsletterSubscribed'],
        'pushNotificationsEnabled': json['pushNotificationsEnabled'],
        'emailAddressToConfirm': json['emailAddressToConfirm'],
        'emailValid': json['emailValid'],
    };
}

export function SysadminUserProfileToJSON(value?: SysadminUserProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'infoMailsEnabled': value['infoMailsEnabled'],
        'newsletterSubscribed': value['newsletterSubscribed'],
        'pushNotificationsEnabled': value['pushNotificationsEnabled'],
        'emailAddressToConfirm': value['emailAddressToConfirm'],
        'emailValid': value['emailValid'],
    };
}

