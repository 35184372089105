/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterLayout
 */
export interface NewsletterLayout {
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayout
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayout
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterLayout
     */
    usageCount: number;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayout
     */
    headerText?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayout
     */
    footerText?: string;
}

/**
 * Check if a given object implements the NewsletterLayout interface.
 */
export function instanceOfNewsletterLayout(value: object): value is NewsletterLayout {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('usageCount' in value) || value['usageCount'] === undefined) return false;
    return true;
}

export function NewsletterLayoutFromJSON(json: any): NewsletterLayout {
    return NewsletterLayoutFromJSONTyped(json, false);
}

export function NewsletterLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterLayout {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'usageCount': json['usageCount'],
        'headerText': json['headerText'] == null ? undefined : json['headerText'],
        'footerText': json['footerText'] == null ? undefined : json['footerText'],
    };
}

export function NewsletterLayoutToJSON(value?: NewsletterLayout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'usageCount': value['usageCount'],
        'headerText': value['headerText'],
        'footerText': value['footerText'],
    };
}

