/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientStaticTranslation,
} from '../models/index';
import {
    ClientStaticTranslationFromJSON,
    ClientStaticTranslationToJSON,
} from '../models/index';

export interface GetTranslationByIdRequest {
    cstId: string;
}

export interface SearchTranslationRequest {
    filter?: string;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class TranslationControllerApi extends runtime.BaseAPI {

    /**
     */
    async getTranslationByIdRaw(requestParameters: GetTranslationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientStaticTranslation>> {
        if (requestParameters['cstId'] == null) {
            throw new runtime.RequiredError(
                'cstId',
                'Required parameter "cstId" was null or undefined when calling getTranslationById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/translation/{cstId}`.replace(`{${"cstId"}}`, encodeURIComponent(String(requestParameters['cstId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientStaticTranslationFromJSON(jsonValue));
    }

    /**
     */
    async getTranslationById(requestParameters: GetTranslationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientStaticTranslation> {
        const response = await this.getTranslationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchTranslationRaw(requestParameters: SearchTranslationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientStaticTranslation>>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/translation/all/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientStaticTranslationFromJSON));
    }

    /**
     */
    async searchTranslation(requestParameters: SearchTranslationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientStaticTranslation>> {
        const response = await this.searchTranslationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
