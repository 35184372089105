/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateTask,
} from '../models/index';
import {
    UpdateTaskFromJSON,
    UpdateTaskToJSON,
} from '../models/index';

export interface ExecuteTaskRequest {
    taskId: string;
}

/**
 * 
 */
export class UpdateTaskControllerApi extends runtime.BaseAPI {

    /**
     */
    async executeTaskRaw(requestParameters: ExecuteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskId'] == null) {
            throw new runtime.RequiredError(
                'taskId',
                'Required parameter "taskId" was null or undefined when calling executeTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/update-tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters['taskId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async executeTask(requestParameters: ExecuteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.executeTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUpdateTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UpdateTask>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/update-tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UpdateTaskFromJSON));
    }

    /**
     */
    async getUpdateTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UpdateTask>> {
        const response = await this.getUpdateTasksRaw(initOverrides);
        return await response.value();
    }

}
