/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tournament
 */
export interface Tournament {
    /**
     * 
     * @type {number}
     * @memberof Tournament
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    rankingStrategyName: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    tournamentCreatorName: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    season: string;
}

/**
 * Check if a given object implements the Tournament interface.
 */
export function instanceOfTournament(value: object): value is Tournament {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('shortName' in value) || value['shortName'] === undefined) return false;
    if (!('categoryId' in value) || value['categoryId'] === undefined) return false;
    if (!('rankingStrategyName' in value) || value['rankingStrategyName'] === undefined) return false;
    if (!('tournamentCreatorName' in value) || value['tournamentCreatorName'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('season' in value) || value['season'] === undefined) return false;
    return true;
}

export function TournamentFromJSON(json: any): Tournament {
    return TournamentFromJSONTyped(json, false);
}

export function TournamentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tournament {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'shortName': json['shortName'],
        'categoryId': json['categoryId'],
        'rankingStrategyName': json['rankingStrategyName'],
        'tournamentCreatorName': json['tournamentCreatorName'],
        'key': json['key'],
        'season': json['season'],
    };
}

export function TournamentToJSON(value?: Tournament | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'shortName': value['shortName'],
        'categoryId': value['categoryId'],
        'rankingStrategyName': value['rankingStrategyName'],
        'tournamentCreatorName': value['tournamentCreatorName'],
        'key': value['key'],
        'season': value['season'],
    };
}

