/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayListEntry
 */
export interface PlayListEntry {
    /**
     * 
     * @type {number}
     * @memberof PlayListEntry
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof PlayListEntry
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    remark: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    station: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songName: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songArtist: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    albumId: string;
    /**
     * 
     * @type {number}
     * @memberof PlayListEntry
     */
    albumImageId: number;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    albumName: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastTitle: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastAnchorman: string;
}

/**
 * Check if a given object implements the PlayListEntry interface.
 */
export function instanceOfPlayListEntry(value: object): value is PlayListEntry {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('remark' in value) || value['remark'] === undefined) return false;
    if (!('station' in value) || value['station'] === undefined) return false;
    if (!('songId' in value) || value['songId'] === undefined) return false;
    if (!('songName' in value) || value['songName'] === undefined) return false;
    if (!('songArtist' in value) || value['songArtist'] === undefined) return false;
    if (!('songUrl' in value) || value['songUrl'] === undefined) return false;
    if (!('albumId' in value) || value['albumId'] === undefined) return false;
    if (!('albumImageId' in value) || value['albumImageId'] === undefined) return false;
    if (!('albumName' in value) || value['albumName'] === undefined) return false;
    if (!('broadcastId' in value) || value['broadcastId'] === undefined) return false;
    if (!('broadcastTitle' in value) || value['broadcastTitle'] === undefined) return false;
    if (!('broadcastAnchorman' in value) || value['broadcastAnchorman'] === undefined) return false;
    return true;
}

export function PlayListEntryFromJSON(json: any): PlayListEntry {
    return PlayListEntryFromJSONTyped(json, false);
}

export function PlayListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayListEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'remark': json['remark'],
        'station': json['station'],
        'songId': json['songId'],
        'songName': json['songName'],
        'songArtist': json['songArtist'],
        'songUrl': json['songUrl'],
        'albumId': json['albumId'],
        'albumImageId': json['albumImageId'],
        'albumName': json['albumName'],
        'broadcastId': json['broadcastId'],
        'broadcastTitle': json['broadcastTitle'],
        'broadcastAnchorman': json['broadcastAnchorman'],
    };
}

export function PlayListEntryToJSON(value?: PlayListEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'date': ((value['date']).toISOString()),
        'remark': value['remark'],
        'station': value['station'],
        'songId': value['songId'],
        'songName': value['songName'],
        'songArtist': value['songArtist'],
        'songUrl': value['songUrl'],
        'albumId': value['albumId'],
        'albumImageId': value['albumImageId'],
        'albumName': value['albumName'],
        'broadcastId': value['broadcastId'],
        'broadcastTitle': value['broadcastTitle'],
        'broadcastAnchorman': value['broadcastAnchorman'],
    };
}

