import { AnyAction, isType } from 'typescript-fsa';
import { RoundWinners } from '../../../services/betting';
import { roundWinnersSetWinners } from './actions';

export interface RoundWinnersState {
    winners: RoundWinners[];
}

const defaultRoundWinnersState: RoundWinnersState = {
    winners: []
};

export function roundWinnersReducer(
    state: RoundWinnersState = defaultRoundWinnersState,
    action: AnyAction
): RoundWinnersState {
    if (isType(action, roundWinnersSetWinners)) {
        return { ...state, winners: action.payload };
    }
    return state;
}
