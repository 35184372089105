/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BetQuote
 */
export interface BetQuote {
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    gameId: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    bet1: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    bet2: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    tendencyCount: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    differenceCount: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    resultCount: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    tendencyPoints: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    differencePoints: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    resultPoints: number;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    tendencyFit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    differenceFit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    resultFit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    tendencyDisabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    differenceDisabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BetQuote
     */
    resultDisabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof BetQuote
     */
    betCount: number;
}

/**
 * Check if a given object implements the BetQuote interface.
 */
export function instanceOfBetQuote(value: object): value is BetQuote {
    if (!('gameId' in value) || value['gameId'] === undefined) return false;
    if (!('bet1' in value) || value['bet1'] === undefined) return false;
    if (!('bet2' in value) || value['bet2'] === undefined) return false;
    if (!('tendencyCount' in value) || value['tendencyCount'] === undefined) return false;
    if (!('differenceCount' in value) || value['differenceCount'] === undefined) return false;
    if (!('resultCount' in value) || value['resultCount'] === undefined) return false;
    if (!('tendencyPoints' in value) || value['tendencyPoints'] === undefined) return false;
    if (!('differencePoints' in value) || value['differencePoints'] === undefined) return false;
    if (!('resultPoints' in value) || value['resultPoints'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('tendencyFit' in value) || value['tendencyFit'] === undefined) return false;
    if (!('differenceFit' in value) || value['differenceFit'] === undefined) return false;
    if (!('resultFit' in value) || value['resultFit'] === undefined) return false;
    if (!('tendencyDisabled' in value) || value['tendencyDisabled'] === undefined) return false;
    if (!('differenceDisabled' in value) || value['differenceDisabled'] === undefined) return false;
    if (!('resultDisabled' in value) || value['resultDisabled'] === undefined) return false;
    if (!('betCount' in value) || value['betCount'] === undefined) return false;
    return true;
}

export function BetQuoteFromJSON(json: any): BetQuote {
    return BetQuoteFromJSONTyped(json, false);
}

export function BetQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): BetQuote {
    if (json == null) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'bet1': json['bet1'],
        'bet2': json['bet2'],
        'tendencyCount': json['tendencyCount'],
        'differenceCount': json['differenceCount'],
        'resultCount': json['resultCount'],
        'tendencyPoints': json['tendencyPoints'],
        'differencePoints': json['differencePoints'],
        'resultPoints': json['resultPoints'],
        'points': json['points'],
        'tendencyFit': json['tendencyFit'],
        'differenceFit': json['differenceFit'],
        'resultFit': json['resultFit'],
        'tendencyDisabled': json['tendencyDisabled'],
        'differenceDisabled': json['differenceDisabled'],
        'resultDisabled': json['resultDisabled'],
        'betCount': json['betCount'],
    };
}

export function BetQuoteToJSON(value?: BetQuote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameId': value['gameId'],
        'bet1': value['bet1'],
        'bet2': value['bet2'],
        'tendencyCount': value['tendencyCount'],
        'differenceCount': value['differenceCount'],
        'resultCount': value['resultCount'],
        'tendencyPoints': value['tendencyPoints'],
        'differencePoints': value['differencePoints'],
        'resultPoints': value['resultPoints'],
        'points': value['points'],
        'tendencyFit': value['tendencyFit'],
        'differenceFit': value['differenceFit'],
        'resultFit': value['resultFit'],
        'tendencyDisabled': value['tendencyDisabled'],
        'differenceDisabled': value['differenceDisabled'],
        'resultDisabled': value['resultDisabled'],
        'betCount': value['betCount'],
    };
}

