/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Team } from './Team';
import {
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';
import type { HighscoreInfo } from './HighscoreInfo';
import {
    HighscoreInfoFromJSON,
    HighscoreInfoFromJSONTyped,
    HighscoreInfoToJSON,
} from './HighscoreInfo';
import type { UserGroup } from './UserGroup';
import {
    UserGroupFromJSON,
    UserGroupFromJSONTyped,
    UserGroupToJSON,
} from './UserGroup';

/**
 * 
 * @export
 * @interface BettingUserPublicProfile
 */
export interface BettingUserPublicProfile {
    /**
     * 
     * @type {number}
     * @memberof BettingUserPublicProfile
     */
    userId: number;
    /**
     * 
     * @type {boolean}
     * @memberof BettingUserPublicProfile
     */
    visible: boolean;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof BettingUserPublicProfile
     */
    userGroups?: Array<UserGroup>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof BettingUserPublicProfile
     */
    favoriteTeams?: Array<Team>;
    /**
     * 
     * @type {Array<HighscoreInfo>}
     * @memberof BettingUserPublicProfile
     */
    highscores?: Array<HighscoreInfo>;
}

/**
 * Check if a given object implements the BettingUserPublicProfile interface.
 */
export function instanceOfBettingUserPublicProfile(value: object): value is BettingUserPublicProfile {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('visible' in value) || value['visible'] === undefined) return false;
    return true;
}

export function BettingUserPublicProfileFromJSON(json: any): BettingUserPublicProfile {
    return BettingUserPublicProfileFromJSONTyped(json, false);
}

export function BettingUserPublicProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): BettingUserPublicProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'visible': json['visible'],
        'userGroups': json['userGroups'] == null ? undefined : ((json['userGroups'] as Array<any>).map(UserGroupFromJSON)),
        'favoriteTeams': json['favoriteTeams'] == null ? undefined : ((json['favoriteTeams'] as Array<any>).map(TeamFromJSON)),
        'highscores': json['highscores'] == null ? undefined : ((json['highscores'] as Array<any>).map(HighscoreInfoFromJSON)),
    };
}

export function BettingUserPublicProfileToJSON(value?: BettingUserPublicProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'visible': value['visible'],
        'userGroups': value['userGroups'] == null ? undefined : ((value['userGroups'] as Array<any>).map(UserGroupToJSON)),
        'favoriteTeams': value['favoriteTeams'] == null ? undefined : ((value['favoriteTeams'] as Array<any>).map(TeamToJSON)),
        'highscores': value['highscores'] == null ? undefined : ((value['highscores'] as Array<any>).map(HighscoreInfoToJSON)),
    };
}

