import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Collapse, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { myProfilePath } from '../../../screens/UserProfileScreen';
import { accountUserSettingsProfileSettingsSelector } from '../../AccountUserApp/AccountUserSettings/selectors';
import { showInvalidEmailTeaser } from '../../AccountUserApp/InvalidEmailTeaser';
import { bettingPath } from '../../Betting';
import { myGroupHighscorePath } from '../../BettingApp/MyGroupHighscores';
import { gamesListPath } from '../../GamesList';
import { highscoresPath } from '../../Highscores';
import { rankingPath } from '../../RankingComponent';
import { logout } from '../../System/actions';
import {
    isClientAdminSelector,
    isLoggedInSelector,
    isSysAdminSelector,
    userInfoSelector
} from '../../System/selectors';
import { getStaticFilesBaseUrl, getSystemConfig, isFuntip, isRadioEins } from '../../System/systemConfig';
import { userGroupHighscorePath, userGroupListPath } from '../../UserGroupHighscores';
import { userListPath } from '../../UserList';
import { liveTickerConferencePath } from '../../BettingApp/LiveTickerConference';
import { clientAdminIndexPath } from '../../ClientAdminApp';

export const R1MobileMenu: FC<{}> = () => {
    const userInfo = useSelector(userInfoSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);
    const isClientAdmin = useSelector(isClientAdminSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    return (
        <>
            <div
                className="d-flex flex-row align-items-center justify-content-between ps-2 pe-2 fixed-top"
                style={{ backgroundColor: '#ebebe9' }}
            >
                <div className="my-3 ms-3 me-auto ">
                    {isRadioEins() && (
                        <img
                            src={getStaticFilesBaseUrl() + '/r1/radioeins.png'}
                            alt="radioeins Logo"
                            title="radioeins Logo"
                        />
                    )}
                    {isFuntip() && (
                        <>
                            <img
                                src={getStaticFilesBaseUrl() + '/static-img/ft/logo.png'}
                                alt="Funtip Tippspiel Logo"
                                width={30}
                                className="img-fluid"
                            />
                            <span className="ms-2">Funtip Tippspiel</span>
                        </>
                    )}
                </div>
                <div className="me-3">
                    <iframe
                        id="ftLoginFrame_New"
                        src={getSystemConfig().iframeApiUrl}
                        title="login"
                        height="45"
                        width="260"
                        className="me-2 align-middle d-md-inline-block d-none "
                        style={{ border: 'none' }}
                        sandbox="allow-scripts allow-top-navigation allow-same-origin"
                    ></iframe>

                    <Button outline={true} color="warning" onClick={() => setOpen(!open)} className="align-middle">
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </div>
            </div>
            <Collapse isOpen={open} navbar={true}>
                <div className="d-flex flex-row bg-white border mx-0 fixed-top" style={{ marginTop: '68px' }}>
                    <div className="d-inline-flex flex-column">
                        <Navbar light={true}>
                            <Nav navbar={true}>
                                <NavItem>
                                    <NavLink to={bettingPath('')} tag={Link} onClick={() => setOpen(!open)}>
                                        <b>Tippen</b>
                                    </NavLink>
                                </NavItem>
                                {isLoggedIn && (
                                    <NavItem>
                                        <NavLink to={myGroupHighscorePath()} tag={Link} onClick={() => setOpen(!open)}>
                                            Meine Wertung
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem>
                                    <NavLink to={highscoresPath('')} tag={Link} onClick={() => setOpen(!open)}>
                                        Einzelwertung
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={userGroupHighscorePath('')} tag={Link} onClick={() => setOpen(!open)}>
                                        Teamwertung
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={rankingPath('')} tag={Link} onClick={() => setOpen(!open)}>
                                        Tabellen
                                    </NavLink>
                                </NavItem>
                                {isLoggedIn && (
                                    <NavItem>
                                        <NavLink to={myProfilePath()} tag={Link} onClick={() => setOpen(!open)}>
                                            Profil {userInfo && userInfo.name && `'${userInfo.name}'`}
                                            {profileSettings && showInvalidEmailTeaser(profileSettings) && (
                                                <Badge className="ms-1" color="warning">
                                                    !
                                                </Badge>
                                            )}
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </Navbar>
                    </div>
                    <div className="d-inline-flex flex-column">
                        <Navbar light={true}>
                            <Nav navbar={true}>
                                <NavItem>
                                    <NavLink
                                        to={liveTickerConferencePath()}
                                        tag={Link}
                                        onClick={() => setOpen(!open)}
                                    >
                                        <b>Live-Ticker</b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to={gamesListPath(moment().format('YYYYMMDD'), 'all')}
                                        tag={Link}
                                        onClick={() => setOpen(!open)}
                                    >
                                        Heute
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={userListPath()} tag={Link} onClick={() => setOpen(!open)}>
                                        Tipper
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={userGroupListPath()} tag={Link} onClick={() => setOpen(!open)}>
                                        Tippteams
                                    </NavLink>
                                </NavItem>
                                {isClientAdmin && (
                                    <NavItem>
                                        <NavLink tag={Link} to={clientAdminIndexPath()} onClick={() => setOpen(!open)}>
                                            Client-Admin
                                        </NavLink>
                                    </NavItem>
                                )}
                                {isSysAdmin && (
                                    <NavItem>
                                        <NavLink tag={Link} to="/sys/" onClick={() => setOpen(!open)}>
                                            Sys-Admin
                                        </NavLink>
                                    </NavItem>
                                )}
                                {isLoggedIn && (
                                    <NavItem>
                                        <NavLink tag={Link} to="/" onClick={() => dispatch(logout())}>
                                            Abmelden
                                        </NavLink>
                                    </NavItem>
                                )}
                                {!isLoggedIn && (
                                    <NavItem>
                                        <NavLink tag={Link} to="/" onClick={() => {}}>
                                            Anmelden
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </Navbar>
                    </div>
                </div>
            </Collapse>
        </>
    );
};
