import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import { UserGroupAdminLink } from '../../../links/UserGroupAdminLink';
import { GroupMember } from '../../../services/betting';
import {
    systemSetAuthenticatedAccessToken,
    systemSetLoginNotRequired,
    systemSetPopupMessage,
} from '../../System/actions';
import { isClientAdminSelector, isSysAdminSelector } from '../../System/selectors';
import {
    getLogin2Api,
    getSysadminBettingProfileApi,
    getSysAdminProfile2Api,
    getSystemConfig,
} from '../../System/systemConfig';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { Section, SectionHeading, SysAdminSection } from '../../common/Section';
import { userAdminLoad, userAdminMergeBets, userAdminSetMergeBetsCount, userAdminToggleR1Moderator } from './actions';
import { userAdminMergeBetsCountSelector, userAdminProfileSelector } from './selectors';
import { push } from '@lagunovsky/redux-react-router';

interface Props {
    userId: string;
    onClose: () => void;
}

export const UserAdmin: FC<Props> = (props) => {
    const isSysAdmin = useSelector(isSysAdminSelector);

    const bettingProfileApi = getSysadminBettingProfileApi();

    const userProfileQuery = useQuery({
        queryKey: ['userProfile', props.userId],
        queryFn: () => getSysAdminProfile2Api().getUserProfile({ userId: props.userId }),
    });

    const userGroupQuery = useQuery({
        queryKey: ['userGroup', props.userId],
        queryFn: () => bettingProfileApi.getUserGroups({ userId: props.userId }),
    });

    const userSysadminProfileQuery = useQuery({
        queryKey: ['userSysadminProfile', props.userId],
        queryFn: () => getSysAdminProfile2Api().getUserSysadminProfile({ userId: props.userId }),
    });

    const [mergeBetsSrcUser, setMergeBetsSrcUser] = useState('');

    const [newPassoword, setNewPassword] = useState('');
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState<string>('');

    const dispatch = useDispatch();

    const profile = useSelector(userAdminProfileSelector);
    const mergeBetsCount = useSelector(userAdminMergeBetsCountSelector);

    useEffect(() => {
        if (isSysAdmin) {
            dispatch(userAdminLoad(props.userId));
            dispatch(userAdminSetMergeBetsCount(''));
        }
    }, [dispatch, props.userId, isSysAdmin]);

    const toggleInfoMailMutation = useMutation({
        mutationFn: (param: { userId: string }) =>
            getSysAdminProfile2Api().toggleInfoMailsSysAdmin({ userId: param.userId }),
        onSuccess(data, variables, context) {
            userSysadminProfileQuery.refetch();
            dispatch(systemSetPopupMessage('InfoMails erfolgreich geändert'));
        },
        onError(error, variables, context) {
            dispatch(systemSetPopupMessage('Fehler beim Ändern der InfoMails'));
        },
    });

    const changePasswordMutation = useMutation({
        mutationFn: (param: { userId: string; newPassword: string }) =>
            getSysAdminProfile2Api().changePassoword({ userId: param.userId, pwd: param.newPassword }),
        onSuccess(data, variables, context) {
            setNewPassword('');
            setNewPasswordErrorMsg('');
            dispatch(systemSetPopupMessage('Kennwort erfolgreich geändert'));
        },
        onError(error, variables, context) {
            setNewPasswordErrorMsg('Ungültiges Kennwort');
            dispatch(systemSetPopupMessage('Ungültiges Kennwort'));
        },
    });

    const loginAsUserMutation = useMutation({
        mutationFn: (userId: string) => getLogin2Api().loginAsUser({ tenant: getSystemConfig().clientName, userId }),
    });

    if (!isSysAdmin) {
        return null;
    }

    const onToggleInfoMails = () => {
        if (profile) {
            toggleInfoMailMutation.mutate({ userId: profile.id });
        }
    };

    const onChangePassword = () => {
        if (profile) {
            changePasswordMutation.mutate({ userId: profile.id, newPassword: newPassoword });
        }
    };

    const renderGroupMember = (gm: GroupMember) => {
        return (
            <tr key={gm.groupMemberId}>
                <td>
                    <UserGroupAdminLink ugId={gm.userGroupId}>{gm.userGroup.name}</UserGroupAdminLink>
                </td>
            </tr>
        );
    };

    const onLoginAsUser = () => {
        loginAsUserMutation.mutate(props.userId, {
            onSuccess(data, variables, context) {
                dispatch(systemSetAuthenticatedAccessToken(data.token!));
                dispatch(systemSetLoginNotRequired());
                props.onClose();
                dispatch(push('/b/portal'));
            },
            onError(error, variables, context) {
                dispatch(systemSetPopupMessage('Fehler beim Login'));
            },
        });
    };

    return (
        <>
            <Modal isOpen={true} toggle={props.onClose} size="xl">
                <ModalHeader>
                    <span className="text-danger">User-Administration</span>
                </ModalHeader>
                <ModalBody>
                    {isSysAdmin && profile && (
                        <SysAdminSection subtitle={`User '${profile.name}' [${profile.id}]`}>
                            <Row>
                                <Col>
                                    <Section>
                                        <SectionHeading>Userdaten</SectionHeading>
                                        <Form>
                                            <FormGroup>
                                                <Label>Username</Label>
                                                <Input
                                                    type="text"
                                                    value={userProfileQuery.data?.name}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Status</Label>
                                                <Input
                                                    type="text"
                                                    value={userProfileQuery.data?.status}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Email-Adresse</Label>
                                                <Input
                                                    type="text"
                                                    value={userSysadminProfileQuery.data?.email}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>
                                                    <Input
                                                        type="checkbox"
                                                        checked={userSysadminProfileQuery.data?.emailValid}
                                                        readOnly={true}
                                                        disabled={true}
                                                        className="me-2"
                                                    />
                                                    Email gültig
                                                </Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>noch zu bestätigende Email-Adresse</Label>
                                                <Input
                                                    type="text"
                                                    value={userSysadminProfileQuery.data?.emailAddressToConfirm}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Label>
                                                        <Input
                                                            type="checkbox"
                                                            checked={userSysadminProfileQuery.data?.infoMailsEnabled}
                                                            readOnly={true}
                                                            disabled={true}
                                                            className="me-2"
                                                        />
                                                        InfoMails?
                                                    </Label>
                                                    <div>
                                                        <ButtonWithConfirmation
                                                            title="InfoMails"
                                                            message={`Sind Sie sicher, dass Sie die InfoMails für User '${profile.name}' ändern wollen?`}
                                                            className="ms-4"
                                                            onOK={onToggleInfoMails}
                                                            color="danger"
                                                        >
                                                            Ändern
                                                        </ButtonWithConfirmation>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>
                                                    <Input
                                                        type="checkbox"
                                                        checked={userSysadminProfileQuery.data?.newsletterSubscribed}
                                                        readOnly={true}
                                                        disabled={true}
                                                        className="me-2"
                                                    />
                                                    Newsletter-Subscriber?
                                                </Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>
                                                    <Input
                                                        type="checkbox"
                                                        checked={
                                                            userSysadminProfileQuery.data?.pushNotificationsEnabled
                                                        }
                                                        readOnly={true}
                                                        disabled={true}
                                                        className="me-2"
                                                    />
                                                    Push-Nachrichten Subscriber?
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                    </Section>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Section>
                                        <SectionHeading>Tippteams</SectionHeading>
                                        <Table>
                                            <thead></thead>
                                            <tbody>{userGroupQuery.data?.map((ug) => renderGroupMember(ug))}</tbody>
                                        </Table>
                                    </Section>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Section>
                                        <SectionHeading>Kennwort ändern</SectionHeading>
                                        <Form>
                                            <FormGroup inline={true}>
                                                <Label className="me-3">Kennwort ändern</Label>
                                                <Input
                                                    name="newPasswordInput"
                                                    placeholder="neues Kennwort"
                                                    value={newPassoword}
                                                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                                                />
                                                <FormText>{newPasswordErrorMsg}</FormText>
                                            </FormGroup>
                                            <ButtonWithConfirmation
                                                title="Kennwort ändern"
                                                message={`Sind Sie sicher, dass Sie das Kennwort von Nutzer '${profile.name}' ändern wollen?`}
                                                className="ms-4"
                                                onOK={onChangePassword}
                                                color="danger"
                                            >
                                                Kennwort ändern
                                            </ButtonWithConfirmation>
                                        </Form>
                                    </Section>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Section>
                                        <SectionHeading>RadioEins-Moderator-Rolle</SectionHeading>
                                        <Form>
                                            <FormGroup check={true}>
                                                <Label check={true} className="me-3">
                                                    RadioEins Moderator
                                                </Label>
                                                <Input type="checkbox" name="infoMails" checked={profile.r1Moderator} />
                                            </FormGroup>
                                            <ButtonWithConfirmation
                                                message={`Sind Sie sicher, dass sie das Moderator-Flag von User '${profile.name}' ändern wollen?`}
                                                title="RadioEins Moderator"
                                                className="ms-4"
                                                onOK={() => dispatch(userAdminToggleR1Moderator(profile.id))}
                                                color="danger"
                                            >
                                                Toggle
                                            </ButtonWithConfirmation>
                                        </Form>
                                    </Section>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Section>
                                        <SectionHeading>Tipps von User hinzufügen</SectionHeading>
                                        <Form>
                                            <FormGroup inline={true}>
                                                <Label className="me-3">Tipps von User hinzufügen</Label>
                                                <Input
                                                    name="mergeBetsSrcUser"
                                                    placeholder="Username"
                                                    value={mergeBetsSrcUser}
                                                    onChange={(e) => setMergeBetsSrcUser(e.currentTarget.value)}
                                                />
                                                {mergeBetsCount && (
                                                    <FormText className="mx-2" inline={true}>
                                                        {mergeBetsCount} hinzugefügt
                                                    </FormText>
                                                )}
                                            </FormGroup>
                                            <ButtonWithConfirmation
                                                title="Tipps hinzufügen"
                                                message={`Sind Sie sicher, dass Sie die Tipps von '${mergeBetsSrcUser}' zum User '${profile.name}' kopieren wollen?`}
                                                className="ms-4"
                                                color="danger"
                                                onOK={() =>
                                                    dispatch(
                                                        userAdminMergeBets({
                                                            destUserId: profile.id,
                                                            srcUserName: mergeBetsSrcUser,
                                                        }),
                                                    )
                                                }
                                            >
                                                Tipps hinzufügen
                                            </ButtonWithConfirmation>
                                        </Form>
                                    </Section>
                                </Col>
                            </Row>
                        </SysAdminSection>
                    )}
                </ModalBody>
                <ModalFooter>
                    {isSysAdmin && (
                        <ButtonWithConfirmation
                            title="Anmeldung als Nutzer"
                            message={`Sind Sie sicher, dass Sie sich als Nutzer ${profile?.name} anmelden wollen?`}
                            onOK={onLoginAsUser}
                            color="danger"
                        >Anmelden als {profile?.name}</ButtonWithConfirmation>
                    )}
                    <Button color="success" onClick={props.onClose}>
                        Schließen
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

interface UserAdminButtonProps {
    userId: string;
}

export const UserAdminButton: FC<UserAdminButtonProps> = (props) => {
    const [showSysAdminDialog, setShowSysAdminDialog] = React.useState(false);

    const isSysAdmin = useSelector(isSysAdminSelector);
    const isClientAdmin = useSelector(isClientAdminSelector);

    if (!isSysAdmin && !isClientAdmin) {
        return null;
    }
    return (
        <>
            <Button color="link" onClick={() => setShowSysAdminDialog(true)}>
                <FontAwesomeIcon icon={faUnlock} className="text-danger" />
            </Button>
            {showSysAdminDialog && <UserAdmin userId={props.userId} onClose={() => setShowSysAdminDialog(false)} />}
        </>
    );
};
