/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserId } from './UserId';
import {
    UserIdFromJSON,
    UserIdFromJSONTyped,
    UserIdToJSON,
} from './UserId';

/**
 * 
 * @export
 * @interface RegistrationResultRestModel
 */
export interface RegistrationResultRestModel {
    /**
     * 
     * @type {UserId}
     * @memberof RegistrationResultRestModel
     */
    userId?: UserId;
    /**
     * 
     * @type {string}
     * @memberof RegistrationResultRestModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationResultRestModel
     */
    registrationResult?: string;
}

/**
 * Check if a given object implements the RegistrationResultRestModel interface.
 */
export function instanceOfRegistrationResultRestModel(value: object): value is RegistrationResultRestModel {
    return true;
}

export function RegistrationResultRestModelFromJSON(json: any): RegistrationResultRestModel {
    return RegistrationResultRestModelFromJSONTyped(json, false);
}

export function RegistrationResultRestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationResultRestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : UserIdFromJSON(json['userId']),
        'accessToken': json['accessToken'] == null ? undefined : json['accessToken'],
        'registrationResult': json['registrationResult'] == null ? undefined : json['registrationResult'],
    };
}

export function RegistrationResultRestModelToJSON(value?: RegistrationResultRestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': UserIdToJSON(value['userId']),
        'accessToken': value['accessToken'],
        'registrationResult': value['registrationResult'],
    };
}

