/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailUpdateResult = {
    Success: 'success',
    InvalidUser: 'invalidUser',
    InvalidEmail: 'invalidEmail'
} as const;
export type EmailUpdateResult = typeof EmailUpdateResult[keyof typeof EmailUpdateResult];


export function instanceOfEmailUpdateResult(value: any): boolean {
    for (const key in EmailUpdateResult) {
        if (Object.prototype.hasOwnProperty.call(EmailUpdateResult, key)) {
            if ((EmailUpdateResult as Record<string, EmailUpdateResult>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmailUpdateResultFromJSON(json: any): EmailUpdateResult {
    return EmailUpdateResultFromJSONTyped(json, false);
}

export function EmailUpdateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailUpdateResult {
    return json as EmailUpdateResult;
}

export function EmailUpdateResultToJSON(value?: EmailUpdateResult | null): any {
    return value as any;
}

