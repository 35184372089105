/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestResult
 */
export interface RestResult {
    /**
     * 
     * @type {number}
     * @memberof RestResult
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RestResult
     */
    message?: string;
}

/**
 * Check if a given object implements the RestResult interface.
 */
export function instanceOfRestResult(value: object): value is RestResult {
    return true;
}

export function RestResultFromJSON(json: any): RestResult {
    return RestResultFromJSONTyped(json, false);
}

export function RestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestResult {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function RestResultToJSON(value?: RestResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statusCode': value['statusCode'],
        'message': value['message'],
    };
}

