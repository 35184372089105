/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Team } from './Team';
import {
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';

/**
 * 
 * @export
 * @interface GameBet
 */
export interface GameBet {
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    gameId: number;
    /**
     * 
     * @type {Date}
     * @memberof GameBet
     */
    date: Date;
    /**
     * 
     * @type {Team}
     * @memberof GameBet
     */
    team1: Team;
    /**
     * 
     * @type {Team}
     * @memberof GameBet
     */
    team2: Team;
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    team1Result?: number;
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    team2Result?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GameBet
     */
    betAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GameBet
     */
    finished: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GameBet
     */
    hasResult: boolean;
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    bet1?: number;
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    bet2?: number;
    /**
     * 
     * @type {number}
     * @memberof GameBet
     */
    points?: number;
}

/**
 * Check if a given object implements the GameBet interface.
 */
export function instanceOfGameBet(value: object): value is GameBet {
    if (!('gameId' in value) || value['gameId'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('team1' in value) || value['team1'] === undefined) return false;
    if (!('team2' in value) || value['team2'] === undefined) return false;
    if (!('betAllowed' in value) || value['betAllowed'] === undefined) return false;
    if (!('finished' in value) || value['finished'] === undefined) return false;
    if (!('hasResult' in value) || value['hasResult'] === undefined) return false;
    return true;
}

export function GameBetFromJSON(json: any): GameBet {
    return GameBetFromJSONTyped(json, false);
}

export function GameBetFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameBet {
    if (json == null) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'date': (new Date(json['date'])),
        'team1': TeamFromJSON(json['team1']),
        'team2': TeamFromJSON(json['team2']),
        'team1Result': json['team1Result'] == null ? undefined : json['team1Result'],
        'team2Result': json['team2Result'] == null ? undefined : json['team2Result'],
        'betAllowed': json['betAllowed'],
        'finished': json['finished'],
        'hasResult': json['hasResult'],
        'bet1': json['bet1'] == null ? undefined : json['bet1'],
        'bet2': json['bet2'] == null ? undefined : json['bet2'],
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function GameBetToJSON(value?: GameBet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameId': value['gameId'],
        'date': ((value['date']).toISOString()),
        'team1': TeamToJSON(value['team1']),
        'team2': TeamToJSON(value['team2']),
        'team1Result': value['team1Result'],
        'team2Result': value['team2Result'],
        'betAllowed': value['betAllowed'],
        'finished': value['finished'],
        'hasResult': value['hasResult'],
        'bet1': value['bet1'],
        'bet2': value['bet2'],
        'points': value['points'],
    };
}

