import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import UserLink from '../../../links/UserLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { RoundWinners } from '../../../services/betting';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { TournamentSelectionComponent } from '../../TournamentSelectionComponent';
import { roundWinnersLoad } from './actions';
import { roundWinnersEntitySelector } from './selectors';

const RoundWinnersComp: FC<{}> = () => {
    const winners = useSelector(roundWinnersEntitySelector);

    const [tId] = usePathSegment(3);
    const dispatch = useDispatch();

    useEffect(() => {
        if (tId) {
            dispatch(roundWinnersLoad({ tId }));
        }
    }, [dispatch, tId]);

    const renderRow = (w: RoundWinners) => {
        return (
            <React.Fragment key={w.trId}>
                <tr>
                    <td colSpan={2}>
                        <b>{w.name}</b>
                    </td>
                </tr>
                {w.winners.map((w) => (
                    <tr key={w.userId}>
                        <td>
                            <UserLink id={String(w.userId)} name={w.userName} />
                        </td>
                        <td>{w.email}</td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };

    return (
        <>
            <Table>
                <tbody>{winners && winners.map((w) => renderRow(w))}</tbody>
            </Table>
        </>
    );
};

const title: JSX.Element = (
    <>
        <span>Spieltags-Gewinner</span>
        <span className="flex-nowrap align-items-center me-auto">
            <TournamentSelectionComponent onTournamentChange={(tId) => push(roundWinnerPath(tId))} />
        </span>
    </>
);

export const roundWinnerPath = (tId?: string | number) => `/b/round-winners/${tId ? tId : ''}`;

export interface RoundWinnerParams {
    tId: string;
}
export const RoundWinnerScreen = R.compose(
    withBorder(),
    withTitle(title),
    withNavLocation('betting')
)(RoundWinnersComp);
