import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { NavLocation } from '../../App';
import { AgbInfo, FuntipPushSubscription, UserProfile } from '../../services/account';
import {
    addSystemErrorMessage,
    deleteSystemErrorMessages,
    setSystemAccessToken,
    setSystemUserInfo,
    setUserRoles,
    systemFinishFetching,
    systemLoggedOut,
    systemSetAgbs,
    systemSetDeploymentProperties,
    systemSetLoginNotRequired,
    systemSetLoginRequired,
    systemSetNavLocation,
    systemSetPopupMessage,
    systemSetPushSubscription,
    systemSetPushSubscriptionPublicKey,
    systemSetServerAvailibility,
    systemShowAutomaticLogoutMessage,
    systemShowErrors,
    systemShowLogoutConfirmation,
    systemStartFetching,
} from './actions';
import { DeploymentProperties, ServerType } from './systemConfig';

export type SystemError = {
    date: Date;
    error: any;
};

export interface SystemStore {
    errors: SystemError[];
    navLocation: NavLocation;
    showErrors: boolean;
    isFetching: number;
    accessToken: string;
    userInfo: UserProfile;
    userRoles: string[];
    agbInfos: AgbInfo;
    serverAvailability: { [server in ServerType]: boolean };
    deploymentProperties?: DeploymentProperties;
    popupMessage?: string;
    showLogoutConfirmation: boolean;
    showAutomaticLogoutMessage: boolean;
    loginRequired: boolean;
    loginNextUrl: string;
    pushSubscription?: FuntipPushSubscription;
    pushSubscriptionPublicKey?: string;
}

const initialUserInfo: UserProfile = {
    id: '',
    bettingPublic: false,
    name: '',
    playlistPublic: false,
    character: '',
    characterHtml: '',
    favoriteBook: '',
    favoriteBookHtml: '',
    favoriteMovie: '',
    favoriteMovieHtml: '',
    note: '',
    status: 'inactive',
    r1Moderator: false,
};

const initialSystemStore: SystemStore = {
    errors: [],
    navLocation: 'betting',
    showErrors: false,
    isFetching: 0,
    accessToken: '',
    userInfo: initialUserInfo,
    userRoles: [],
    agbInfos: {
        privacyStatement: '',
        privacyStatementUrl: '',
        confirmedPrivacyStatementVersion: '',
        privacyStatementVersion: '',
        imprint: '',
    },
    serverAvailability: {
        account: true,
        playlist: true,
        betting: true,
        image: true,
        webapp: true
    },
    showLogoutConfirmation: false,
    showAutomaticLogoutMessage: false,
    loginRequired: false,
    loginNextUrl: '',
};

export function systemReducer(state: SystemStore = initialSystemStore, action: Action): SystemStore {
    if (isType(action, addSystemErrorMessage)) {
        const errorList = state.errors;
        errorList.push({ date: new Date(), error: action.payload });
        return { ...state, errors: errorList };
    } else if (isType(action, systemStartFetching)) {
        return Object.assign({}, state, { isFetching: state.isFetching + 1 });
    } else if (isType(action, systemFinishFetching)) {
        return Object.assign({}, state, { isFetching: state.isFetching - 1 });
    } else if (isType(action, setSystemAccessToken)) {
        return Object.assign({}, state, { accessToken: action.payload });
    } else if (isType(action, setSystemUserInfo)) {
        return Object.assign({}, state, { userInfo: action.payload });
    } else if (isType(action, setUserRoles)) {
        return Object.assign({}, state, { userRoles: action.payload });
    } else if (isType(action, systemLoggedOut)) {
        return { ...state, accessToken: '', userInfo: initialUserInfo, userRoles: [] };
    } else if (isType(action, deleteSystemErrorMessages)) {
        return { ...state, errors: [] };
    } else if (isType(action, systemShowErrors)) {
        return { ...state, showErrors: action.payload };
    } else if (isType(action, systemSetAgbs)) {
        return { ...state, agbInfos: action.payload };
    } else if (isType(action, systemSetDeploymentProperties)) {
        return { ...state, deploymentProperties: action.payload };
    } else if (isType(action, systemSetServerAvailibility)) {
        const avail = { ...state.serverAvailability, [action.payload.server]: action.payload.available };
        return { ...state, serverAvailability: avail };
    } else if (isType(action, systemSetNavLocation)) {
        return { ...state, navLocation: action.payload };
    } else if (isType(action, systemSetPopupMessage)) {
        return { ...state, popupMessage: action.payload };
    } else if (isType(action, systemShowLogoutConfirmation)) {
        return { ...state, showLogoutConfirmation: action.payload };
    } else if (isType(action, systemShowAutomaticLogoutMessage)) {
        return { ...state, showAutomaticLogoutMessage: action.payload };
    } else if (isType(action, systemSetLoginRequired)) {
        return { ...state, loginRequired: true, loginNextUrl: action.payload.nextUrl };
    } else if (isType(action, systemSetLoginNotRequired)) {
        return { ...state, loginRequired: false };
    } else if (isType(action, systemSetPushSubscription)) {
        return { ...state, pushSubscription: action.payload };
    } else if (isType(action, systemSetPushSubscriptionPublicKey)) {
        return { ...state, pushSubscriptionPublicKey: action.payload };
    }

    return state;
}
