import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import React, { FC, useEffect } from 'react';
import { Form, FormGroup, Input, Table } from 'reactstrap';
import UserLink from '../../../links/UserLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { UserProfile } from '../../../services/account';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { getSysAdminProfile2Api, getSystemConfig } from '../../System/systemConfig';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { UserAdminButton } from '../UserAdmin';
import { UserEmail } from '../UserAdmin/UserEmail';

const UserAdminSearch: FC<{}> = () => {
    const [name, setName] = React.useState<string>('');
    const [users, setUsers] = React.useState<UserProfile[]>([]);

    const api = getSysAdminProfile2Api();
    const userQuery = useQuery({
        queryKey: ['usersearch', name],
        queryFn: () => api.search({ clientName: getSystemConfig().clientName, name }),
        enabled: name.length > 2,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    useEffect(() => {
        if (userQuery.data) {
            setUsers(userQuery.data);
        } else {
            setUsers([]);
        }
    }, [userQuery.data]);

    return (
        <>
            <Section>
                <SectionHeading>User Search</SectionHeading>
                <Form>
                    <FormGroup>
                        <label>Username or Email-Fragment</label>
                        <Input type="text" onChange={(e) => setName(e.currentTarget.value)} value={name} />
                    </FormGroup>
                </Form>
                <LoadingIndicatorContent isLoading={userQuery.isFetching}>
                    <Table striped={true}>
                        <tbody>
                            {users.map((u) => (
                                <tr key={u.id}>
                                    <td>
                                        <UserLink id={u.id}>{u.name}</UserLink>
                                    </td>
                                    <td>{u.status}</td>
                                    <td>
                                        <UserEmail userId={u.id} />
                                    </td>
                                    <td>
                                        <UserAdminButton userId={u.id} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </LoadingIndicatorContent>
            </Section>
        </>
    );
};

export const UserAdminApp = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withTitle('Users'),
    withLogin(),
)(UserAdminSearch);
