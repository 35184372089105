/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientTournamentStats
 */
export interface ClientTournamentStats {
    /**
     * 
     * @type {number}
     * @memberof ClientTournamentStats
     */
    subscribedUsers: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTournamentStats
     */
    participatingUserGroups: number;
}

/**
 * Check if a given object implements the ClientTournamentStats interface.
 */
export function instanceOfClientTournamentStats(value: object): value is ClientTournamentStats {
    if (!('subscribedUsers' in value) || value['subscribedUsers'] === undefined) return false;
    if (!('participatingUserGroups' in value) || value['participatingUserGroups'] === undefined) return false;
    return true;
}

export function ClientTournamentStatsFromJSON(json: any): ClientTournamentStats {
    return ClientTournamentStatsFromJSONTyped(json, false);
}

export function ClientTournamentStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTournamentStats {
    if (json == null) {
        return json;
    }
    return {
        
        'subscribedUsers': json['subscribedUsers'],
        'participatingUserGroups': json['participatingUserGroups'],
    };
}

export function ClientTournamentStatsToJSON(value?: ClientTournamentStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'subscribedUsers': value['subscribedUsers'],
        'participatingUserGroups': value['participatingUserGroups'],
    };
}

