/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterReceiverFilter
 */
export interface NewsletterReceiverFilter {
    /**
     * 
     * @type {string}
     * @memberof NewsletterReceiverFilter
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterReceiverFilter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterReceiverFilter
     */
    nameFilter: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterReceiverFilter
     */
    emailFilter: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterReceiverFilter
     */
    receiverCount: number;
    /**
     * 
     * @type {number}
     * @memberof NewsletterReceiverFilter
     */
    usageCount: number;
}

/**
 * Check if a given object implements the NewsletterReceiverFilter interface.
 */
export function instanceOfNewsletterReceiverFilter(value: object): value is NewsletterReceiverFilter {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('nameFilter' in value) || value['nameFilter'] === undefined) return false;
    if (!('emailFilter' in value) || value['emailFilter'] === undefined) return false;
    if (!('receiverCount' in value) || value['receiverCount'] === undefined) return false;
    if (!('usageCount' in value) || value['usageCount'] === undefined) return false;
    return true;
}

export function NewsletterReceiverFilterFromJSON(json: any): NewsletterReceiverFilter {
    return NewsletterReceiverFilterFromJSONTyped(json, false);
}

export function NewsletterReceiverFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterReceiverFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'nameFilter': json['nameFilter'],
        'emailFilter': json['emailFilter'],
        'receiverCount': json['receiverCount'],
        'usageCount': json['usageCount'],
    };
}

export function NewsletterReceiverFilterToJSON(value?: NewsletterReceiverFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'nameFilter': value['nameFilter'],
        'emailFilter': value['emailFilter'],
        'receiverCount': value['receiverCount'],
        'usageCount': value['usageCount'],
    };
}

