/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PushSubscriptionKeys } from './PushSubscriptionKeys';
import {
    PushSubscriptionKeysFromJSON,
    PushSubscriptionKeysFromJSONTyped,
    PushSubscriptionKeysToJSON,
} from './PushSubscriptionKeys';

/**
 * 
 * @export
 * @interface FuntipPushSubscription
 */
export interface FuntipPushSubscription {
    /**
     * 
     * @type {string}
     * @memberof FuntipPushSubscription
     */
    endpoint: string;
    /**
     * 
     * @type {PushSubscriptionKeys}
     * @memberof FuntipPushSubscription
     */
    subscriptionKeys: PushSubscriptionKeys;
}

/**
 * Check if a given object implements the FuntipPushSubscription interface.
 */
export function instanceOfFuntipPushSubscription(value: object): value is FuntipPushSubscription {
    if (!('endpoint' in value) || value['endpoint'] === undefined) return false;
    if (!('subscriptionKeys' in value) || value['subscriptionKeys'] === undefined) return false;
    return true;
}

export function FuntipPushSubscriptionFromJSON(json: any): FuntipPushSubscription {
    return FuntipPushSubscriptionFromJSONTyped(json, false);
}

export function FuntipPushSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuntipPushSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'endpoint': json['endpoint'],
        'subscriptionKeys': PushSubscriptionKeysFromJSON(json['subscriptionKeys']),
    };
}

export function FuntipPushSubscriptionToJSON(value?: FuntipPushSubscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'endpoint': value['endpoint'],
        'subscriptionKeys': PushSubscriptionKeysToJSON(value['subscriptionKeys']),
    };
}

