import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row, Table } from 'reactstrap';
import { GlobalState } from '../../components/actions';
import { ButtonWithConfirmation } from '../../components/common/ButtonWithConfirmation';
import { LoadingIndicatorSpinner } from '../../components/common/LoadingIndicatorSpinner';
import { Section, SectionHeading, SysAdminSection } from '../../components/common/Section';
import { DateTime } from '../../components/DateTime';
import { usePathSegment } from '../../components/SysAdminApp/CacheStatisticsComponent';
import { isSysAdminSelector, userIdSelector } from '../../components/System/selectors';
import { userGroupTabLinks } from '../../components/TeamChat';
import { UserGroupMemberAdminList } from '../../components/UserGroupMemberAdminList';
import { UserGroupMemberList } from '../../components/UserGroupMemberList';
import { withBorder } from '../../reactutils/withBorder';
import { TabbedContent } from '../../reactutils/withTabbedScreen';
import { withTitle } from '../../reactutils/withTitle';
import {
    acceptInvitationForUserGroup,
    acceptMembershipRequestForUserGroup,
    createMembershipRequestForUserGroup,
    grantAdminPrivilege,
    inviteUserToUserGroup,
    leaveUserGroup,
    loadUserGroupDetail,
    loadUserGroupMembers,
    refuseInvitationForUserGroup,
    refuseMembershipRequestForUserGroup,
    reinviteMemberForUserGroup,
    removeInvitationForUserGroup,
    removeMemberFromUserGroup,
    revokeAdminPrivilege,
    userGroupLoadGroupMemberChanges,
    userGroupUpdateDescription,
    withdrawMembershipRequest
} from './actions';
import {
    userGroupDetailSelector,
    userGroupGroupMemberChangesSelector,
    userGroupInvitationStateSelector,
    userGroupMemberSelector
} from './selectors';
import { UserGroupDeleteSection } from './UserGroupDeleteSection';
import { UserGroupDetailComponent } from './UserGroupDetailComponent';
import { UserGroupTournamentSubscriptions } from './UserGroupTournamentSubscriptions';

const UserGroupControllerComponent = () => {
    const [userNameOrMail, setUserNameOrMail] = React.useState('');

    const dispatch = useDispatch();

    const [ugId] = usePathSegment(3);

    React.useEffect(() => {
        if (ugId) {
            dispatch(loadUserGroupMembers(Number(ugId)));
            dispatch(loadUserGroupDetail(Number(ugId)));
        } else {
            // dispatch(push('/'));
        }
    }, [dispatch, ugId]);

    const userGroupDetail = useSelector(userGroupDetailSelector);
    const groupMembers = useSelector(userGroupMemberSelector);
    const loginUserId = useSelector(userIdSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);
    const invitationState = useSelector(userGroupInvitationStateSelector);
    const groupMemberChanges = useSelector(userGroupGroupMemberChangesSelector);

    React.useEffect(() => {
        if (isSysAdmin && userGroupDetail) {
            dispatch(userGroupLoadGroupMemberChanges(String(userGroupDetail.id)));
        }
    }, [isSysAdmin, userGroupDetail, dispatch]);

    function handleInviteUserChange(event: React.FormEvent<HTMLInputElement>) {
        setUserNameOrMail(event.currentTarget.value);
    }

    function onRemoveMember(ugId: number, userId: number) {
        dispatch(removeMemberFromUserGroup({ ugId, userId }));
    }

    function onReinviteMember(ugId: number, userId: number) {
        console.info('reinvitemember' + userId);
        dispatch(reinviteMemberForUserGroup({ ugId, userId }));
    }
    function onRemoveInvitation(ugId: number, userId: number) {
        dispatch(removeInvitationForUserGroup({ ugId, userId }));
    }
    function onAcceptMembershipRequest(ugId: number, userId: number) {
        dispatch(acceptMembershipRequestForUserGroup({ ugId, userId }));
    }
    function onRefuseMembershipRequest(ugId: number, userId: number) {
        dispatch(refuseMembershipRequestForUserGroup({ ugId, userId }));
    }
    function onCreateMembershipRequest(ugId: number) {
        dispatch(createMembershipRequestForUserGroup({ ugId }));
    }
    function onRefuseInvitation(ugId: number) {
        dispatch(refuseInvitationForUserGroup({ ugId }));
    }
    function onAcceptInvitation(ugId: number) {
        dispatch(acceptInvitationForUserGroup({ ugId }));
    }
    function onLeaveUserGroup(ugId: number) {
        dispatch(leaveUserGroup({ ugId }));
    }

    function onWithdrawMembershipRequest(ugId: number) {
        dispatch(withdrawMembershipRequest({ ugId }));
    }

    function onShowHighscoreDescriptor(ugId: string) {
        dispatch(push(highscoreDescriptorPath(ugId)));
    }
    function onRevokeAdminPrivilege(ugId: number, userId: number) {
        dispatch(revokeAdminPrivilege({ ugId, userId }));
    }
    function onGrantAdminPrivilege(ugId: number, userId: number) {
        dispatch(grantAdminPrivilege({ ugId, userId }));
    }
    function onDescriptionSave(ugId: string, description: string) {
        dispatch(userGroupUpdateDescription({ ugId, description }));
    }

    function onInviteSelectedUser() {
        if (userGroupDetail) {
            dispatch(inviteUserToUserGroup({ ugId: userGroupDetail.id, userNameOrMail }));
            setUserNameOrMail('');
        }
    }

    function showUserGroupAdminComponents() {
        if (userGroupDetail) {
            return userGroupDetail.loginUserIsGroupAdmin || isSysAdmin;
        } else {
            return isSysAdmin;
        }
    }

    function showRequestMembershipComponent() {
        if (userGroupDetail) {
            return (
                !userGroupDetail.loginUserIsGroupAdmin &&
                !userGroupDetail.loginUserIsGroupMember &&
                !userGroupDetail.loginUserIsInvited &&
                !userGroupDetail.loginUserIsApplying
            );
        } else {
            return true;
        }
    }

    if (!userGroupDetail || !groupMembers) {
        return (
            <div className="m-4 container-fluid">
                <LoadingIndicatorSpinner />
            </div>
        );
    } else {
        return (
            <>
                <TabbedContent
                    activeTab={'Tippteam'}
                    tabNames={['Wertung', 'Tippteam', 'Chat']}
                    tabLinks={userGroupTabLinks(String(userGroupDetail.id))}
                />
                <div>
                    <div className="usergroup p-0 container-fluid">
                        <Row>
                            <Col sm={12} md={4} lg={4}>
                                <UserGroupDetailComponent
                                    userGroupDetail={userGroupDetail}
                                    onDescriptionSave={onDescriptionSave}
                                    onShowHighscoreDescriptor={onShowHighscoreDescriptor}
                                    isSysAdmin={isSysAdmin}
                                />
                                <UserGroupTournamentSubscriptions ugId={String(userGroupDetail.id)} />
                                {showUserGroupAdminComponents() && (
                                    <UserGroupDeleteSection ugId={String(userGroupDetail.id)} />
                                )}
                            </Col>
                            <Col sm={12} md={8} lg={8}>
                                {showUserGroupAdminComponents() && (
                                    <Section>
                                        <SectionHeading>Neue Tipper einladen</SectionHeading>
                                        <Form className="m-2">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    id="mailOrName"
                                                    length={32}
                                                    name="Tipper"
                                                    placeholder="Mail-Adresse oder Tipper-Name"
                                                    value={userNameOrMail}
                                                    onChange={handleInviteUserChange}
                                                    valid={invitationState.state === 'success'}
                                                    invalid={invitationState.state === 'failure'}
                                                />
                                                <FormFeedback valid={false}>{invitationState.message}</FormFeedback>
                                                <FormFeedback valid={true}>{invitationState.message}</FormFeedback>
                                            </FormGroup>
                                            <Button onClick={onInviteSelectedUser} color="success" className="m-0">
                                                Einladen
                                            </Button>
                                        </Form>
                                    </Section>
                                )}
                                {showUserGroupAdminComponents() && (
                                    <UserGroupMemberAdminList
                                        groupMembers={groupMembers}
                                        userGroupDetail={userGroupDetail}
                                        onRemoveMember={onRemoveMember}
                                        onReinviteMember={onReinviteMember}
                                        onRemoveInvitation={onRemoveInvitation}
                                        onAcceptMembershipRequest={onAcceptMembershipRequest}
                                        onRefuseMembershipRequest={onRefuseMembershipRequest}
                                        onRevokeAdminPrivilege={onRevokeAdminPrivilege}
                                        onGrantAdminPrivilege={onGrantAdminPrivilege}
                                        loginUserId={loginUserId || ''}
                                    />
                                )}
                                {!showUserGroupAdminComponents() && (
                                    <UserGroupMemberList
                                        groupMembers={groupMembers}
                                        userGroupDetail={userGroupDetail}
                                        onRefuseInvitation={onRefuseInvitation}
                                        onAcceptInvitation={onAcceptInvitation}
                                        onLeaveUserGroup={onLeaveUserGroup}
                                        onWithdrawMembershipRequest={onWithdrawMembershipRequest}
                                        loginUserId={loginUserId || ''}
                                    />
                                )}

                                {showRequestMembershipComponent() && (
                                    <div className="mt-2">
                                        <ButtonWithConfirmation
                                            title={'Mitgliedschaft beantragen'}
                                            message={'Wollen Sie eine Mitgliedschaftsanfrage beantragen?'}
                                            color={'warning'}
                                            onOK={() => onCreateMembershipRequest(userGroupDetail.id)}
                                        >
                                            Mitgliedschaft beantragen{' '}
                                            <FontAwesomeIcon
                                                icon={faUserPlus}
                                                title={'Mitgliedschaftsanfrage beantragen.'}
                                            />
                                        </ButtonWithConfirmation>
                                    </div>
                                )}
                                {isSysAdmin && (
                                    <SysAdminSection>
                                        <SectionHeading>Mitgliedshistorie</SectionHeading>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Nutzer</th>
                                                <th>Zeitpunkt</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupMemberChanges.map(gmc => (
                                                <tr key={gmc.date?.getMilliseconds()}>
                                                    <td>{gmc.userName}</td>
                                                    <td><DateTime date={gmc.date} /></td>
                                                    <td>{gmc.state}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    </SysAdminSection>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={8} lg={8} />
                        </Row>
                    </div>
                </div>
            </>
        );
    }
};

export const highscoreDescriptorPath = (ugId: string) => `/b/user-group/${ugId}/hsd`;

export const userGroupSubTitleSelector = (state: GlobalState) => {
    const ug = userGroupDetailSelector(state);
    return ug ? ug.name : undefined;
};

export const UserGroupController = R.compose(
    withBorder(),
    withTitle('Tippteam', userGroupSubTitleSelector)
)(UserGroupControllerComponent);
