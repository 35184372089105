/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushSubscriptionKeys
 */
export interface PushSubscriptionKeys {
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionKeys
     */
    p256dh: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionKeys
     */
    auth: string;
}

/**
 * Check if a given object implements the PushSubscriptionKeys interface.
 */
export function instanceOfPushSubscriptionKeys(value: object): value is PushSubscriptionKeys {
    if (!('p256dh' in value) || value['p256dh'] === undefined) return false;
    if (!('auth' in value) || value['auth'] === undefined) return false;
    return true;
}

export function PushSubscriptionKeysFromJSON(json: any): PushSubscriptionKeys {
    return PushSubscriptionKeysFromJSONTyped(json, false);
}

export function PushSubscriptionKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushSubscriptionKeys {
    if (json == null) {
        return json;
    }
    return {
        
        'p256dh': json['p256dh'],
        'auth': json['auth'],
    };
}

export function PushSubscriptionKeysToJSON(value?: PushSubscriptionKeys | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'p256dh': value['p256dh'],
        'auth': value['auth'],
    };
}

