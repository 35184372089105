import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Input, Label as ReactLabel, Table } from 'reactstrap';
import { BetCountStatistic } from '../../../../../services/betting';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { Section, SectionHeading } from '../../../../common/Section';

interface Props {
    clients?: string[];
}

export const BetCountOverview: React.FC<Props> = (props) => {
    const [betStats, setBetStats] = useState<BetCountStatistic[]>();
    const [xValues, setXValues] = useState<number[]>([]);
    const [yValues, setYValues] = useState<string[]>([]);
    const [clients, setClients] = useState<string[]>([]);
    const [tournaments, setTournaments] = useState<string[]>([]);

    const [clientFilter, setClientFilter] = useState<string[]>([]);
    const [tournamentFilter, setTournamentFilter] = useState<string[]>([]);

    const api = getTournamentSysAdminApi();

    const betStatisticsQuery = useQuery({
        queryKey: ['betStatistics'],
        queryFn: () => api.getBetStatistics(),
        retry: false,
    });

    useEffect(() => {
        if (betStatisticsQuery.data) {
            setBetStats(betStatisticsQuery.data);

            const clList = betStatisticsQuery.data
                .map((bs) => bs.clientName)
                .filter((v, i, a) => a.indexOf(v) === i)
                .filter((cl) => props.clients === undefined || props.clients.includes(cl))
                .sort();
            setClients(clList);
            setClientFilter(clList);

            const tList = betStatisticsQuery.data
                .map((bs) => bs.tournamentShortName)
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort();
            setTournaments(tList);
            setTournamentFilter(tList);
        }
    }, [betStatisticsQuery.data, props.clients]);

    useEffect(() => {
        if (betStats) {
            const yValues = betStats
                .filter((bs) => clientFilter.includes(bs.clientName))
                .filter((bs) => tournamentFilter.includes(bs.tournamentShortName))
                .map((bs) => getRowKey(bs))
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort();
            const xValues = betStats
                .map((bs) => bs.tournamentRoundNumber)
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort((a, b) => a - b);
            setYValues(yValues);
            setXValues(xValues);
        }
    }, [betStats, clientFilter, tournamentFilter]);

    const getRowKey = (bs: BetCountStatistic): string => bs.tournamentShortName + '-' + bs.clientName;

    const getMatrixValue = (x: number, y: string): number | undefined => {
        if (!betStats) {
            return;
        }
        const bs = betStats.find((bs) => y === getRowKey(bs) && x === bs.tournamentRoundNumber);
        return bs?.betCount;
    };

    const toggleClientFilter = (cl: string) => {
        if (clientFilter.includes(cl)) {
            setClientFilter(clientFilter.filter((c) => c !== cl));
        } else {
            setClientFilter([...clientFilter, cl]);
        }
    };
    const toggleTournamentFilter = (cl: string) => {
        if (tournamentFilter.includes(cl)) {
            setTournamentFilter(tournamentFilter.filter((c) => c !== cl));
        } else {
            setTournamentFilter([...tournamentFilter, cl]);
        }
    };
    return (
        <>
            <Section>
                <SectionHeading>Bet Overview</SectionHeading>
                <div className="d-flex flex-row justify-content-start">
                    {clients?.length > 1 && (
                        <div className="me-5">
                            Client-Filter:
                            {clients.map((cl) => (
                                <div className="mx-2">
                                    <Input
                                        type="checkbox"
                                        key={cl}
                                        checked={clientFilter.includes(cl)}
                                        onChange={(e) => toggleClientFilter(cl)}
                                    />
                                    <ReactLabel className="ms-1">{cl}</ReactLabel>
                                </div>
                            ))}
                        </div>
                    )}
                    <div>
                        Tournament-Filter:
                        {tournaments.map((t) => (
                            <div className="mx-2" key={t}>
                                <span className="text-nowrap">
                                    <Input
                                        type="checkbox"
                                        key={t}
                                        checked={tournamentFilter.includes(t)}
                                        onChange={(e) => toggleTournamentFilter(t)}
                                    />
                                    <ReactLabel className="ms-1">{t}</ReactLabel>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ maxWidth: '90vw' }}>
                    <Table responsive={true}>
                        <thead>
                            <tr>
                                <th style={{ position: 'sticky', left: 0, zIndex: 1001 }} className="bg-light">
                                    Tournament/Client
                                </th>
                                {xValues.map((x) => (
                                    <th key={x}>{x}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {yValues.map((y) => (
                                <tr key={y}>
                                    <td style={{ position: 'sticky', left: 0, zIndex: 1001 }} className="bg-light">
                                        {y}
                                    </td>
                                    {xValues.map((x) => (
                                        <td key={x}>{getMatrixValue(x, y)}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                {/*                 <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis dataKey="tournamentName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </BarChart>
                </ResponsiveContainer>
 */}{' '}
            </Section>
        </>
    );
};
