/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSong
 */
export interface UserSong {
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    songId: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    songArtist: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    songName: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    songRemark: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    songAudioUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    albumImageId: string;
    /**
     * 
     * @type {string}
     * @memberof UserSong
     */
    albumName: string;
    /**
     * 
     * @type {number}
     * @memberof UserSong
     */
    vote: number;
    /**
     * 
     * @type {Date}
     * @memberof UserSong
     */
    lastPlayed: Date;
}

/**
 * Check if a given object implements the UserSong interface.
 */
export function instanceOfUserSong(value: object): value is UserSong {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('songId' in value) || value['songId'] === undefined) return false;
    if (!('songArtist' in value) || value['songArtist'] === undefined) return false;
    if (!('songName' in value) || value['songName'] === undefined) return false;
    if (!('songRemark' in value) || value['songRemark'] === undefined) return false;
    if (!('songAudioUrl' in value) || value['songAudioUrl'] === undefined) return false;
    if (!('albumImageId' in value) || value['albumImageId'] === undefined) return false;
    if (!('albumName' in value) || value['albumName'] === undefined) return false;
    if (!('vote' in value) || value['vote'] === undefined) return false;
    if (!('lastPlayed' in value) || value['lastPlayed'] === undefined) return false;
    return true;
}

export function UserSongFromJSON(json: any): UserSong {
    return UserSongFromJSONTyped(json, false);
}

export function UserSongFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSong {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'songId': json['songId'],
        'songArtist': json['songArtist'],
        'songName': json['songName'],
        'songRemark': json['songRemark'],
        'songAudioUrl': json['songAudioUrl'],
        'albumImageId': json['albumImageId'],
        'albumName': json['albumName'],
        'vote': json['vote'],
        'lastPlayed': (new Date(json['lastPlayed'])),
    };
}

export function UserSongToJSON(value?: UserSong | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'songId': value['songId'],
        'songArtist': value['songArtist'],
        'songName': value['songName'],
        'songRemark': value['songRemark'],
        'songAudioUrl': value['songAudioUrl'],
        'albumImageId': value['albumImageId'],
        'albumName': value['albumName'],
        'vote': value['vote'],
        'lastPlayed': ((value['lastPlayed']).toISOString()),
    };
}

