/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BettingUser
 */
export interface BettingUser {
    /**
     * 
     * @type {number}
     * @memberof BettingUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BettingUser
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof BettingUser
     */
    bettingDataPublic: boolean;
    /**
     * 
     * @type {number}
     * @memberof BettingUser
     */
    wonTournaments: number;
    /**
     * 
     * @type {number}
     * @memberof BettingUser
     */
    wonTournamentRounds: number;
    /**
     * 
     * @type {Date}
     * @memberof BettingUser
     */
    regDate: Date;
}

/**
 * Check if a given object implements the BettingUser interface.
 */
export function instanceOfBettingUser(value: object): value is BettingUser {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('bettingDataPublic' in value) || value['bettingDataPublic'] === undefined) return false;
    if (!('wonTournaments' in value) || value['wonTournaments'] === undefined) return false;
    if (!('wonTournamentRounds' in value) || value['wonTournamentRounds'] === undefined) return false;
    if (!('regDate' in value) || value['regDate'] === undefined) return false;
    return true;
}

export function BettingUserFromJSON(json: any): BettingUser {
    return BettingUserFromJSONTyped(json, false);
}

export function BettingUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BettingUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'bettingDataPublic': json['bettingDataPublic'],
        'wonTournaments': json['wonTournaments'],
        'wonTournamentRounds': json['wonTournamentRounds'],
        'regDate': (new Date(json['regDate'])),
    };
}

export function BettingUserToJSON(value?: BettingUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'bettingDataPublic': value['bettingDataPublic'],
        'wonTournaments': value['wonTournaments'],
        'wonTournamentRounds': value['wonTournamentRounds'],
        'regDate': ((value['regDate']).toISOString()),
    };
}

