/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgbInfo,
  EmailUpdateResult,
  RestResult,
  Role,
  UpdatePasswordResult,
  UserId,
  UserProfile,
  UserProfileContactData,
  UserProfileSettings,
} from '../models/index';
import {
    AgbInfoFromJSON,
    AgbInfoToJSON,
    EmailUpdateResultFromJSON,
    EmailUpdateResultToJSON,
    RestResultFromJSON,
    RestResultToJSON,
    RoleFromJSON,
    RoleToJSON,
    UpdatePasswordResultFromJSON,
    UpdatePasswordResultToJSON,
    UserIdFromJSON,
    UserIdToJSON,
    UserProfileFromJSON,
    UserProfileToJSON,
    UserProfileContactDataFromJSON,
    UserProfileContactDataToJSON,
    UserProfileSettingsFromJSON,
    UserProfileSettingsToJSON,
} from '../models/index';

export interface ChangeUserNameRequest {
    name?: string;
}

export interface GetAgbInfosRequest {
    tenant: string;
}

export interface GetAvailableCountriesRequest {
    tenant: string;
}

export interface GetMyUserProfileRequest {
    tenant: string;
}

export interface GetPublicUserProfileRequest {
    tenant: string;
    userId: string;
}

export interface GetPublicUserProfileByNameRequest {
    name: string;
    tenant: string;
}

export interface GetRolesRequest {
    tenant: string;
}

export interface GetUserImageRequest {
    tenant: string;
    userId: string;
}

export interface GetUserProfileContactDataRequest {
    tenant: string;
}

export interface GetUserSettingsRequest {
    tenant: string;
}

export interface IsValidPasswordRequest {
    tenant: string;
    pwd: string;
}

export interface LogoutRequest {
    tenant: string;
}

export interface UpdateEmailRequest {
    emailAddress?: string;
}

export interface UpdateImageRequest {
    imageId?: string;
}

export interface UpdatePasswordRequest {
    newPwd?: string;
    oldPwd?: string;
}

export interface UpdateUserProfileContactDataRequest {
    tenant: string;
    userProfileContactData?: UserProfileContactData;
}

export interface UpdateUserProfileDataRequest {
    tenant: string;
    userProfile?: UserProfile;
}

export interface UpdateUserSettingsRequest {
    tenant: string;
    userProfileSettings?: UserProfileSettings;
}

/**
 * 
 */
export class Profile2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async acceptPrivacyStatementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserId>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/agb`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserIdFromJSON(jsonValue));
    }

    /**
     */
    async acceptPrivacyStatement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserId> {
        const response = await this.acceptPrivacyStatementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async changeUserNameRaw(requestParameters: ChangeUserNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/change-user-name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async changeUserName(requestParameters: ChangeUserNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.changeUserNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/delete-account`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteImageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/image`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteImage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteImageRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dismissInvalidEmailTeaserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/invalid-email-dismiss-teaser`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async dismissInvalidEmailTeaser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.dismissInvalidEmailTeaserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dismissNewsletterTeaserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/newsletter-dismiss-teaser`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async dismissNewsletterTeaser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.dismissNewsletterTeaserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgbInfosRaw(requestParameters: GetAgbInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgbInfo>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getAgbInfos().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/agb`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgbInfoFromJSON(jsonValue));
    }

    /**
     */
    async getAgbInfos(requestParameters: GetAgbInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgbInfo> {
        const response = await this.getAgbInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableCountriesRaw(requestParameters: GetAvailableCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getAvailableCountries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/available-countries`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAvailableCountries(requestParameters: GetAvailableCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAvailableCountriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyUserProfileRaw(requestParameters: GetMyUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getMyUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async getMyUserProfile(requestParameters: GetMyUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.getMyUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPublicUserProfileRaw(requestParameters: GetPublicUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getPublicUserProfile().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getPublicUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/{userId}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async getPublicUserProfile(requestParameters: GetPublicUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.getPublicUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPublicUserProfileByNameRaw(requestParameters: GetPublicUserProfileByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getPublicUserProfileByName().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getPublicUserProfileByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/name/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async getPublicUserProfileByName(requestParameters: GetPublicUserProfileByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.getPublicUserProfileByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolesRaw(requestParameters: GetRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Role>>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getRoles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/roles`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     */
    async getRoles(requestParameters: GetRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Role>> {
        const response = await this.getRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserImageRaw(requestParameters: GetUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getUserImage().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user-image/{userId}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getUserImage(requestParameters: GetUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getUserImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserProfileContactDataRaw(requestParameters: GetUserProfileContactDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileContactData>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getUserProfileContactData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/contact-data`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileContactDataFromJSON(jsonValue));
    }

    /**
     */
    async getUserProfileContactData(requestParameters: GetUserProfileContactDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileContactData> {
        const response = await this.getUserProfileContactDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserSettingsRaw(requestParameters: GetUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileSettings>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getUserSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/settings`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileSettingsFromJSON(jsonValue));
    }

    /**
     */
    async getUserSettings(requestParameters: GetUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileSettings> {
        const response = await this.getUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isNameChangeAllowedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/is-name-change-allowed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async isNameChangeAllowed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.isNameChangeAllowedRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isValidPasswordRaw(requestParameters: IsValidPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling isValidPassword().'
            );
        }

        if (requestParameters['pwd'] == null) {
            throw new runtime.RequiredError(
                'pwd',
                'Required parameter "pwd" was null or undefined when calling isValidPassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pwd'] != null) {
            queryParameters['pwd'] = requestParameters['pwd'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/valid-pwd`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async isValidPassword(requestParameters: IsValidPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.isValidPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logoutRaw(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling logout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/logout`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async logout(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.logoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resendEmailConfirmationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/resend-email-confirmation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async resendEmailConfirmation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.resendEmailConfirmationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeToNewsletterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/newsletter-subscribe`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async subscribeToNewsletter(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.subscribeToNewsletterRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async toggleInfoMailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/toggle-info-mails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async toggleInfoMails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleInfoMailsRaw(initOverrides);
    }

    /**
     */
    async toggleNewsletterSubscriptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/toggle-newsletter-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async toggleNewsletterSubscription(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleNewsletterSubscriptionRaw(initOverrides);
    }

    /**
     */
    async togglePushNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/toggle-push-notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async togglePushNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.togglePushNotificationsRaw(initOverrides);
    }

    /**
     */
    async unsubscribeNewsletterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/newsletter-unsubscribe`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async unsubscribeNewsletter(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.unsubscribeNewsletterRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEmailRaw(requestParameters: UpdateEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailUpdateResult>> {
        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/update-email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailUpdateResultFromJSON(jsonValue));
    }

    /**
     */
    async updateEmail(requestParameters: UpdateEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailUpdateResult> {
        const response = await this.updateEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateImageRaw(requestParameters: UpdateImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['imageId'] != null) {
            queryParameters['imageId'] = requestParameters['imageId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/image`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async updateImage(requestParameters: UpdateImageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.updateImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePasswordRaw(requestParameters: UpdatePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePasswordResult>> {
        const queryParameters: any = {};

        if (requestParameters['newPwd'] != null) {
            queryParameters['newPwd'] = requestParameters['newPwd'];
        }

        if (requestParameters['oldPwd'] != null) {
            queryParameters['oldPwd'] = requestParameters['oldPwd'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/update-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePasswordResultFromJSON(jsonValue));
    }

    /**
     */
    async updatePassword(requestParameters: UpdatePasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePasswordResult> {
        const response = await this.updatePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserProfileContactDataRaw(requestParameters: UpdateUserProfileContactDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileContactData>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling updateUserProfileContactData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/contact-data`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileContactDataToJSON(requestParameters['userProfileContactData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileContactDataFromJSON(jsonValue));
    }

    /**
     */
    async updateUserProfileContactData(requestParameters: UpdateUserProfileContactDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileContactData> {
        const response = await this.updateUserProfileContactDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserProfileDataRaw(requestParameters: UpdateUserProfileDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling updateUserProfileData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/profile`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileToJSON(requestParameters['userProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async updateUserProfileData(requestParameters: UpdateUserProfileDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.updateUserProfileDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserSettingsRaw(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileSettings>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling updateUserSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{tenant}/user/settings`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileSettingsToJSON(requestParameters['userProfileSettings']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileSettingsFromJSON(jsonValue));
    }

    /**
     */
    async updateUserSettings(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileSettings> {
        const response = await this.updateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
