/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FuntipPushSubscription,
  RestResult,
} from '../models/index';
import {
    FuntipPushSubscriptionFromJSON,
    FuntipPushSubscriptionToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface SubscribeRequest {
    funtipPushSubscription?: FuntipPushSubscription;
}

export interface TestRequest {
    body?: string;
    icon?: string;
    title?: string;
}

export interface UnsubscribeRequest {
    endpoint?: string;
}

/**
 * 
 */
export class PushNotification2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async getPublicKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/push/public-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async getPublicKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.getPublicKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeRaw(requestParameters: SubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FuntipPushSubscriptionToJSON(requestParameters['funtipPushSubscription']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async subscribe(requestParameters: SubscribeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.subscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async testRaw(requestParameters: TestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['body'] != null) {
            queryParameters['body'] = requestParameters['body'];
        }

        if (requestParameters['icon'] != null) {
            queryParameters['icon'] = requestParameters['icon'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async test(requestParameters: TestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.testRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        if (requestParameters['endpoint'] != null) {
            queryParameters['endpoint'] = requestParameters['endpoint'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/push/unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async unsubscribe(requestParameters: UnsubscribeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.unsubscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
