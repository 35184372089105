/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientProperty
 */
export interface ClientProperty {
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    clientId: string;
    /**
     * 
     * @type {number}
     * @memberof ClientProperty
     */
    daysValid: number;
    /**
     * 
     * @type {Date}
     * @memberof ClientProperty
     */
    timeStamp: Date;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    languageName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    languageId: string;
    /**
     * 
     * @type {number}
     * @memberof ClientProperty
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ClientProperty
     */
    clientName: string;
    /**
     * 
     * @type {number}
     * @memberof ClientProperty
     */
    versionCount: number;
}

/**
 * Check if a given object implements the ClientProperty interface.
 */
export function instanceOfClientProperty(value: object): value is ClientProperty {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('clientId' in value) || value['clientId'] === undefined) return false;
    if (!('daysValid' in value) || value['daysValid'] === undefined) return false;
    if (!('timeStamp' in value) || value['timeStamp'] === undefined) return false;
    if (!('languageName' in value) || value['languageName'] === undefined) return false;
    if (!('languageId' in value) || value['languageId'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('clientName' in value) || value['clientName'] === undefined) return false;
    if (!('versionCount' in value) || value['versionCount'] === undefined) return false;
    return true;
}

export function ClientPropertyFromJSON(json: any): ClientProperty {
    return ClientPropertyFromJSONTyped(json, false);
}

export function ClientPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientProperty {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'name': json['name'],
        'value': json['value'],
        'clientId': json['clientId'],
        'daysValid': json['daysValid'],
        'timeStamp': (new Date(json['timeStamp'])),
        'languageName': json['languageName'],
        'languageId': json['languageId'],
        'version': json['version'],
        'clientName': json['clientName'],
        'versionCount': json['versionCount'],
    };
}

export function ClientPropertyToJSON(value?: ClientProperty | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'name': value['name'],
        'value': value['value'],
        'clientId': value['clientId'],
        'daysValid': value['daysValid'],
        'timeStamp': ((value['timeStamp']).toISOString()),
        'languageName': value['languageName'],
        'languageId': value['languageId'],
        'version': value['version'],
        'clientName': value['clientName'],
        'versionCount': value['versionCount'],
    };
}

