import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { matchStatisticLoad } from './actions';
import { MatchStatisticGames } from './MatchStatisticGames';
import { matchStatisticEntitySelector } from './selectors';
import { TeamLink } from '../../TeamLink';
import { FixtureStatistic, Team } from '../../../services/betting';

interface Props {
    team1Id: string;
    team2Id: string;
    display: boolean;
    onClose: () => void;
}

export const MatchStatistic: FC<Props> = props => {
    const dispatch = useDispatch();

    const stats = useSelector(matchStatisticEntitySelector);

    useEffect(() => {
        if (props.display) {
            dispatch(matchStatisticLoad({ team1: props.team1Id, team2: props.team2Id }));
        }
    }, [props.display, props.team1Id, props.team2Id, dispatch]);

    const [statsType, setStatsType] = useState<'all' | 'home' | 'away'>('all');

    if (stats) {
        return (
            <>
                <Modal isOpen={props.display} toggle={props.onClose} size={'lg'}>
                    <ModalHeader toggle={props.onClose}>
                        Begegnungs-Statistik {stats.team1.name} vs. {stats.team2.name}
                    </ModalHeader>
                    <ModalBody>
                        <Button color="link" onClick={() => setStatsType('all')} disabled={statsType === 'all'}>
                            Alle
                        </Button>
                        <Button color="link" onClick={() => setStatsType('home')} disabled={statsType === 'home'}>
                            @{stats.team1.name} 
                        </Button>
                        <Button color="link" onClick={() => setStatsType('away')} disabled={statsType === 'away'}>
                            @{stats.team2.name}
                        </Button>
                        {statsType === 'all' && (
                            <FixtureStatisticComp team1={stats.team1} team2={stats.team2} stats={stats.allStats} />
                        )}
                        {statsType === 'home' && (
                            <FixtureStatisticComp team1={stats.team1} team2={stats.team2} stats={stats.homeStats} />
                        )}
                        {statsType === 'away' && (
                            <FixtureStatisticComp team1={stats.team2} team2={stats.team1} stats={stats.awayStats} />
                        )}

                        <MatchStatisticGames team1Id={props.team1Id} team2Id={props.team2Id} count={5} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={props.onClose}>Schließen</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    } else {
        return null;
    }
};

interface FixtureStatisticProps {
    team1: Team;
    team2: Team;
    stats: FixtureStatistic;
}
const FixtureStatisticComp: FC<FixtureStatisticProps> = props => {
    return (
        <>
            <Table>
                <tbody>
                    <tr>
                        <td>Sieg <TeamLink team={props.team1} /></td>
                        <td>{props.stats.wonCount}</td>
                    </tr>
                    <tr>
                        <td>Unentschieden</td>
                        <td>{props.stats.drawCount}</td>
                    </tr>
                    <tr>
                        <td>Sieg <TeamLink team={props.team2} /></td>
                        <td>{props.stats.lostCount}</td>
                    </tr>
                    <tr>
                        <td>Torverhältnis</td>
                        <td>
                            {props.stats.goals1}&nbsp;:&nbsp;{props.stats.goals2}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};
