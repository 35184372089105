import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { AuthTest } from './AuthTest';
import { NewsletterAdminScreen } from './NewsletterAdmin';
import { ClientAdminIndexComp } from './ClientAdminIndex';
import { ClientAdminBetOverviewScreen } from './ClientAdminBetOverview';

interface Props {}

export const clientAdminBetOverviewPath = () => `/client-admin/bet-overview`;
export const newsletterListPath = () => `/client-admin/newsletter/list`;
export const clientAdminIndexPath = () => `/client-admin/`;


export const ClientAdminApp: FC<Props> = props => {
    return (
        <>
            <Routes>
                <Route path="auth-test" element={<AuthTest />} />
                <Route path="newsletter/*" element={<NewsletterAdminScreen />} />
                <Route path="bet-overview" element={<ClientAdminBetOverviewScreen />} />
                <Route path="/*" index={true} element={<ClientAdminIndexComp />} />
            </Routes>
        </>
    );
};
