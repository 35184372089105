/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterLayoutBlock
 */
export interface NewsletterLayoutBlock {
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayoutBlock
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayoutBlock
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayoutBlock
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterLayoutBlock
     */
    layoutId: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterLayoutBlock
     */
    usageCount: number;
}

/**
 * Check if a given object implements the NewsletterLayoutBlock interface.
 */
export function instanceOfNewsletterLayoutBlock(value: object): value is NewsletterLayoutBlock {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('layoutId' in value) || value['layoutId'] === undefined) return false;
    if (!('usageCount' in value) || value['usageCount'] === undefined) return false;
    return true;
}

export function NewsletterLayoutBlockFromJSON(json: any): NewsletterLayoutBlock {
    return NewsletterLayoutBlockFromJSONTyped(json, false);
}

export function NewsletterLayoutBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterLayoutBlock {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'text': json['text'] == null ? undefined : json['text'],
        'layoutId': json['layoutId'],
        'usageCount': json['usageCount'],
    };
}

export function NewsletterLayoutBlockToJSON(value?: NewsletterLayoutBlock | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'text': value['text'],
        'layoutId': value['layoutId'],
        'usageCount': value['usageCount'],
    };
}

