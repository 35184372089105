/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BetCountStatistic,
  Category,
  ClientTournament,
  ClientTournamentStats,
  GameSysAdmin,
  HighscoreStrategyDescriptor,
  RankingStrategy,
  RestResult1,
  Sport,
  Team,
  Tournament,
  TournamentCreator,
  TournamentGroup,
  TournamentGroupMember,
  TournamentRound,
} from '../models/index';
import {
    BetCountStatisticFromJSON,
    BetCountStatisticToJSON,
    CategoryFromJSON,
    CategoryToJSON,
    ClientTournamentFromJSON,
    ClientTournamentToJSON,
    ClientTournamentStatsFromJSON,
    ClientTournamentStatsToJSON,
    GameSysAdminFromJSON,
    GameSysAdminToJSON,
    HighscoreStrategyDescriptorFromJSON,
    HighscoreStrategyDescriptorToJSON,
    RankingStrategyFromJSON,
    RankingStrategyToJSON,
    RestResult1FromJSON,
    RestResult1ToJSON,
    SportFromJSON,
    SportToJSON,
    TeamFromJSON,
    TeamToJSON,
    TournamentFromJSON,
    TournamentToJSON,
    TournamentCreatorFromJSON,
    TournamentCreatorToJSON,
    TournamentGroupFromJSON,
    TournamentGroupToJSON,
    TournamentGroupMemberFromJSON,
    TournamentGroupMemberToJSON,
    TournamentRoundFromJSON,
    TournamentRoundToJSON,
} from '../models/index';

export interface AddTournamentGroupMemberRequest {
    teamId: string;
    tgId: string;
}

export interface AdjustTournamentGroupMembersRequest {
    trId: string;
}

export interface ChangeOrderClientTournamentRequest {
    clId: string;
    tId: string;
    direction?: string;
}

export interface CopyTournamentRequest {
    tId: string;
}

export interface CopyTournamentSubscribersRequest {
    clId: string;
    fromTId: string;
    tId: string;
}

export interface CreateGameRequest {
    bettingAllowed?: boolean;
    dateStr?: string;
    finished?: boolean;
    infoText?: string;
    intermediateResult?: boolean;
    state?: string;
    team1Id?: string;
    team2Id?: string;
    timeStr?: string;
    timeZone?: string;
    trId?: string;
}

export interface CreateOrUpdateClientTournamentRequest {
    clId: string;
    tId: string;
    hsdId?: string;
    state?: string;
}

export interface CreateTournamentGroupRequest {
    trId: string;
    longName?: string;
    name?: string;
    order?: number;
}

export interface DeleteGameRequest {
    gameId: string;
}

export interface DeleteGameBetsRequest {
    gameId: string;
}

export interface DeleteGameResultRequest {
    gameId: string;
    type?: string;
}

export interface DeleteTournamentRequest {
    tId: string;
}

export interface DeleteTournamentGroupRequest {
    tgId: string;
}

export interface DeleteTournamentGroupMemberRequest {
    tgId: string;
    tgmId: string;
}

export interface GetAvailableTeamsRequest {
    tgId: string;
}

export interface GetAvailableTeamsForGameRequest {
    trId: string;
    all: boolean;
    gameId: string;
}

export interface GetClientTournamentRequest {
    clId: string;
    tId: string;
}

export interface GetClientTournamentStatsRequest {
    clId: string;
    tId: string;
}

export interface GetGameSysAdminRequest {
    gameId: string;
}

export interface GetHighscoreStrategyDescriptorsRequest {
    clId: string;
}

export interface GetTournamentRequest {
    tId: string;
}

export interface GetTournamentGroupRequest {
    tgId: string;
}

export interface GetTournamentGroupMemberRequest {
    tgId: string;
}

export interface GetTournamentGroupsRequest {
    trId: string;
}

export interface GetTournamentRoundsRequest {
    tId: string;
}

export interface ImportLiveTickerRequest {
    gameId: string;
}

export interface RecalculatePointsRequest {
    trId: string;
}

export interface RecalculateTRGMsRequest {
    trId: string;
}

export interface RefreshUserGroupClientTournamentsAsyncRequest {
    clId: string;
    tId: string;
}

export interface SearchTournamentsRequest {
    name: string;
}

export interface TournamentAllowBetsRequest {
    tId: string;
}

export interface TournamentRoundAllowBetsRequest {
    trId: string;
}

export interface UpdateGameRequest {
    gameId: string;
    bettingAllowed?: boolean;
    dateStr?: string;
    finished?: boolean;
    infoText?: string;
    intermediateResult?: boolean;
    state?: string;
    team1Id?: string;
    team2Id?: string;
    timeStr?: string;
    timeZone?: string;
    trId?: string;
}

export interface UpdateGameResultRequest {
    gameId: string;
    r1?: number;
    r2?: number;
    type?: string;
}

export interface UpdateMatchStatisticsRequest {
    gameId: string;
}

export interface UpdateTournamentRequest {
    tId: string;
    categoryId?: number;
    key?: string;
    name?: string;
    rankingStrategy?: string;
    season?: string;
    shortName?: string;
    tournamentCreator?: string;
}

export interface UpdateTournamentGroupRequest {
    tgId: string;
    longName?: string;
    name?: string;
    order?: number;
}

export interface UpdateTournamentRankingRequest {
    tId: string;
}

export interface UpdateTournamentStartAndEndDatesRequest {
    tId: string;
}

/**
 * 
 */
export class TournamentSysadminControllerApi extends runtime.BaseAPI {

    /**
     */
    async addTournamentGroupMemberRaw(requestParameters: AddTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentGroupMember>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling addTournamentGroupMember().'
            );
        }

        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling addTournamentGroupMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}/member/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))).replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentGroupMemberFromJSON(jsonValue));
    }

    /**
     */
    async addTournamentGroupMember(requestParameters: AddTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentGroupMember> {
        const response = await this.addTournamentGroupMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async adjustTournamentGroupMembersRaw(requestParameters: AdjustTournamentGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling adjustTournamentGroupMembers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{trId}/adjust-members`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async adjustTournamentGroupMembers(requestParameters: AdjustTournamentGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.adjustTournamentGroupMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeOrderClientTournamentRaw(requestParameters: ChangeOrderClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTournament>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling changeOrderClientTournament().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling changeOrderClientTournament().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['direction'] != null) {
            queryParameters['direction'] = requestParameters['direction'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}/change-order`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTournamentFromJSON(jsonValue));
    }

    /**
     */
    async changeOrderClientTournament(requestParameters: ChangeOrderClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTournament> {
        const response = await this.changeOrderClientTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async copyTournamentRaw(requestParameters: CopyTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling copyTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/copy/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async copyTournament(requestParameters: CopyTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.copyTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async copyTournamentSubscribersRaw(requestParameters: CopyTournamentSubscribersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling copyTournamentSubscribers().'
            );
        }

        if (requestParameters['fromTId'] == null) {
            throw new runtime.RequiredError(
                'fromTId',
                'Required parameter "fromTId" was null or undefined when calling copyTournamentSubscribers().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling copyTournamentSubscribers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}/copy-subscribers/{fromTId}`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"fromTId"}}`, encodeURIComponent(String(requestParameters['fromTId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async copyTournamentSubscribers(requestParameters: CopyTournamentSubscribersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.copyTournamentSubscribersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createGameRaw(requestParameters: CreateGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        const queryParameters: any = {};

        if (requestParameters['bettingAllowed'] != null) {
            queryParameters['bettingAllowed'] = requestParameters['bettingAllowed'];
        }

        if (requestParameters['dateStr'] != null) {
            queryParameters['dateStr'] = requestParameters['dateStr'];
        }

        if (requestParameters['finished'] != null) {
            queryParameters['finished'] = requestParameters['finished'];
        }

        if (requestParameters['infoText'] != null) {
            queryParameters['infoText'] = requestParameters['infoText'];
        }

        if (requestParameters['intermediateResult'] != null) {
            queryParameters['intermediateResult'] = requestParameters['intermediateResult'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        if (requestParameters['team1Id'] != null) {
            queryParameters['team1Id'] = requestParameters['team1Id'];
        }

        if (requestParameters['team2Id'] != null) {
            queryParameters['team2Id'] = requestParameters['team2Id'];
        }

        if (requestParameters['timeStr'] != null) {
            queryParameters['timeStr'] = requestParameters['timeStr'];
        }

        if (requestParameters['timeZone'] != null) {
            queryParameters['timeZone'] = requestParameters['timeZone'];
        }

        if (requestParameters['trId'] != null) {
            queryParameters['trId'] = requestParameters['trId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async createGame(requestParameters: CreateGameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.createGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrUpdateClientTournamentRaw(requestParameters: CreateOrUpdateClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTournament>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling createOrUpdateClientTournament().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling createOrUpdateClientTournament().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hsdId'] != null) {
            queryParameters['hsdId'] = requestParameters['hsdId'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTournamentFromJSON(jsonValue));
    }

    /**
     */
    async createOrUpdateClientTournament(requestParameters: CreateOrUpdateClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTournament> {
        const response = await this.createOrUpdateClientTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTournamentGroupRaw(requestParameters: CreateTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentGroup>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling createTournamentGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['longName'] != null) {
            queryParameters['longName'] = requestParameters['longName'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{trId}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentGroupFromJSON(jsonValue));
    }

    /**
     */
    async createTournamentGroup(requestParameters: CreateTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentGroup> {
        const response = await this.createTournamentGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGameRaw(requestParameters: DeleteGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling deleteGame().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteGame(requestParameters: DeleteGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGameBetsRaw(requestParameters: DeleteGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling deleteGameBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/bets`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteGameBets(requestParameters: DeleteGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteGameBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGameResultRaw(requestParameters: DeleteGameResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling deleteGameResult().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/delete-result`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteGameResult(requestParameters: DeleteGameResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteGameResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTournamentRaw(requestParameters: DeleteTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling deleteTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteTournament(requestParameters: DeleteTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTournamentGroupRaw(requestParameters: DeleteTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling deleteTournamentGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteTournamentGroup(requestParameters: DeleteTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteTournamentGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTournamentGroupMemberRaw(requestParameters: DeleteTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling deleteTournamentGroupMember().'
            );
        }

        if (requestParameters['tgmId'] == null) {
            throw new runtime.RequiredError(
                'tgmId',
                'Required parameter "tgmId" was null or undefined when calling deleteTournamentGroupMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}/member/{tgmId}`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))).replace(`{${"tgmId"}}`, encodeURIComponent(String(requestParameters['tgmId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async deleteTournamentGroupMember(requestParameters: DeleteTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.deleteTournamentGroupMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableGameStatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAvailableGameStates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAvailableGameStatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableTeamsRaw(requestParameters: GetAvailableTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling getAvailableTeams().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}/member/available`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async getAvailableTeams(requestParameters: GetAvailableTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.getAvailableTeamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableTeamsForGameRaw(requestParameters: GetAvailableTeamsForGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getAvailableTeamsForGame().'
            );
        }

        if (requestParameters['all'] == null) {
            throw new runtime.RequiredError(
                'all',
                'Required parameter "all" was null or undefined when calling getAvailableTeamsForGame().'
            );
        }

        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getAvailableTeamsForGame().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['all'] != null) {
            queryParameters['all'] = requestParameters['all'];
        }

        if (requestParameters['gameId'] != null) {
            queryParameters['gameId'] = requestParameters['gameId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-round/{trId}/available-teams`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async getAvailableTeamsForGame(requestParameters: GetAvailableTeamsForGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.getAvailableTeamsForGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBetStatisticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BetCountStatistic>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/bet-statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BetCountStatisticFromJSON));
    }

    /**
     */
    async getBetStatistics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BetCountStatistic>> {
        const response = await this.getBetStatisticsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Category>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryFromJSON));
    }

    /**
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Category>> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientTournamentRaw(requestParameters: GetClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTournament>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling getClientTournament().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getClientTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTournamentFromJSON(jsonValue));
    }

    /**
     */
    async getClientTournament(requestParameters: GetClientTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTournament> {
        const response = await this.getClientTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientTournamentStatsRaw(requestParameters: GetClientTournamentStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTournamentStats>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling getClientTournamentStats().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getClientTournamentStats().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}/stats`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTournamentStatsFromJSON(jsonValue));
    }

    /**
     */
    async getClientTournamentStats(requestParameters: GetClientTournamentStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTournamentStats> {
        const response = await this.getClientTournamentStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientTournamentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientTournament>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientTournamentFromJSON));
    }

    /**
     */
    async getClientTournaments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientTournament>> {
        const response = await this.getClientTournamentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameSysAdminRaw(requestParameters: GetGameSysAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GameSysAdmin>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameSysAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameSysAdminFromJSON(jsonValue));
    }

    /**
     */
    async getGameSysAdmin(requestParameters: GetGameSysAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GameSysAdmin> {
        const response = await this.getGameSysAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoreStrategyDescriptorsRaw(requestParameters: GetHighscoreStrategyDescriptorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HighscoreStrategyDescriptor>>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling getHighscoreStrategyDescriptors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/highscore-strategy-descriptors/{clId}`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HighscoreStrategyDescriptorFromJSON));
    }

    /**
     */
    async getHighscoreStrategyDescriptors(requestParameters: GetHighscoreStrategyDescriptorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HighscoreStrategyDescriptor>> {
        const response = await this.getHighscoreStrategyDescriptorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRankingStrategiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RankingStrategy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/ranking-strategies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RankingStrategyFromJSON));
    }

    /**
     */
    async getRankingStrategies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RankingStrategy>> {
        const response = await this.getRankingStrategiesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Sport>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/sports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SportFromJSON));
    }

    /**
     */
    async getSports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Sport>> {
        const response = await this.getSportsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentRaw(requestParameters: GetTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async getTournament(requestParameters: GetTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.getTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentCreatorsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentCreator>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-creators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentCreatorFromJSON));
    }

    /**
     */
    async getTournamentCreators(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentCreator>> {
        const response = await this.getTournamentCreatorsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentGroupRaw(requestParameters: GetTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentGroup>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling getTournamentGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentGroupFromJSON(jsonValue));
    }

    /**
     */
    async getTournamentGroup(requestParameters: GetTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentGroup> {
        const response = await this.getTournamentGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentGroupMemberRaw(requestParameters: GetTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentGroupMember>>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling getTournamentGroupMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}/member`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentGroupMemberFromJSON));
    }

    /**
     */
    async getTournamentGroupMember(requestParameters: GetTournamentGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentGroupMember>> {
        const response = await this.getTournamentGroupMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentGroupsRaw(requestParameters: GetTournamentGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentGroup>>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getTournamentGroups().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/list/{trId}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentGroupFromJSON));
    }

    /**
     */
    async getTournamentGroups(requestParameters: GetTournamentGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentGroup>> {
        const response = await this.getTournamentGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentRoundsRaw(requestParameters: GetTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentRound>>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTournamentRounds().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/{tId}/rounds`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentRoundFromJSON));
    }

    /**
     */
    async getTournamentRounds(requestParameters: GetTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentRound>> {
        const response = await this.getTournamentRoundsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importLiveTickerRaw(requestParameters: ImportLiveTickerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling importLiveTicker().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/import-ticker`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async importLiveTicker(requestParameters: ImportLiveTickerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.importLiveTickerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listTournamentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tournament>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentFromJSON));
    }

    /**
     */
    async listTournaments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tournament>> {
        const response = await this.listTournamentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async recalculatePointsRaw(requestParameters: RecalculatePointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling recalculatePoints().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-round/{trId}/recalculate-points`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async recalculatePoints(requestParameters: RecalculatePointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.recalculatePointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recalculateTRGMsRaw(requestParameters: RecalculateTRGMsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling recalculateTRGMs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-round/{trId}/recalculate-trgms`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async recalculateTRGMs(requestParameters: RecalculateTRGMsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.recalculateTRGMsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshUserGroupClientTournamentsAsyncRaw(requestParameters: RefreshUserGroupClientTournamentsAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling refreshUserGroupClientTournamentsAsync().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling refreshUserGroupClientTournamentsAsync().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/client-tournament/{clId}/{tId}/refresh-subscribed-user-groups`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async refreshUserGroupClientTournamentsAsync(requestParameters: RefreshUserGroupClientTournamentsAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.refreshUserGroupClientTournamentsAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchTournamentsRaw(requestParameters: SearchTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tournament>>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling searchTournaments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentFromJSON));
    }

    /**
     */
    async searchTournaments(requestParameters: SearchTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tournament>> {
        const response = await this.searchTournamentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tournamentAllowBetsRaw(requestParameters: TournamentAllowBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling tournamentAllowBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament/{tId}/allow-bets`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async tournamentAllowBets(requestParameters: TournamentAllowBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.tournamentAllowBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tournamentRoundAllowBetsRaw(requestParameters: TournamentRoundAllowBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling tournamentRoundAllowBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-round/{trId}/allow-bets`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async tournamentRoundAllowBets(requestParameters: TournamentRoundAllowBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.tournamentRoundAllowBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateGameRaw(requestParameters: UpdateGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling updateGame().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['bettingAllowed'] != null) {
            queryParameters['bettingAllowed'] = requestParameters['bettingAllowed'];
        }

        if (requestParameters['dateStr'] != null) {
            queryParameters['dateStr'] = requestParameters['dateStr'];
        }

        if (requestParameters['finished'] != null) {
            queryParameters['finished'] = requestParameters['finished'];
        }

        if (requestParameters['infoText'] != null) {
            queryParameters['infoText'] = requestParameters['infoText'];
        }

        if (requestParameters['intermediateResult'] != null) {
            queryParameters['intermediateResult'] = requestParameters['intermediateResult'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        if (requestParameters['team1Id'] != null) {
            queryParameters['team1Id'] = requestParameters['team1Id'];
        }

        if (requestParameters['team2Id'] != null) {
            queryParameters['team2Id'] = requestParameters['team2Id'];
        }

        if (requestParameters['timeStr'] != null) {
            queryParameters['timeStr'] = requestParameters['timeStr'];
        }

        if (requestParameters['timeZone'] != null) {
            queryParameters['timeZone'] = requestParameters['timeZone'];
        }

        if (requestParameters['trId'] != null) {
            queryParameters['trId'] = requestParameters['trId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/update`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async updateGame(requestParameters: UpdateGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.updateGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateGameResultRaw(requestParameters: UpdateGameResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling updateGameResult().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['r1'] != null) {
            queryParameters['r1'] = requestParameters['r1'];
        }

        if (requestParameters['r2'] != null) {
            queryParameters['r2'] = requestParameters['r2'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/update-result`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async updateGameResult(requestParameters: UpdateGameResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.updateGameResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMatchStatisticsRaw(requestParameters: UpdateMatchStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling updateMatchStatistics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/game/{gameId}/update-match-statistics`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async updateMatchStatistics(requestParameters: UpdateMatchStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.updateMatchStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTournamentRaw(requestParameters: UpdateTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling updateTournament().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['categoryId'] != null) {
            queryParameters['categoryId'] = requestParameters['categoryId'];
        }

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['rankingStrategy'] != null) {
            queryParameters['rankingStrategy'] = requestParameters['rankingStrategy'];
        }

        if (requestParameters['season'] != null) {
            queryParameters['season'] = requestParameters['season'];
        }

        if (requestParameters['shortName'] != null) {
            queryParameters['shortName'] = requestParameters['shortName'];
        }

        if (requestParameters['tournamentCreator'] != null) {
            queryParameters['tournamentCreator'] = requestParameters['tournamentCreator'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async updateTournament(requestParameters: UpdateTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.updateTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTournamentGroupRaw(requestParameters: UpdateTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentGroup>> {
        if (requestParameters['tgId'] == null) {
            throw new runtime.RequiredError(
                'tgId',
                'Required parameter "tgId" was null or undefined when calling updateTournamentGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['longName'] != null) {
            queryParameters['longName'] = requestParameters['longName'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament-group/{tgId}`.replace(`{${"tgId"}}`, encodeURIComponent(String(requestParameters['tgId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentGroupFromJSON(jsonValue));
    }

    /**
     */
    async updateTournamentGroup(requestParameters: UpdateTournamentGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentGroup> {
        const response = await this.updateTournamentGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTournamentRankingRaw(requestParameters: UpdateTournamentRankingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling updateTournamentRanking().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament/{tId}/update-ranking`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async updateTournamentRanking(requestParameters: UpdateTournamentRankingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.updateTournamentRankingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTournamentStartAndEndDatesRaw(requestParameters: UpdateTournamentStartAndEndDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult1>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling updateTournamentStartAndEndDates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/tournaments/tournament/{tId}/update-start-and-end-dates`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResult1FromJSON(jsonValue));
    }

    /**
     */
    async updateTournamentStartAndEndDates(requestParameters: UpdateTournamentStartAndEndDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult1> {
        const response = await this.updateTournamentStartAndEndDatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
