/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientState } from './ClientState';
import {
    ClientStateFromJSON,
    ClientStateFromJSONTyped,
    ClientStateToJSON,
} from './ClientState';
import type { ClientId } from './ClientId';
import {
    ClientIdFromJSON,
    ClientIdFromJSONTyped,
    ClientIdToJSON,
} from './ClientId';

/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    host?: string;
    /**
     * 
     * @type {ClientId}
     * @memberof Client
     */
    parentId?: ClientId;
    /**
     * 
     * @type {ClientState}
     * @memberof Client
     */
    state?: ClientState;
}

/**
 * Check if a given object implements the Client interface.
 */
export function instanceOfClient(value: object): value is Client {
    return true;
}

export function ClientFromJSON(json: any): Client {
    return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'host': json['host'] == null ? undefined : json['host'],
        'parentId': json['parentId'] == null ? undefined : ClientIdFromJSON(json['parentId']),
        'state': json['state'] == null ? undefined : ClientStateFromJSON(json['state']),
    };
}

export function ClientToJSON(value?: Client | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'host': value['host'],
        'parentId': ClientIdToJSON(value['parentId']),
        'state': ClientStateToJSON(value['state']),
    };
}

