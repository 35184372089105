import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Col, Form, FormGroup, Label, Table } from 'reactstrap';
import { Team } from '../../../../services/betting';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { IncrementalTeamSearch } from '../../../common/IncrementalTeamSearch';
import { Section, SectionHeading } from '../../../common/Section';
import { isDefaultSport } from '../../../System/systemConfig';
import { TeamLink } from '../../../TeamLink';

interface Props {
    favoriteTeams: Team[];
    availableTeams: Team[];

    onDelete: (teamId: string) => void;
    onAdd: (teamId: string) => void;
}

const MAX_NUMBER_OF_FAVORITE_TEAMS = 20;

export const FavoriteTeams = (props: Props) => {
    const [selectedTeamId, setSeletectedTeamId] = React.useState('');

    function displayTeam(t: Team) {
        return (
            <tr key={t.id}>
                <td className="align-middle">
                    <TeamLink team={t} /> {isDefaultSport(t) ? null : `(${t.sportName})`}
                </td>
                <td className="align-middle">
                    <ButtonWithConfirmation
                        title="Lieblingsteam löschen"
                        message={`Sind Sie sicher, dass Sie '${t.name} nicht mehr als Lieblingsteam haben wollen?`}
                        color="warning"
                        onOK={() => props.onDelete(t.id)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    }

    const onAddFavoriteTeam = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedTeamId) {
            props.onAdd(selectedTeamId);
        }
    };

    const onTeamSelected = (teamId: string) => {
        setSeletectedTeamId(teamId);
    };

    return (
        <Section>
            <SectionHeading>Lieblingsteams</SectionHeading>
            {props.favoriteTeams.length === 0 && <p>Sie haben noch keine Lieblingsteams angelegt.</p>}
            {props.favoriteTeams.length > 0 && (
                <Table striped={true}>
                    <thead>
                        <tr>
                            <th>Team</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>{props.favoriteTeams && props.favoriteTeams.map((t) => displayTeam(t))}</tbody>
                </Table>
            )}
            {props.favoriteTeams.length < MAX_NUMBER_OF_FAVORITE_TEAMS && (
                <Form>
                    <FormGroup row={true}>
                        <Label for="newTeamId" sm={1}>
                            Team
                        </Label>
                        <Col sm={9}>
                            <IncrementalTeamSearch onTeamSelected={onTeamSelected} />
                        </Col>
                        <Col sm={2}>
                            <Button color="success" onClick={onAddFavoriteTeam}>
                                Hinzufügen
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            )}
            {props.favoriteTeams.length >= MAX_NUMBER_OF_FAVORITE_TEAMS && (
                <p>Sie haben bereits ihre maximal {MAX_NUMBER_OF_FAVORITE_TEAMS} Lieblingsteams angelegt.</p>
            )}
        </Section>
    );
};
