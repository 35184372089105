import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { Game, TournamentGroup, TournamentRound } from '../../../../../services/betting';
import { DateTime } from '../../../../DateTime';
import { getGameApi2, getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { GameBettingAllowed } from '../../../../TournamentApp/GameBettingAllowed';
import { GameFinished } from '../../../../TournamentApp/GameFinished';
import { GameResult } from '../../../../TournamentApp/GameResult';
import { LoadingIndicatorContent } from '../../../../common/LoadingIndicatorSpinner';
import { TournamentGroupEdit } from '../TournamentGroupEdit';
import { GameActions } from './GameActions';
import { TournamentGroupActions } from './TournamentGroupActions';
import { TournamentRoundActions } from './TournamentRoundActions';

interface TournamentRoundManagementProps {
    trId: string;
    tr?: TournamentRound;
}
export const TournamentRoundManagement: FC<TournamentRoundManagementProps> = (props) => {
    const gamesApi = getGameApi2();
    const tournamentApi = getTournamentSysAdminApi();

    const [games, setGames] = useState<Game[]>([]);
    const [tournamentGroups, setTournamentGroups] = useState<TournamentGroup[]>([]);

    const [editTournamentGroupId, setEditTournamentGroupId] = useState('');

    const tournamentGroupQuery = useQuery({
        queryKey: ['trounamentGroups.getByTrId', props.trId],
        queryFn: () => tournamentApi.getTournamentGroups({trId: props.trId}),
    });

    useEffect(() => {
        if (tournamentGroupQuery.data) {
            setTournamentGroups(tournamentGroupQuery.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
        }
    }, [tournamentGroupQuery.data]);

    const gamesQuery = useQuery({
        queryKey: ['games.getByTrId', props.trId],
        queryFn: () => gamesApi.getGamesByTournamentRound({trId: Number(props.trId)}),
    });

    useEffect(() => {
        if (gamesQuery.data) {
            setGames(gamesQuery.data);
        }
    }, [gamesQuery.data]);

    const renderGame = (g: Game, index: number) => {
        return (
            <tr key={g.id} className="align-middle">
                <td>{index + 1}</td>
                <td>
                    <DateTime date={g.date} format="short" />
                </td>
                <td>{g.team1.shortName}</td>
                <td>{g.team2.shortName}</td>
                <td>
                    <GameResult gameId={String(g.id)} date={g.date} />
                </td>
                <td>
                    <GameBettingAllowed bettingAllowed={g.bettingAllowed} />
                </td>
                <td>
                    <GameFinished finished={g.finished} />
                </td>
                <td>
                    <GameActions gameId={String(g.id)} trId={props.trId} onRefresh={() => gamesQuery.refetch()} />
                </td>
            </tr>
        );
    };

    const renderTournamentGroup = (tg: TournamentGroup) => {
        const name = tg.name?.trim() === '' ? '<no_name>' : tg.name;
        return (
            <span key={tg.id}>
                <Button color="link" onClick={() => setEditTournamentGroupId(String(tg.id))}>
                    {name}
                </Button>
                <TournamentGroupEdit
                    tgId={String(tg.id)}
                    isShown={editTournamentGroupId === String(tg.id)}
                    onClose={() => {
                        setEditTournamentGroupId('');
                        tournamentGroupQuery.refetch();
                    }}
                />
            </span>
        );
    };

    return (
        <>
            <div className="d-flex align-items-center mb-3 justify-content-between me-2">
                <h5 className="ms-3 m-0 ">
                    Round <span className="fw-bold text-warning">{props.tr?.name}</span>
                </h5>
                <TournamentRoundActions trId={props.trId} onRefresh={() => gamesQuery.refetch()} />
            </div>

            <div className="ms-3 d-flex align-items-center justify-content-between my-2 me-2">
                <div className="align-items-center">
                    Groups:&nbsp;{tournamentGroups.map((tg) => renderTournamentGroup(tg))}{' '}
                </div>
                <TournamentGroupActions trId={props.trId} onRefresh={() => tournamentGroupQuery.refetch()} />
            </div>
            <LoadingIndicatorContent isLoading={gamesQuery.isLoading}>
                {games.length > 0 && (
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th colSpan={2}>Game</th>
                                <th>Result</th>
                                <th>B</th>
                                <th>F</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>{games.map((g, index) => renderGame(g, index))}</tbody>
                    </Table>
                )}
            </LoadingIndicatorContent>
        </>
    );
};
