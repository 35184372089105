import {
    createRouterMiddleware,
    createRouterReducer,
    ReduxRouter,
    ReduxRouterSelector,
} from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import 'moment/locale/de';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router';
import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AnyAction } from 'typescript-fsa';
import App, { appCallback } from './App';
import { userMailReducer } from './components/AccountUserApp/AccountUserMail/reducer';
import { accountUserProfileReducer } from './components/AccountUserApp/AccountUserProfile/reducer';
import { accountUserSettingsReducer } from './components/AccountUserApp/AccountUserSettings/reducer';
import { userSearchReducer } from './components/AccountUserApp/IncrementalUserSearch/reducer';
import { GlobalState } from './components/actions';
import { bettingReducer } from './components/Betting/reducer';
import { notificationStatusReducer } from './components/BettingApp/BettingProfile/NotificationStatus/reducer';
import { bettingProfilReducer } from './components/BettingApp/BettingProfile/reducer';
import { bettingViewReducer } from './components/BettingApp/BettingView/reducer';
import { hsdReducer } from './components/BettingApp/HighscoreStrategyDescriptorScreen/reducer';
import { matchStatisticReducer } from './components/BettingApp/MatchStatistic/reducer';
import { myGroupHighscoreReducer } from './components/BettingApp/MyGroupHighscores/reducer';
import { roundWinnersReducer } from './components/BettingApp/RoundWinners/reducer';
import { tournamentArchiveReducer } from './components/BettingApp/TournamentArchive/reducer';
import { hsdEditReducer } from './components/BettingApp/UserGroupHighscoreDescriptorDialog/reducer';
import { blogReducer } from './components/BloggingApp/reducer';
import { portletReducer } from './components/common/RssPortlet/reducer';
import { groupInternalHighscoreReducer } from './components/GroupInternalHighscores/reducer';
import { jobManagementReducer } from './components/JobManagement/reducer';
import { playlistAdminReducer } from './components/PlaylistAdminApp/reducer';
import { portalReducer } from './components/RadioEins/Portal/reducer';
import { userAdminReducer } from './components/SysAdminApp/UserAdmin/reducer';
import { actionTokenReducer } from './components/System/ActionToken/reducer';
import { htmlIncludeReducer } from './components/System/HtmlInclude/reducer';
import { systemReducer } from './components/System/reducer';
import { teamChatReducer } from './components/TeamChat/reducer';
import { tournamentSelectionStateReducer } from './components/TournamentSelectionComponent/reducer';
import { groupHighscoreReducer } from './components/UserGroupHighscores/reducer';
import { userGroupListReducer } from './components/UserGroupList/reducer';
import { userListReducer } from './components/UserList/reducer';
import { userViewReducer } from './components/UserView/reducer';
// Bootstrap CSS
import './custom.scss';
// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import rootSaga from './sagas';
import { systemPropertyReducer } from './screens/SysAdmin/SystemProperties/reducer';
import { userGroupReducer } from './screens/UserGroupController/reducer';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { logError } from './utils';

const sagaMiddleware = createSagaMiddleware(); // sagaOptions);
export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

export const reduxStore: Store<GlobalState, AnyAction> = configureStore({
    reducer: {
        router: createRouterReducer(history),
        groupHighscore: groupHighscoreReducer,
        userGroupList: userGroupListReducer,
        userList: userListReducer,
        system: systemReducer,
        betting: bettingReducer,
        groupInternalHighscore: groupInternalHighscoreReducer,
        playlistAdmin: playlistAdminReducer,
        htmlInclude: htmlIncludeReducer,
        jobManagement: jobManagementReducer,
        userGroup: userGroupReducer,
        tournamentSelection: tournamentSelectionStateReducer,
        bettingProfile: bettingProfilReducer,
        accountUserProfile: accountUserProfileReducer,
        accountUserSettings: accountUserSettingsReducer,
        userView: userViewReducer,
        bettingView: bettingViewReducer,
        hsd: hsdReducer,
        hsdEdit: hsdEditReducer,
        notificationStatus: notificationStatusReducer,
        userMail: userMailReducer,
        userSearch: userSearchReducer,
        portlet: portletReducer,
        portal: portalReducer,
        blog: blogReducer,
        archive: tournamentArchiveReducer,
        userAdmin: userAdminReducer,
        matchStatistic: matchStatisticReducer,
        actionToken: actionTokenReducer,
        roundWinners: roundWinnersReducer,
        myGroupHighscores: myGroupHighscoreReducer,
        teamChat: teamChatReducer,
        systemPropertyState: systemPropertyReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ thunk: false, serializableCheck: false })
            .prepend(sagaMiddleware)
            .prepend(routerMiddleware);
    },
});

sagaMiddleware.run(rootSaga);

/*
registerServiceWorker()
    .then((w) => console.info('service worker successfully registered.'))
    .catch((e) => console.warn('service worker registration failed: ' + JSON.stringify(e)));
*/

serviceWorkerRegistration.register();

class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>> {
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo?: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        logError(error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}

(window as any).appCallback = appCallback;

const container = document.getElementById('root');
const root = createRoot(container!);
const routerSelector: ReduxRouterSelector<GlobalState> = (state) => state.router;

root.render(
    <ErrorBoundary>
        <Provider store={reduxStore}>
            <ReduxRouter history={history} routerSelector={routerSelector}>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </ReduxRouter>
        </Provider>
    </ErrorBoundary>,
);
