/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamChatMessage
 */
export interface TeamChatMessage {
    /**
     * 
     * @type {string}
     * @memberof TeamChatMessage
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamChatMessage
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof TeamChatMessage
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof TeamChatMessage
     */
    message: string;
}

/**
 * Check if a given object implements the TeamChatMessage interface.
 */
export function instanceOfTeamChatMessage(value: object): value is TeamChatMessage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function TeamChatMessageFromJSON(json: any): TeamChatMessage {
    return TeamChatMessageFromJSONTyped(json, false);
}

export function TeamChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamChatMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'userId': json['userId'],
        'message': json['message'],
    };
}

export function TeamChatMessageToJSON(value?: TeamChatMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'date': ((value['date']).toISOString()),
        'userId': value['userId'],
        'message': value['message'],
    };
}

