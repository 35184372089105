import { FC, PropsWithChildren } from 'react';

export interface SectionProps extends PropsWithChildren {
    id?: string;
    subtitle?: string;
}

export const Section: FC<SectionProps> = (props) => {
    return (
        <div className="bg-light border px-3 pt-1 pb-3 mt-4" id={props.id}>
            {props.children}
        </div>
    );
};

export const SysAdminSection: FC<SectionProps> = (props) => {
    return (
        <div className="border px-3 pt-1 pb-3 mt-4 border-4 border-danger" id={props.id}>
            {props.children}
        </div>
    );
};
export interface SectionHeadingProps extends PropsWithChildren {}

export const SectionHeading: FC<SectionHeadingProps> = (props) => {
    // const { className, ...rest } = props;
    // return <h2 {...rest} className={`mt-3 mb-2 ${className}`}>{props.children}</h2>;
    return <h2 className={`mt-3 mb-2`}>{props.children}</h2>;
};
