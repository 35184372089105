import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { isPushNotificationSupported } from '../../../utils';
import { Section, SectionHeading } from '../../common/Section';
import { systemSetPopupMessage } from '../../System/actions';
import { isSysAdminSelector, userIdSelector, userNameSelector } from '../../System/selectors';
import { getProfile2Api, getPushNotification2Api, getSystemConfig } from '../../System/systemConfig';
import { EmailConfirmationTeaser } from '../EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../InvalidEmailTeaser';
import { NewsletterTeaser } from '../NewsletterTeaser';
import { ProfileSubMenu } from '../ProfileSubMenu';
import { ChangeEmail } from './ChangeEmail';
import ChangePassword from './ChangePassword';
import { ChangeUserName } from './ChangeUserName';
import DeleteProfile from './DeleteProfile';
import { EmailNotificationSettings } from './EmailNotificationSettings';

const AccountUserSettingsComponent: React.FC<{}> = () => {
    const dispatch = useDispatch();

    const pushNotificationsSupported = isPushNotificationSupported();

    // const pushSubscription = useSelector(systemPushSubscriptionSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);
    const userId = useSelector(userIdSelector);

    const [testTitle, setTestTitle] = React.useState('Bayern - Dortmund 1:1');
    const [testBody, setTestBody] = React.useState('1. Bundesliga 2022/23, 17. Spieltag\nSpielbeginn: 15:30 Uhr');
    const [testIcon, setTestIcon] = React.useState('/images/soccer4.png');

    interface TestMessage {
        title: string;
        body: string;
        icon: string;
    }

    const profileQuery = useQuery({
        queryKey: ['profileSettings', userId],
        queryFn: () => getProfile2Api().getUserSettings({tenant: getSystemConfig().clientName}),
        enabled: !!userId,
    });

    const api = getPushNotification2Api();

    const testMessageMutation = useMutation({
        mutationFn: (v: TestMessage) => api.test({title: v.title, body: v.body, icon: v.icon}),
    });

    const togglePushNotificationsMutation = useMutation({
        mutationFn: () => getProfile2Api().togglePushNotifications(),
    });

    const pushSubscriptionChange = (ev: React.FormEvent<HTMLInputElement>) => {
        togglePushNotificationsMutation.mutate(undefined, {
            onSuccess: () => {
                profileQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Die Einstellungen konnten nicht gespeichert werden. ' + error));
            },
        });
    };

    const sendTestMessage = () => {
        testMessageMutation.mutate({ title: testTitle, body: testBody, icon: testIcon });
    };

    return (
        <>
            <ProfileSubMenu subMenu="settings" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />
            <Section>
                <SectionHeading>Push-Benachrichtigungen</SectionHeading>
                <FormGroup check={true}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="pushNotifications"
                            checked={profileQuery.data?.pushNotifications ?? false}
                            onChange={pushSubscriptionChange}
                        />
                        Ich möchte Push-Benachrichtigungen auf diesem Gerät erhalten.
                        {profileQuery.data?.pushNotifications && isSysAdmin && (
                            <Form className="p-3 bg-danger">
                                <FormGroup>
                                    <Label className="text-white" size="sm">
                                        Titel
                                    </Label>
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        placeholder="Titel"
                                        value={testTitle}
                                        onChange={(e) => {
                                            setTestTitle(e.currentTarget.value);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="text-white" size="sm">
                                        Body
                                    </Label>
                                    <Input
                                        bsSize="sm"
                                        type="textarea"
                                        placeholder="Body"
                                        value={testBody}
                                        onChange={(e) => {
                                            setTestBody(e.currentTarget.value);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="text-white" size="sm">
                                        Icon
                                    </Label>
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        placeholder="Icon"
                                        value={testIcon}
                                        onChange={(e) => {
                                            setTestIcon(e.currentTarget.value);
                                        }}
                                    />
                                </FormGroup>
                                <Button size="sm" onClick={sendTestMessage}>
                                    Test
                                </Button>
                            </Form>
                        )}
                    </Label>

                    <FormText>
                        <br />
                        {pushNotificationsSupported && <span>Push-Nachrichten werden vom Gerät unterstützt.</span>}
                        {!pushNotificationsSupported && (
                            <span>Push-Nachrichten werden vom Gerät nicht unterstützt.</span>
                        )}
                    </FormText>
                </FormGroup>
            </Section>

            {profileQuery.data?.userId && <EmailNotificationSettings userId={String(profileQuery.data?.userId)} />}

            <ChangePassword />
            <ChangeEmail />
            <ChangeUserName />
            <DeleteProfile />
        </>
    );
};

export const AccountUserSettingsScreen = R.compose(
    withBorder(),
    withTitle('Einstellungen', userNameSelector),
    withNavLocation('profile'),
    withLogin(),
)(AccountUserSettingsComponent);
