/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryStatistic
 */
export interface QueryStatistic {
    /**
     * 
     * @type {string}
     * @memberof QueryStatistic
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryStatistic
     */
    executionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryStatistic
     */
    executionTime?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryStatistic
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryStatistic
     */
    averageExecutionTimeInSec?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryStatistic
     */
    totalTime?: number;
}

/**
 * Check if a given object implements the QueryStatistic interface.
 */
export function instanceOfQueryStatistic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QueryStatisticFromJSON(json: any): QueryStatistic {
    return QueryStatisticFromJSONTyped(json, false);
}

export function QueryStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'executionCount': !exists(json, 'executionCount') ? undefined : json['executionCount'],
        'executionTime': !exists(json, 'executionTime') ? undefined : json['executionTime'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'averageExecutionTimeInSec': !exists(json, 'averageExecutionTimeInSec') ? undefined : json['averageExecutionTimeInSec'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
    };
}

export function QueryStatisticToJSON(value?: QueryStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'executionCount': value.executionCount,
        'executionTime': value.executionTime,
        'percentage': value.percentage,
        'averageExecutionTimeInSec': value.averageExecutionTimeInSec,
        'totalTime': value.totalTime,
    };
}

