import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { getProfile2Api, getSystemConfig } from '../../../components/System/systemConfig';
import { UserImage } from '../../../components/UserImage';

interface LoadUserImageProps {
    userId: string;
    width?: number;
    height?: number;
    className?: string;
}

export const LoadUserImage: FC<LoadUserImageProps> = (props) => {
    const userImageQuery = useQuery({
        queryKey: ['userImage', props.userId],
        queryFn: () => getProfile2Api().getUserImage({tenant: getSystemConfig().clientName, userId: props.userId}),
        enabled: !!props.userId,
    });

    if (userImageQuery.data) {
        return (
            <>
                <UserImage
                    imageId={userImageQuery.data}
                    width={props.width}
                    height={props.height}
                    className={props.className}
                />
            </>
        );
    } else {
        return <></>;
    }
};
