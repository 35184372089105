import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPushSubscription } from '../../../utils';
import {
    systemPushSubscriptionUnsubscribe,
    systemSetPushSubscription,
    systemSetPushSubscriptionPublicKey,
} from '../actions';
import { pushSubscription2FuntipPushSubscription } from '../sagas';
import { isLoggedInSelector, systemPushSubscriptionSelector } from '../selectors';
import { getPushNotification2Api } from '../systemConfig';

export const PushNotification: FC<{}> = () => {
    const api = getPushNotification2Api();

    const loggedIn = useSelector(isLoggedInSelector);
    const pushSubscription = useSelector(systemPushSubscriptionSelector);

    const publicKeyQuery = useQuery({
        queryKey: ['publicKeyQuery'],
        queryFn: () => api.getPublicKey(),
        refetchOnWindowFocus: false,
    });

    const dispatch = useDispatch();

    const checkForLogout = useCallback(() => {
        if (!loggedIn && pushSubscription) {
            // we need to unsubscribe because there is no logged in user anymore
            dispatch(systemPushSubscriptionUnsubscribe());
        }
    }, [dispatch, loggedIn, pushSubscription]);

    // check every 10 minutes if the user is still logged in; if not, unsubscribe from push messages
    useEffect(() => {
        const interval = setInterval(checkForLogout, 10 * 60 * 1000);

        return () => clearInterval(interval);
    }, [checkForLogout]);

    useEffect(() => {
        getPushSubscription().then((sub) => {
            if (sub) {
                const funtipSub = pushSubscription2FuntipPushSubscription(sub);
                if (funtipSub) {
                    dispatch(systemSetPushSubscription(funtipSub));
                }
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (publicKeyQuery.isSuccess && publicKeyQuery.data && publicKeyQuery.data.message) {
            dispatch(systemSetPushSubscriptionPublicKey(publicKeyQuery.data.message));
        }
    }, [publicKeyQuery, dispatch]);

    return null;
};

/*

FIXME : install PushSubscriber

export const PushSubscriber: React.FC = (props) => {
    const [pushNotificationSupported, setPushNotificationSupported] = useState<boolean>(false);
    const [pushSubscriptionConfirmed, setPushSubscriptionConfirmed] = useState<boolean>(false);
    const [pushSubscription, setPushSubscription] = useState<PushSubscription | null>(null);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        setPushNotificationSupported('Notification' in window);

        if ('serviceWorker' in navigator) {
            console.log('Service worker found');
            const tryToSubscribe = async () => {
                const register = await navigator.serviceWorker.ready;

                const subscription: PushSubscription = await register.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
                });

                setPushSubscription(subscription);
            };
            tryToSubscribe();
        } else {
            console.log('No service worker');
        }
    }, []);

    const subscribeMutation = useMutation({
            mutationFn: () => getPushNotification2Api().subscribe({funtipPushSubscription: pushSubscription!.toJSON()}),
        onSuccess(data, variables, context) {
            setPushSubscriptionConfirmed(true);
            console.log('Subscription added');
        },
        onError(error, variables, context) {
            setPushSubscriptionConfirmed(false);
            console.error('error adding Subscription');
        },
    });

    const { mutate: unsubscribe } = trpc.users.unsubscribe.useMutation({
        onSuccess(data, variables, context) {
            setPushSubscriptionConfirmed(false);
            console.log('Subscription removed');
        },
        onError(error, variables, context) {
            setPushSubscriptionConfirmed(false);
            console.error('error removing Subscription');
        },
    });

    useEffect(() => {
        console.info(`PS: ${pushSubscription?.endpoint} u: ${auth?.user?.userId} t: ${phContext?.tenant?.tenantId}`);
        if (pushSubscription && auth?.user) {
            subscribe({
                userId: auth.user.userId,
                subscription: pushSubscription.toJSON(),
            });
        }

        if (pushSubscription && !auth?.user) {
            unsubscribe({
                subscription: pushSubscription.toJSON(),
            });
        }
    }, [pushSubscription, auth?.user, phContext.tenant?.tenantId, subscribe, unsubscribe]);

    if (pushSubscriptionConfirmed || auth?.user === undefined) {
        return null;
    }

    const isEnabled = () => {
        return pushNotificationSupported && pushSubscription && !pushSubscriptionConfirmed;
    };
    return (
        <>
            <button
                className="flex justify-center items-center mt-1 w-10 h-10 text-lg font-bold relative"
                onClick={() => setShowDialog(true)}
            >
                {<div className="absolute top-0 right-1 font-bold text-red-500 text-xl">!</div>}
                <FontAwesomeIcon icon={faBellSlash} color="black" />
            </button>
            <ModalDialog show={showDialog} onClose={() => setShowDialog(false)}>
                <ModalHeader onClose={() => setShowDialog(false)}>Push-Benachrichtigungen</ModalHeader>
                <ModalBody>
                    <div className={`rounded p-2 text-base md:text-lg ${isEnabled() ? 'bg-green-300' : 'bg-red-300'}`}>
                        {pushNotificationSupported && !pushSubscriptionConfirmed && (
                            <>
                                <p>Dein Browser unterstützt Push-Benachrichtigungen.</p>
                                <p>
                                    Du hast Push-Nachrichten für my-ponyhof{' '}
                                    <span className="text-red-500 font-bold">ausgeschaltet</span>. Wenn Du Nachrichten
                                    erhalten willst, musst Du diese in Deinem Browser oder in den Systemeinstellungen
                                    aktivieren.
                                </p>
                            </>
                        )}
                        {!pushNotificationSupported && (
                            <p>
                                Dein Browser unterstützt <span className="text-red-500 font-bold">keine</span>{' '}
                                Push-Nachrichten.
                            </p>
                        )}
                        {pushNotificationSupported && pushSubscription && !pushSubscriptionConfirmed && (
                            <p>Push-Nachrichten für my-ponyhof sind noch nicht aktiviert.</p>
                        )}
                        {pushNotificationSupported && pushSubscription && pushSubscriptionConfirmed && (
                            <p>Du hast Push-Nachrichten für my-ponyhof aktiviert.</p>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <PonyhofButton color="success" onClick={() => setShowDialog(false)}>
                        Schließen
                    </PonyhofButton>
                </ModalFooter>
            </ModalDialog>
        </>
    );
};
*/
