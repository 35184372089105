/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RssFeedImage } from './RssFeedImage';
import {
    RssFeedImageFromJSON,
    RssFeedImageFromJSONTyped,
    RssFeedImageToJSON,
} from './RssFeedImage';
import type { RssEntry } from './RssEntry';
import {
    RssEntryFromJSON,
    RssEntryFromJSONTyped,
    RssEntryToJSON,
} from './RssEntry';

/**
 * 
 * @export
 * @interface RssFeed
 */
export interface RssFeed {
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    topline?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    shortTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeed
     */
    image?: string;
    /**
     * 
     * @type {Array<RssFeedImage>}
     * @memberof RssFeed
     */
    images?: Array<RssFeedImage>;
    /**
     * 
     * @type {Array<RssEntry>}
     * @memberof RssFeed
     */
    entries?: Array<RssEntry>;
}

/**
 * Check if a given object implements the RssFeed interface.
 */
export function instanceOfRssFeed(value: object): value is RssFeed {
    return true;
}

export function RssFeedFromJSON(json: any): RssFeed {
    return RssFeedFromJSONTyped(json, false);
}

export function RssFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RssFeed {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'topline': json['topline'] == null ? undefined : json['topline'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'link': json['link'] == null ? undefined : json['link'],
        'image': json['image'] == null ? undefined : json['image'],
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(RssFeedImageFromJSON)),
        'entries': json['entries'] == null ? undefined : ((json['entries'] as Array<any>).map(RssEntryFromJSON)),
    };
}

export function RssFeedToJSON(value?: RssFeed | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'topline': value['topline'],
        'shortTitle': value['shortTitle'],
        'link': value['link'],
        'image': value['image'],
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(RssFeedImageToJSON)),
        'entries': value['entries'] == null ? undefined : ((value['entries'] as Array<any>).map(RssEntryToJSON)),
    };
}

