import * as React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Dispatch } from 'redux';
import { GlobalState } from '../../../actions';
import { ButtonWithMultipleConfirmations } from '../../../common/ButtonWithMultipleConfirmations';
import { Section, SectionHeading } from '../../../common/Section';
import { accountUserSettingDeleteProfile, accountUserSettingSetDeleteAccountMessage } from '../actions';
import {
    accountUserSettingsDeleteAccountMessageSelector,
    accountUserSettingsDeleteAccountStateSelector
} from '../selectors';

export type DeleteProfileResult = 'ok' | 'error' | undefined;

interface Props {
    deleteProfileMessage: string;
    deleteProfileState: DeleteProfileResult;

    onDismissMessage: () => void;
    onDeleteProfile: () => void;
}

class DeleteProfile extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onDeleteProfile = this.onDeleteProfile.bind(this);
    }

    onDeleteProfile() {
        this.props.onDeleteProfile();
    }

    public render() {
        return (
            <Section>
                <SectionHeading>Profil löschen</SectionHeading>
                <Alert
                    color={this.props.deleteProfileState === 'error' ? 'danger' : 'success'}
                    toggle={this.props.onDismissMessage}
                    isOpen={this.props.deleteProfileState !== undefined}
                >
                    {this.props.deleteProfileMessage}
                </Alert>
                {this.props.deleteProfileState !== 'ok' && (
                    <ButtonWithMultipleConfirmations
                        onOK={this.onDeleteProfile}
                        color="danger"
                        messages={[
                            'Sind Sie sicher, dass Sie Ihren Account vollständig löschen wollen?',
                            'Die Löschung des Profils kann nicht rückgängig gemacht werden. Bitte ' +
                                'bestätigen Sie erneut, dass Sie alle Daten unwiderruflich löschen wollen.'
                        ]}
                        title="Gesamtes Profil unwiderruflich löschen!"
                    >
                        Gesamtes Profil unwiderruflich löschen!
                    </ButtonWithMultipleConfirmations>
                )}
            </Section>
        );
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        deleteProfileMessage: accountUserSettingsDeleteAccountMessageSelector(state),
        deleteProfileState: accountUserSettingsDeleteAccountStateSelector(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onDeleteProfile: () => {
            dispatch(accountUserSettingDeleteProfile());
        },
        onDismissMessage: () => {
            dispatch(accountUserSettingSetDeleteAccountMessage({ msg: '', state: undefined }));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteProfile);
