import React, { FC, useState } from 'react';
import { GameEditComp } from '../GameEdit';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { systemSetPopupMessage } from '../../../../../System/actions';
import { OnDemandConfirmationDialog } from '../../../../../common/ConfirmationDialog';

interface GameActionsProps {
    gameId: string;
    trId: string;
    onRefresh: () => void;
}
export const GameActions: FC<GameActionsProps> = (props) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [editGameId, setEditGameId] = useState('');

    const [confirmationTitle, setConfirmationTitle] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationOnOK, setConfirmationOnOK] = useState<() => void>(() => {});
    const [confirmationIsShown, setConfirmationIsShown] = useState(false);
    const [confirmationOnCancel, setConfirmationOnCancel] = useState<() => void>(() => {});

    const dispatch = useDispatch();

    const onShowConfirmationDialog = (title: string, message: string, onOK: () => void, onCancel: () => void) => {
        setConfirmationTitle(title);
        setConfirmationMessage(message);
        setConfirmationOnOK(onOK);
        setConfirmationOnCancel(onCancel);
        setConfirmationIsShown(true);
    };

    const toggle = () => setDropDownOpen((state) => !state);

    const refreshMatchStatisticsMutation = useMutation({
        mutationFn: () => getTournamentSysAdminApi().updateMatchStatistics({gameId: props.gameId}),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Match Statistics will be updated soon.'));
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error updating Match Statistics.'));
        },
    });

    const importLiveTickerMutation = useMutation({
        mutationFn: () => getTournamentSysAdminApi().importLiveTicker({gameId: props.gameId}),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Live-Ticker imported.'));
            props.onRefresh();
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error importing Live-Ticker.'));
        },
    });

    const deleteGameMutation = useMutation({
        mutationFn: () => getTournamentSysAdminApi().deleteGame({gameId: props.gameId}),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Game deleted.'));
            props.onRefresh();
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error deleting Game: ' + JSON.stringify(error)));
        },
    });

    const refreshMatchStatistics = () => {
        refreshMatchStatisticsMutation.mutate();
    };

    const importLiveTicker = () => {
        importLiveTickerMutation.mutate();
    };

    const onDeleteGame = () => {
        onShowConfirmationDialog(
            'Delete Game',
            'Are you sure you want to delete this game?',
            () => () => {
                deleteGameMutation.mutate();
                setConfirmationIsShown(false);
            },
            () => () => {
                setConfirmationIsShown(false);
            },
        );
    };

    return (
        <>
            <GameEditComp
                gameId={props.gameId}
                trId={props.trId}
                isShown={editGameId === props.gameId}
                onClose={() => {
                    setEditGameId('');
                    props.onRefresh();
                }}
            />
            <OnDemandConfirmationDialog
                title={confirmationTitle}
                message={confirmationMessage}
                onOK={confirmationOnOK}
                isShown={() => confirmationIsShown}
                onCancel={confirmationOnCancel}
            />

            <Dropdown isOpen={dropDownOpen} toggle={toggle} className="ms-3" size="sm">
                <DropdownToggle caret color="primary" className="text-white">
                    Game Actions
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Game Actions</DropdownItem>
                    <DropdownItem onClick={() => setEditGameId(props.gameId)}>Edit Game</DropdownItem>
                    <DropdownItem onClick={importLiveTicker}>Import Live-Ticker</DropdownItem>
                    <DropdownItem>Swap Teams</DropdownItem>
                    <DropdownItem onClick={refreshMatchStatistics}>Refresh Match Statistics</DropdownItem>
                    <DropdownItem onClick={onDeleteGame}>Delete Game!</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem disabled>Delete Bets</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
