import * as React from 'react';
import { useSelector } from 'react-redux';
import { Team } from '../../services/betting';
import { bettingProfileFavoriteTeamsSelector } from '../BettingApp/BettingProfile/selectors';
import { useQuery } from '@tanstack/react-query';
import { getTeamApi2 } from '../System/systemConfig';

interface Props {
    team?: Team;
    teamId?: string;
    displayType?: 'mini' | 'short' | 'normal' | undefined;
    maxWidth?: number;
}

interface WithBadgeProps extends React.PropsWithChildren {
    isFavorite: boolean;
}
const WithBadgeIfFavorite: React.FC<WithBadgeProps> = (props) => {
    if (props.isFavorite) {
        return <span className="text-truncate py-1 border-warning rounded fw-bold text-warning">{props.children}</span>;
    } else {
        return <span className="py-1">{props.children}</span>;
    }
};

export const isFavoriteTeam = (teamId: string, favoriteTeams: Team[]) =>
    favoriteTeams.find((t) => t.id === teamId) !== undefined;

export const TeamLink: React.FC<Props> = (props) => {
    const favoriteTeams = useSelector(bettingProfileFavoriteTeamsSelector);

    const [team, setTeam] = React.useState<Team>();

    const api = getTeamApi2();

    const teamQuery = useQuery({
        queryKey: ['team.getById', props.teamId],
        queryFn: () => api.getById({teamId: props.teamId!}),
        enabled: !!props.teamId,
    });

    React.useEffect(() => {
        setTeam(teamQuery.data);
    }, [teamQuery.data]);

    React.useEffect(() => {
        if (props.team) {
            setTeam(props.team);
        }
    }, [props.team]);

    const isFavorite = isFavoriteTeam(team?.id ?? '', favoriteTeams);

    switch (props.displayType) {
        case 'mini': {
            return (
                <WithBadgeIfFavorite isFavorite={isFavorite}>
                    <span title={team?.name}>{team?.miniName}</span>
                </WithBadgeIfFavorite>
            );
        }
        case 'short': {
            return (
                <WithBadgeIfFavorite isFavorite={isFavorite}>
                    <span title={team?.name}>{team?.shortName}</span>
                </WithBadgeIfFavorite>
            );
        }
        case 'normal': {
            return (
                <WithBadgeIfFavorite isFavorite={isFavorite}>
                    <span>{team?.name}</span>
                </WithBadgeIfFavorite>
            );
        }
        default: {
            return (
                <WithBadgeIfFavorite isFavorite={isFavorite}>
                    <span
                        className="d-table-cell d-sm-none text-truncate px-0"
                        title="{props.team.name}"
                        style={props.maxWidth !== undefined ? { maxWidth: props.maxWidth, width: 'auto' } : {}}
                    >
                        {team?.shortName}
                    </span>
                    <span
                        className="d-none d-sm-table-cell d-md-none text-truncate d-lg-none px-0 py-0"
                        style={props.maxWidth !== undefined ? {} : { maxWidth: props.maxWidth }}
                    >
                        {team?.shortName}
                    </span>
                    <span
                        className="d-none d-md-table-cell text-truncate"
                        style={props.maxWidth !== undefined ? {} : { maxWidth: props.maxWidth }}
                    >
                        {team?.name}
                    </span>
                </WithBadgeIfFavorite>
            );
        }
    }
};
