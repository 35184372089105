import { takeLatest, all, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getSystemPropertyApi } from "../../../components/System/systemConfig";
import { fetchWrapperFuncNoLoading } from "../../../utils";
import { systemPropertiesLoad, systemPropertiesSet, SYSTEM_PROPERTIES_LOAD, SYSTEM_PROPERTIES_UPDATE } from "./actions";

function* load() {
    const api = getSystemPropertyApi();

    yield* fetchWrapperFuncNoLoading(
        () => api.getSystemProperties(),
        [r => systemPropertiesSet(r)]
    );
}

function* update(action: Action<{name: string, value: string}>) {
    const api = getSystemPropertyApi();

    yield* fetchWrapperFuncNoLoading(
        () => api.updateSystemProperty(action.payload.name, action.payload.value),
        [ r => systemPropertiesLoad()]
    );
}

function* watch() {
    yield takeLatest(SYSTEM_PROPERTIES_LOAD, load);
    yield takeEvery(SYSTEM_PROPERTIES_UPDATE, update);
}

export function* systemPropertiesSagas() {
    yield all([watch()]);
}