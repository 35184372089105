import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { isR1Mobile, isRadioEins } from '../../System/systemConfig';

interface Props {
    onRouteChange: (newRoute: string) => void;
}
export const RouteChange: FC<Props> = (props) => {
    const location = useLocation();

    const { onRouteChange } = props;
    useEffect(() => {
        if (location && location.pathname) {
            onRouteChange(location.pathname);
        }
    }, [location, onRouteChange]);
    return <></>;
};

const onRouteChange = (newRoute: string) => {
    if (isRadioEins() || isR1Mobile()) {
        if ((window as any).callIVW) (window as any).callIVW('mein_radioeins_rbb', true, 'layer');
    }
};

export const RouteChangeAccountServerLog: FC<{}> = () => <RouteChange onRouteChange={onRouteChange} />;
