import * as React from 'react';
import { getSystemConfig } from '../System/systemConfig';

interface Props {
    imageId?: string;
    width?: number;
    height?: number;
    className?: string;
}

export const UserImage: React.FC<Props> = props => {
    return (
        <>
            {props.imageId && (
                <img
                    src={getSystemConfig().imageUrl(props.imageId)}
                    alt=""
                    className={props.className || `img-fluid img-r1--profile-image rounded`}
                    width={props.width ? props.width : 200}
                    height={props.height ? props.height : 200}
                />
            )}
            {!props.imageId && (
                <img
                    src="https://static2.funtip.de/static-img/r1/bild_dummy.jpg"
                    alt="dummy"
                    className={props.className || `img-fluid img-r1--profile-image`}
                    width={props.width ? props.width : 200}
                    height={props.height ? props.height : 200}
                />
            )}
        </>
    );
};

export const UserGroupImage: React.FC<Props> = props => <UserImage {...props} />;
