import axios, { AxiosInstance } from 'axios';
import { FC } from 'react';
import React from 'react';
import { ApiFootballRoundFixtures } from '../league';
import { getSystemPropertyApi } from '../../../components/System/systemConfig';

// const API_KEY = '5853ddab561ab550e0b79c01b3547050';

const getBaseUrl = () => 'https://v3.football.api-sports.io';

let apiFootball: AxiosInstance | undefined = undefined;

export const getApiFootball = async () => {
    if (!apiFootball) {
        const API_KEY = await getSystemPropertyApi().getSystemProperty('apiFootballKey');
        apiFootball = axios.create({ baseURL: getBaseUrl(), headers: { 'x-apisports-key': API_KEY.value ?? '' } });
    }
    return apiFootball;
};

export const apiSportsLivePath = () => '/a';

export const ApiFootballLive: FC<{}> = (props) => {
    return (
        <>
            <ApiFootballRoundFixtures name="Live" live={true} />
        </>
    );
};
