/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaylistUser
 */
export interface PlaylistUser {
    /**
     * 
     * @type {number}
     * @memberof PlaylistUser
     */
    userId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlaylistUser
     */
    playlistDataPublic: boolean;
}

/**
 * Check if a given object implements the PlaylistUser interface.
 */
export function instanceOfPlaylistUser(value: object): value is PlaylistUser {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('playlistDataPublic' in value) || value['playlistDataPublic'] === undefined) return false;
    return true;
}

export function PlaylistUserFromJSON(json: any): PlaylistUser {
    return PlaylistUserFromJSONTyped(json, false);
}

export function PlaylistUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaylistUser {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'playlistDataPublic': json['playlistDataPublic'],
    };
}

export function PlaylistUserToJSON(value?: PlaylistUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'playlistDataPublic': value['playlistDataPublic'],
    };
}

