import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Login } from '../../Login';
import { ErrorMessage } from '../../System/ErrorMessage';
import { isSysAdminSelector } from '../../System/selectors';
import { clientAdminBetOverviewPath, clientAdminIndexPath, newsletterListPath } from '..';

export const MenuClientAdmin: FC<{}> = () => {
    const isSysAdmin = useSelector(isSysAdminSelector);

    return (
        <>
            <div>
                <Navbar color="light">
                    <NavbarBrand tag={Link} to={'/'}>
                        Funtip Client Admin
                    </NavbarBrand>
                    <Nav>
                        <NavItem>
                            <NavLink tag={Link} to={clientAdminIndexPath()}>
                                Übersicht
                            </NavLink>
                        </NavItem>
                        {isSysAdmin && (
                            <NavItem>
                                <NavLink tag={Link} to={'/client-admin/auth-test'}>
                                    Auth-Test
                                </NavLink>
                            </NavItem>
                        )}
                        <NavItem>
                            <NavLink tag={Link} to={newsletterListPath()}>
                                Newsletter
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={clientAdminBetOverviewPath()}>
                                Tipp-Statistik
                            </NavLink>
                        </NavItem>

                        <Login />
                        <ErrorMessage />
                    </Nav>
                </Navbar>
            </div>
        </>
    );
};
